import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { getProjectName } from "core/projects";
import { getCSAName } from "core/csa";
import { downloadEversignFinalPDF } from "core/eversign/downloadEversignFinalPDF";
import { sendMailTemplate } from "core/mail";
import { addFileToProjectAssignment } from "core/projectAssignments/addFileToProjectAssignment";
import { getEngineerAssignments } from "core/projectAssignments/getEngineerAssignments";

export const useFreelancerSignProjectAssignments = (freelancerId: string) => {
  const queryClient = useQueryClient();

  // Fetch assignments that need signing
  const {
    data: assignments = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["freelancerProjectAssignmentsToSign", freelancerId],
    queryFn: async () => {
      if (!freelancerId) return [];

      const fetchedAssignments = await getEngineerAssignments(freelancerId);
 
      const signingAssignments = fetchedAssignments.filter(
        (a) =>
          (a.status === "signing" &&
            a.everSignDocumentStatus === "awaiting_engineer_signature") ||
          a.everSignDocumentStatus === "awaiting_admin_signature"
      );

      const enrichedAssignments = await Promise.all(
        signingAssignments.map(async (assignment) => {
          const projectName = await getProjectName(
            assignment.clientId,
            assignment.projectId
          );
          const csaName = assignment.csaId
            ? await getCSAName(
                assignment.clientId,
                assignment.projectId,
                assignment.csaId
              )
            : "";
          return {
            ...assignment,
            projectName,
            csaName,
          };
        })
      );

      return enrichedAssignments;
    },
    enabled: !!freelancerId,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Update assignment after signing
  const updateAssignment = useMutation({
    mutationFn: async ({
      assignment,
      file,
    }: {
      assignment: ProjectAssignment;
      file: File;
    }) => {
      const fileUrl = await addFileToProjectAssignment(
        assignment.clientId,
        assignment.id,
        file
      );

      const assignmentRef = doc(
        db,
        "clients",
        assignment.clientId,
        "projectAssignments",
        assignment.id
      );
      await updateDoc(assignmentRef, {
        everSignDocumentStatus: "signed",
        status: "in_progress",
        fileUrl,
      });

      return { fileUrl };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["freelancerProjectAssignmentsToSign", freelancerId],
      });
    },
  });

  const handleAgreementSigned = async (assignment: ProjectAssignment) => {
    try {
      
      if (assignment?.eversignDocumentHash) {
        const file = await downloadEversignFinalPDF(
        assignment.eversignDocumentHash
      );
      const projectName = await getProjectName(
        assignment.clientId,
        assignment.projectId
      );

      const result = await updateAssignment.mutateAsync({
        assignment,
        file,
      });

      if (projectName) {
        await sendMailTemplate(
          "adam@freetech.co",
          `Project assignment status updated for ${projectName}`,
          "adminProjectAssignmentStatusUpdated",
          {
            projectAssignment: {
              ...assignment,
              fileUrl: result.fileUrl,
            },
          }
        );
      }

        return result;
      }
    } catch (error) {
      console.error("Error signing agreement:", error);
    }
  };

  return {
    assignments,
    isLoading,
    error: error?.message || null,
    handleAgreementSigned,
  };
};
