import React, { useEffect, useState, useRef } from "react";
import { Box, Paper, IconButton, Divider, Typography, CircularProgress, TextField } from "@mui/material";
import { useEditor, EditorContent, BubbleMenu, Extension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { common, createLowlight } from "lowlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Suggestion from "@tiptap/suggestion";
import { Editor, Range } from "@tiptap/core";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import tippy, { Instance as TippyInstance } from "tippy.js";
import "./KnowledgeBaseDocument.css";
import HardBreak from "@tiptap/extension-hard-break";
import { 
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Code,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  HorizontalRule as HorizontalRuleIcon,
  TableChart,
  Link as LinkIcon,
  FormatClear,
  ContentCopy,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import Underline from '@tiptap/extension-underline';
import { Tooltip, Snackbar, Alert } from '@mui/material';
import { generateId } from 'utils/generateId';
import { DateTime } from 'luxon';
import { useKnowledgeBase } from "hooks/knowledgeBase/useKnowledgeBase";

// Initialize lowlight with common languages
const lowlight = createLowlight(common);

interface CommandProps {
  editor: Editor;
  range: Range;
}

interface SuggestionItem {
  title: string;
  command: (props: CommandProps) => void;
}

interface SuggestionGroup {
  title: string;
  items: SuggestionItem[];
}

interface CommandsListProps {
  items: SuggestionGroup[];
  command: (item: SuggestionItem) => void;
}

interface SuggestionProps {
  editor: Editor;
  range: Range;
  props?: any;
}

interface SuggestionHandlerProps extends SuggestionProps {
  query?: string;
  clientRect?: DOMRect;
  decorationNode?: HTMLElement;
}

class ReactRenderer {
  element: HTMLElement;
  ref: any;
  editor: Editor;
  props: any;

  constructor(component: React.FC<CommandsListProps>, props: any) {
    this.element = document.createElement("div");
    this.ref = null;
    this.editor = props.editor;
    this.props = props;
    // Implementation would go here - simplified for example
  }

  updateProps(props: any) {
    this.props = props;
  }

  destroy() {
    // Implementation would go here
  }
}

const SlashCommands = Extension.create({
  name: "slash-commands",
  addOptions() {
    return {
      suggestion: {
        char: "/",
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: any;
        }) => {
          props.command({ editor, range });
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

const getSuggestions = (): SuggestionGroup[] => [
  {
    title: "Basic Blocks",
    items: [
      {
        title: "Text",
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).setParagraph().run();
        },
      },
      {
        title: "Heading 1",
        command: ({ editor, range }: CommandProps) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode("heading", { level: 1 })
            .run();
        },
      },
      {
        title: "Heading 2",
        command: ({ editor, range }: CommandProps) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode("heading", { level: 2 })
            .run();
        },
      },
    ],
  },
  {
    title: "Lists",
    items: [
      {
        title: "Bullet List",
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleBulletList().run();
        },
      },
      {
        title: "Numbered List",
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleOrderedList().run();
        },
      },
      {
        title: "Task List",
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleTaskList().run();
        },
      },
    ],
  },
  {
    title: "Other",
    items: [
      {
        title: "Code Block",
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
        },
      },
      {
        title: "Blockquote",
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleBlockquote().run();
        },
      },
      {
        title: "Horizontal Rule",
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).setHorizontalRule().run();
        },
      },
    ],
  },
];

const renderItems = () => {
  let component: ReactRenderer;
  let popup: TippyInstance[];

  return {
    onStart: (props: any) => {
      component = new ReactRenderer(CommandsList, {
        props,
        editor: props.editor,
      });

      popup = tippy("body", {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
      });
    },
    onUpdate: (props: any) => {
      component.updateProps(props);
      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      });
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === "Escape") {
        popup[0].hide();
        return true;
      }
      return component.ref?.onKeyDown(props);
    },
    onExit: () => {
      popup[0].destroy();
      component.destroy();
    },
  };
};

const CommandsList: React.FC<CommandsListProps> = ({ items, command }) => {
  return (
    <div className="slash-commands-menu">
      {items.map((group, index) => (
        <div key={index} className="slash-commands-group">
          <div className="slash-commands-group-title">{group.title}</div>
          {group.items.map((item, itemIndex) => (
            <button
              key={itemIndex}
              onClick={() => command(item)}
              className="slash-commands-item"
            >
              {item.title}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

interface KnowledgeBaseDocumentProps {
  document: KnowledgeBaseFile | null;
  isEditing: boolean;
  editingTitle: string;
  editingContent: string;
  onEditingTitleChange: (title: string) => void;
  onCancel: () => void;
  onEdit: () => void;
  onClose: () => void;
  onContentChange?: (content: string) => void;
  onDelete?: () => void;
}

interface MenuBarProps {
  editor: Editor | null;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      gap: 0.5, 
      p: 1, 
      borderBottom: '1px solid',
      borderColor: 'divider'
    }}>
      <IconButton
        size="small"
        onClick={() => editor.chain().focus().toggleBold().run()}
        color={editor.isActive('bold') ? 'primary' : 'default'}
      >
        <FormatBold fontSize="small" />
      </IconButton>
      
      <IconButton
        size="small"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        color={editor.isActive('italic') ? 'primary' : 'default'}
      >
        <FormatItalic fontSize="small" />
      </IconButton>

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        color={editor.isActive('underline') ? 'primary' : 'default'}
      >
        <FormatUnderlined fontSize="small" />
      </IconButton>

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().toggleCode().run()}
        color={editor.isActive('code') ? 'primary' : 'default'}
      >
        <Code fontSize="small" />
      </IconButton>

      <Divider orientation="vertical" flexItem />

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        color={editor.isActive('bulletList') ? 'primary' : 'default'}
      >
        <FormatListBulleted fontSize="small" />
      </IconButton>

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        color={editor.isActive('orderedList') ? 'primary' : 'default'}
      >
        <FormatListNumbered fontSize="small" />
      </IconButton>

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        color={editor.isActive('blockquote') ? 'primary' : 'default'}
      >
        <FormatQuote fontSize="small" />
      </IconButton>

      <Divider orientation="vertical" flexItem />

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        <HorizontalRuleIcon fontSize="small" />
      </IconButton>

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().insertTable({
          rows: 3,
          cols: 3,
          withHeaderRow: true
        }).run()}
        color={editor.isActive('table') ? 'primary' : 'default'}
      >
        <TableChart fontSize="small" />
      </IconButton>

      <IconButton
        size="small"
        onClick={() => {
          const url = window.prompt('Enter URL')
          if (url) {
            editor.chain().focus().setLink({ href: url }).run()
          }
        }}
        color={editor.isActive('link') ? 'primary' : 'default'}
      >
        <LinkIcon fontSize="small" />
      </IconButton>

      <IconButton
        size="small"
        onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
      >
        <FormatClear fontSize="small" />
      </IconButton>
    </Box>
  )
}

// Add this interface for heading nodes
interface HeadingNode {
  type: {
    name: string;
  };
  attrs: {
    level: number;
    id?: string;
  };
  textContent: string;
}

const KnowledgeBaseDocument: React.FC<KnowledgeBaseDocumentProps> = ({
  document,
  isEditing,
  editingTitle,
  editingContent,
  onEditingTitleChange,
  onCancel,
  onEdit,
  onClose,
  onContentChange,
  onDelete,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Add new state to track content changes
  const [localContent, setLocalContent] = useState(editingContent);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Add this state for saving status
  const [isSaving, setIsSaving] = useState(false);

  // Add these state variables near the other state declarations
  const [lastAutoSave, setLastAutoSave] = useState<Date | null>(null);
  const [lastContentChange, setLastContentChange] = useState<Date | null>(null);
  const [isTyping, setIsTyping] = useState(false);

  const { updateFile } = useKnowledgeBase();

  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const titleInputRef = useRef<HTMLInputElement>(null);

  const [localTitle, setLocalTitle] = useState(editingTitle);

  const handleCopyLink = (id: string) => {
    // Get the base URL without hash
    const baseUrl = window.location.href.split('#')[0];
    // Create the full URL with the hash
    const url = `${baseUrl}#${id}`;
    navigator.clipboard.writeText(url);
    setSnackbarMessage('Link copied to clipboard!');
    setSnackbarOpen(true);
  };

  const handleTitleClick = () => {
    if (!isEditing) return;
    setIsTitleEditing(true);
    setLocalTitle(editingTitle);
    setTimeout(() => {
      titleInputRef.current?.focus();
      titleInputRef.current?.select();
    }, 0);
  };

  const handleTitleBlur = () => {
    setIsTitleEditing(false);
    if (localTitle !== editingTitle) {
      handleTitleUpdate(localTitle);
    }
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setIsTitleEditing(false);
      if (localTitle !== editingTitle) {
        handleTitleUpdate(localTitle);
      }
    }
    if (e.key === 'Escape') {
      setIsTitleEditing(false);
      setLocalTitle(document?.name || '');
      onEditingTitleChange(document?.name || '');
    }
  };

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
          HTMLAttributes: ({ level }: { level: number }) => ({
            class: 'knowledge-heading',
            id: '',
          }),
        },
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Underline,
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return "What's the title?";
          }
          return 'Can you add some further context?';
        },
      }),
      Table.configure({
        resizable: true,
        HTMLAttributes: {
          class: 'custom-table',
        },
      }),
      TableRow,
      TableHeader,
      TableCell,
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          class: 'custom-link',
        },
      }),
      SlashCommands.configure({
        suggestion: {
          items: getSuggestions,
          render: renderItems,
        },
      }),
    ],
    content: document ? document.content : "",
    editable: isEditing,
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      onContentChange?.(content);
      setHasUnsavedChanges(true);
      setLastContentChange(new Date());
      setIsTyping(true); // Set typing state when content changes
    },
    onCreate: ({ editor }) => {
      updateHeadingIds(editor);
    },
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none",
      },
      transformPastedText: (text) => {
        // Preserve whitespace in pasted text
        return text.replace(/ /g, " ");
      },
      handleDOMEvents: {
        keydown: (view, event) => {
          // Handle Shift + Enter for new lines
          if (event.key === "Enter" && event.shiftKey) {
            event.preventDefault();
            editor?.commands.setHardBreak();
            return true;
          }
          return false;
        },
      },
    },
  });

  const updateHeadingIds = (editor: Editor) => {
    const transaction = editor.state.tr;
    let hasChanges = false;

    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === 'heading') {
        const id = generateId(node.textContent);
        if (!node.attrs.id || node.attrs.id !== id) {
          transaction.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            id,
          });
          hasChanges = true;
        }
      }
    });

    if (hasChanges) {
      editor.view.dispatch(transaction);
    }
  };

  useEffect(() => {
    if (!editor) return;

    const timeoutId = setTimeout(() => {
      updateHeadingIds(editor);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [editor?.getHTML()]);

  // Add this effect to handle hash changes without page refresh
  useEffect(() => {
    // Prevent default hash change behavior
    const handleHashChange = (e: HashChangeEvent) => {
      e.preventDefault();
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  // Update the HeadingWithCopy component
  const HeadingWithCopy: React.FC<{ id: string }> = ({ id }) => (
  <Box 
    sx={{ 
      position: 'relative',
      '&:hover .copy-button': {
        opacity: 1,
      },
    }}
  >
    <Tooltip title="Copy link to section">
      <IconButton
        className="copy-button"
        size="small"
        onClick={(e) => {
          e.preventDefault(); // Prevent hash change
          handleCopyLink(id);
        }}
        sx={{
          position: 'absolute',
          left: -30,
          top: '50%',
          transform: 'translateY(-50%)',
          opacity: 0,
          transition: 'opacity 0.2s',
        }}
      >
        <ContentCopy fontSize="small" />
      </IconButton>
    </Tooltip>
  </Box>
);

  useEffect(() => {
    if (editor && document) {
      editor.commands.setContent(document.content);
      onEditingTitleChange(document.name);
    }
  }, [editor, document, onEditingTitleChange]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditing);
    }
  }, [editor, isEditing]);

  useEffect(() => {
    // Scroll to hash on load
    const hash = window.location.hash.slice(1);
    if (hash && editor) {
      const element = window.document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [editor]);

  // Handle save
  const handleSave = async () => {
    if (editor && document) {
      try {
        setIsSaving(true);
        const content = editor.getHTML();
        
        await updateFile.mutateAsync({
          fileId: document.id,
          name: editingTitle,
          content: content,
        });

        onContentChange?.(content);
        setSnackbarMessage('Document saved successfully');
        setSnackbarOpen(true);
        setHasUnsavedChanges(false);
      } catch (error) {
        console.error('Failed to save:', error);
        setSnackbarMessage('Failed to save document');
        setSnackbarOpen(true);
      } finally {
        setIsSaving(false);
      }
    }
  };

  // Add loading state for save mutation
  const isSavingMutation = updateFile.isPending;

  // Handle cancel
  const handleCancel = () => {
    if (editor && document) {
      editor.commands.setContent(document.content);
      setLocalContent(document.content);
      onContentChange?.(document.content);
      setHasUnsavedChanges(false);
      onCancel();
    }
  };

  // Add warning before closing with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // Add a useEffect to track when user stops typing
  useEffect(() => {
    if (!isTyping) return;

    const typingTimeout = setTimeout(() => {
      setIsTyping(false);
    }, 1000); // Consider user stopped typing after 1 second of no changes

    return () => clearTimeout(typingTimeout);
  }, [lastContentChange]);

  // Update the auto-save effect
  useEffect(() => {
    if (!isEditing || !document || isSaving || isSavingMutation || !hasUnsavedChanges || isTyping) {
      return;
    }

    const autoSaveTimeout = setTimeout(async () => {
      if (editor && editingTitle.trim()) {
        try {
          const content = editor.getHTML();
          await updateFile.mutateAsync({
            fileId: document.id,
            name: editingTitle,
            content: content,
          });
          
          setLastAutoSave(new Date());
          setHasUnsavedChanges(false);
          setSnackbarMessage('Document auto-saved');
          setSnackbarOpen(true);
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }
    }, 10000); // 10 seconds after last change, but only if not typing

    return () => clearTimeout(autoSaveTimeout);
  }, [hasUnsavedChanges, isEditing, document, isSaving, isSavingMutation, editor, editingTitle, isTyping]);

  // Add this function to handle title updates
  const handleTitleUpdate = async (newTitle: string) => {
    if (!document || !newTitle.trim()) return;

    try {
      setIsSaving(true);
      await updateFile.mutateAsync({
        fileId: document.id,
        name: newTitle.trim(),
        content: document.content, // Keep existing content
      });

      onEditingTitleChange(newTitle);
      setHasUnsavedChanges(false);
      setSnackbarMessage('Title updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update title:', error);
      setSnackbarMessage('Failed to update title');
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  if (!document) return null;

  return (
    <Paper
      elevation={2}
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box className="document-header">
        {isTitleEditing ? (
          <input
            ref={titleInputRef}
            className="document-title-input"
            value={localTitle}
            onChange={(e) => setLocalTitle(e.target.value)}
            onBlur={handleTitleBlur}
            onKeyDown={handleTitleKeyDown}
            disabled={isSaving}
          />
        ) : (
          <Typography
            className="document-title"
            onClick={handleTitleClick}
            sx={{
              cursor: isEditing ? 'text' : 'default',
              '&:hover': {
                backgroundColor: isEditing ? '#f3f4f6' : 'transparent',
              }
            }}
          >
            {editingTitle || document.name}
          </Typography>
        )}
        <Typography className="document-meta">
          Last updated {DateTime.fromISO(document.updatedAt).toRelative()}
          {isSaving && ' • Saving...'}
        </Typography>
      </Box>

      <Box
        onClick={!isEditing ? onEdit : undefined}
        sx={{
          flex: 1,
          p: 3,
          cursor: isEditing ? "text" : "pointer",
          position: "relative",
          "& .ProseMirror": {
            height: "100%",
            minHeight: "400px",
            outline: "none",
            padding: "1rem",
          },
        }}
      >
        {isEditing && <MenuBar editor={editor} />}
        <EditorContent editor={editor} />
        {isEditing && (
          <Box sx={{ position: 'absolute', bottom: 16, right: 16, display: 'flex', gap: 1 }}>
            <IconButton
              color="secondary"
              onClick={handleCancel}
              sx={{
                backgroundColor: "background.paper",
                boxShadow: 1,
                "&:hover": {
                  backgroundColor: "background.paper",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={handleSave}
              disabled={!editingTitle.trim() || !hasUnsavedChanges || isSaving || isSavingMutation}
              sx={{
                backgroundColor: "background.paper",
                boxShadow: 1,
                "&:hover": {
                  backgroundColor: "background.paper",
                },
              }}
            >
              {isSaving || isSavingMutation ? (
                <CircularProgress size={24} />
              ) : (
                <SaveIcon />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default KnowledgeBaseDocument;
