import React, { useEffect } from "react";
import {
  Container,
  Box,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { authBackground, logoNoBackground } from "assets";
import { useTheme } from "@mui/material/styles";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { AccountDeactivated } from "components";

const AuthLayout: React.FC = () => {
  const theme = useTheme();
  const { isLoading, isAuthenticated, userInfo } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && (userInfo?.user_type === "non-admin" || !userInfo?.is_active)) {
      navigate("/account-deactivated");
    }
  }, [userInfo, navigate]);

  if (isLoading) {
    return null;
  }

  if (location.pathname === "/" && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && userInfo?.user_type !== "non-admin") {
    return <Navigate to={`/${userInfo?.user_type}`} replace />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundImage: `url(${authBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "15vh",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={logoNoBackground}
            alt="FreeTech Logo"
            sx={{
              width: "200px",
              height: "auto",
              marginBottom: 4,
            }}
          />
          {isLoading ? (
            <Box>
              <Typography>Loading...</Typography>
              <CircularProgress />
            </Box>
          ) : (
            <Paper
              elevation={6}
              sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "white",
                width: "100%",
                borderRadius: 2,
                boxShadow: "0 4px 20px  black", //rgba(0,0,0,0.1)',
              }}
            >
              <Outlet />
            </Paper>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default AuthLayout;
