import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import FreeloTicket from '../FreeloTicket/FreeloTicket';

interface DraggableCardAreaProps {
  board: Board;
  boardId: string;
  isAddingCard: string | null;
  newCardName: string;
  isCreatingCard: boolean;
  editingListId: string | null;
  inlineEditName: string;
  onDragEnd: (result: DropResult) => void;
  onListMenuOpen: (event: React.MouseEvent<HTMLElement>, listId: string) => void;
  onInlineEditSubmit: (listId: string) => void;
  onSetInlineEditName: (name: string) => void;
  onSetEditingListId: (id: string | null) => void;
  onSetIsAddingCard: (listId: string | null) => void;
  onSetNewCardName: (name: string) => void;
  onCreateCard: (listId: string) => void;
  onArchiveCard: (cardId: string, listId: string) => void;
  onCopyCard: (cardId: string, listId: string) => void;
  onUpdateCard: (cardId: string, updates: Partial<Card>) => Promise<void>;
  onSetIsAddingList: (isAdding: boolean) => void;
  onSetNewListName: (name: string) => void;
  onCreateList: () => void;
  isAddingList: boolean;
  newListName: string;
  getVisibleTickets: (listId: string) => Card[];
}

export const DraggableCardArea: React.FC<DraggableCardAreaProps> = ({
  board,
  boardId,
  isAddingCard,
  newCardName,
  isCreatingCard,
  editingListId,
  inlineEditName,
  onDragEnd,
  onListMenuOpen,
  onInlineEditSubmit,
  onSetInlineEditName,
  onSetEditingListId,
  onSetIsAddingCard,
  onSetNewCardName,
  onCreateCard,
  onArchiveCard,
  onCopyCard,
  onUpdateCard,
  onSetIsAddingList,
  onSetNewListName,
  onCreateList,
  isAddingList,
  newListName,
  getVisibleTickets,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="lists" direction="horizontal" type="list">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              flex: 1,
              display: "flex",
              overflowX: "auto",
              overflowY: "hidden",
              p: 2,
              gap: 2,
              minHeight: 0,
              "&::-webkit-scrollbar": {
                height: 8,
              },
              "&::-webkit-scrollbar-track": {
                bgcolor: "background.default",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-thumb": {
                bgcolor: "grey.400",
                borderRadius: 4,
                "&:hover": {
                  bgcolor: "grey.500",
                },
              },
              msOverflowStyle: "none",
              scrollbarWidth: "thin",
            }}
          >
            {/* Lists */}
            <Box sx={{ display: "flex", gap: 2 }}>
              {board?.columns
                ?.sort((a, b) => {
                  const aIndex = board.listOrder?.indexOf(a.id) ?? a.order;
                  const bIndex = board.listOrder?.indexOf(b.id) ?? b.order;
                  return aIndex - bIndex;
                })
                .map((list: Column, index: number) => (
                  <Draggable
                    key={list.id}
                    draggableId={list.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        sx={{
                          width: 280,
                          bgcolor: list.color
                            ? alpha(list.color, 0.1)
                            : snapshot.isDragging
                              ? "action.hover"
                              : "grey.50",
                          borderTop: list.color
                            ? `2px solid ${list.color}`
                            : undefined,
                          height: "fit-content",
                          maxHeight: "100%",
                          display: "flex",
                          flexDirection: "column",
                          flexShrink: 0,
                        }}
                      >
                        {/* Header */}
                        <Box
                          {...provided.dragHandleProps}
                          sx={{
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "divider",
                            bgcolor: "background.paper",
                            cursor: "grab",
                            "&:active": {
                              cursor: "grabbing",
                            },
                          }}
                        >
                          {editingListId === list.id ? (
                            <TextField
                              autoFocus
                              value={inlineEditName}
                              onChange={(e) =>
                                onSetInlineEditName(e.target.value)
                              }
                              onBlur={() => onInlineEditSubmit(list.id)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  onInlineEditSubmit(list.id);
                                } else if (e.key === "Escape") {
                                  onSetEditingListId(null);
                                  onSetInlineEditName("");
                                }
                              }}
                              size="small"
                              onClick={(e) => e.stopPropagation()}
                              sx={{
                                "& .MuiInputBase-input": {
                                  fontSize: "h6.fontSize",
                                  fontWeight: "h6.fontWeight",
                                  p: "4px 8px",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "primary.main",
                                },
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h6"
                              onClick={() => {
                                const currentList = board?.columns?.find(
                                  (col: Column) => col.id === list.id
                                );
                                if (currentList) {
                                  onSetEditingListId(currentList.id);
                                  onSetInlineEditName(currentList.name);
                                }
                              }}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              {list.name}
                            </Typography>
                          )}
                          <IconButton
                            size="small"
                            onClick={(e) => onListMenuOpen(e, list.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Box>

                        {/* Cards */}
                        <Droppable droppableId={list.id} type="card">
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              sx={{
                                p: 1,
                                flex: 1,
                                overflowY: "auto",
                                minHeight: 100,
                              }}
                            >
                              {getVisibleTickets(list.id).map(
                                (card: Card, index: number) => (
                                  <Draggable
                                    key={card.id}
                                    draggableId={card.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <FreeloTicket
                                          card={card}
                                          boardId={boardId}
                                          listId={list.id}
                                          onUpdate={onUpdateCard}
                                          onArchive={() =>
                                            onArchiveCard(card.id, list.id)
                                          }
                                          onCopy={() =>
                                            onCopyCard(card.id, list.id)
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}

                              {/* Add Card Button/Form */}
                              {isAddingCard === list.id ? (
                                <Box sx={{ p: 1 }}>
                                  <TextField
                                    autoFocus
                                    fullWidth
                                    placeholder="Enter card title..."
                                    size="small"
                                    value={newCardName}
                                    onChange={(e) =>
                                      onSetNewCardName(e.target.value)
                                    }
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter" && !isCreatingCard) {
                                        e.preventDefault();
                                        onCreateCard(list.id);
                                      }
                                    }}
                                    disabled={isCreatingCard}
                                    sx={{
                                      mb: 1,
                                      "& .MuiOutlinedInput-root": {
                                        bgcolor: "white",
                                      },
                                    }}
                                  />
                                  <Box sx={{ display: "flex", gap: 1 }}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() => onCreateCard(list.id)}
                                      disabled={isCreatingCard}
                                    >
                                      {isCreatingCard ? "Adding..." : "Add card"}
                                    </Button>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        onSetIsAddingCard(null);
                                        onSetNewCardName("");
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              ) : (
                                <Button
                                  fullWidth
                                  startIcon={<AddIcon />}
                                  onClick={() => onSetIsAddingCard(list.id)}
                                  sx={{
                                    mt: 1,
                                    justifyContent: "flex-start",
                                    color: "text.secondary",
                                    "&:hover": {
                                      bgcolor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  Add a card
                                </Button>
                              )}
                            </Box>
                          )}
                        </Droppable>
                      </Paper>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}

              {/* Add List Button/Form */}
              <Paper
                sx={{
                  width: 280,
                  height: "fit-content",
                  flexShrink: 0,
                  bgcolor: isAddingList
                    ? "grey.50"
                    : "rgba(255, 255, 255, 0.6)",
                  p: 1,
                }}
              >
                {isAddingList ? (
                  <Box>
                    <TextField
                      autoFocus
                      fullWidth
                      placeholder="Enter list name..."
                      size="small"
                      value={newListName}
                      onChange={(e) => onSetNewListName(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onCreateList();
                          onSetIsAddingList(false);
                        }
                      }}
                      sx={{
                        mb: 1,
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "white",
                        },
                      }}
                    />
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          onCreateList();
                          onSetIsAddingList(false);
                        }}
                      >
                        Add list
                      </Button>
                      <IconButton
                        size="small"
                        onClick={() => {
                          onSetIsAddingList(false);
                          onSetNewListName("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <Button
                    startIcon={<AddIcon />}
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      color: "text.secondary",
                      "&:hover": {
                        bgcolor: "rgba(255, 255, 255, 0.4)",
                      },
                    }}
                    onClick={() => onSetIsAddingList(true)}
                  >
                    Add another list
                  </Button>
                )}
              </Paper>
            </Box>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};