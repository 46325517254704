import React, { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  Link,
  useTheme,
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { authTextFieldStyle } from 'core/theme'
import NotFound from 'router/NotFound'
import { verifyPasswordRequirements } from 'core/utils/verifyPasswordRequirements'
import { useAuth } from "hooks/auth/useAuth";
import { FirebaseError } from '@firebase/app'

const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate()
  const [resetSuccess, setResetSuccess] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState({ title: '', body: '', button: '' })
  const [searchParams] = useSearchParams()
  const [tokenStatus, setTokenStatus] = useState<'validating' | 'valid' | 'invalid' | 'expired'>('validating')
  const theme = useTheme()

  const { handleVerifyPasswordResetCode, handleConfirmPasswordReset } = useAuth()

  const token = searchParams.get('oobCode')
  const mode = searchParams.get('mode')
  const continueUrl = searchParams.get('continueUrl')

  const decodeTokenData = async () => {
    
    if (mode === 'verifyEmail') {
      
      navigate(`/?mode=${mode}&oobCode=${token}`)
    }

    if (!token) {
      setTokenStatus('invalid')
    } else {
      if (mode === 'resetPassword') {
        try {
          const userEmail: string | FirebaseError = await handleVerifyPasswordResetCode(token)
          
          if (userEmail) {
            setTokenStatus('valid')
          } else {
            setTokenStatus('invalid')
          }
        } catch (error) {
          if (error instanceof FirebaseError) {
            if (error.code === 'auth/invalid-action-code') {
              setTokenStatus('expired')
            } else {
              setTokenStatus('invalid')
            }
          }
        }
      } else {
        setTokenStatus('invalid')
      }
    }
  }

  // const decodeTokenData = async () => {
  //   if (!token) {
  //     setTokenStatus('invalid');
  //     // return;
  //   }
  //   try {

  //     if (token) {
  //       const response = await handleVerifyPasswordResetCode(token);
  //       if (response) {
  //         setTokenStatus('valid');
  //       } else {
  //         setTokenStatus('invalid');
  //       }
  //       // return;
  //     }
  //     {/* Reset Password Success */ }

  //     {/* Reset Password Link is expired, or another link has been sent afterward.*/ }
  //     // } else if (response.status === 422) {
  //     //   setTokenStatus('expired');
  //     //   return;
  //     // } else if (response.status === 409) {
  //     //   setTokenStatus('expired');
  //     //   return;
  //     // } else {

  //     // {/* Unable to decode token, redirect to 404 Not Found Page */}
  //     //   setTokenStatus('invalid');
  //     // }
  //   } catch (error) {
  //     console.error(error);
  //     setTokenStatus('invalid');
  //   }
  // }

  useEffect(() => {
    decodeTokenData()
  }, [token])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const password = formData.get('password') as string
    const passwordConfirmation = formData.get('passwordConfirmation') as string

    if (!verifyPasswordRequirements(password)) {
      setDialogContent({
        title: 'Password does not meet requirements.',
        body: 'Your password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number.',
        button: 'Ok',
      })
      setOpenDialog(true)
      return
    }

    if (password !== passwordConfirmation) {
      setDialogContent({
        title: 'Passwords do not match',
        body: `You must confirm your password.`,
        button: 'Ok',
      })
      setOpenDialog(true)
      return
    }

    if (!token) {
      setDialogContent({
        title: 'Something went wrong.',
        body: `Please try sending another password reset email.`,
        button: 'Ok',
      })
      setOpenDialog(true)
      return
    }

    const response = await handleConfirmPasswordReset(token, password)

    if (response) {
      setDialogContent({
        title: 'Your password was reset successfully.',
        body: `We will now redirect you to the login page.`,
        button: 'Ok',
      })
      setResetSuccess(true)
      setOpenDialog(true)
      return
    } else if (!response) {
      setDialogContent({
        title: 'Something went wrong.',
        body: `Please try sending another password reset email.`,
        button: 'Ok',
      })
      setOpenDialog(true)
      return
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    if (resetSuccess) {
      navigate('/login')
    }
  }

  const ResetPasswordDialog = (): JSX.Element => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent.body}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='primary'>
              {dialogContent.button}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const ResetTokenExpired = (): JSX.Element => {
    return (
      <>
        <Box sx={{ margin: '20px' }}>
          <Typography variant='h4' sx={{ textAlign: 'center', marginBottom: 3, color: theme.palette.primary.contrastText }}>
            Your password reset link has expired.
          </Typography>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', marginBottom: 3, color: theme.palette.primary.contrastText }}>
            Click "Forgot Password" below to reset your password or "Login" if you remember your password.
          </Typography>
          <Typography sx={{ fontSize: '10px', textAlign: 'center', color: theme.palette.primary.contrastText }}>
            Please contact admin@optictruckworks.com if your issues persist.
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs>
            <Link href='/forgot-password' variant='body2' sx={{ color: theme.palette.primary.contrastText }}>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href='/login' variant='body2' sx={{ color: theme.palette.primary.contrastText }}>
              {'Remember your password? Login'}
            </Link>
          </Grid>
        </Grid>
      </>
    )
  }

  if (tokenStatus === 'expired') {
    return <ResetTokenExpired />
  }

  if (tokenStatus === 'validating') {
    return <div></div>
  }

  if (tokenStatus === 'invalid') {
    return <NotFound />
  }

  return (
    <>
      <ResetPasswordDialog />
      <Box sx={{ marginTop: '20px' }}>
        <Typography sx={{ color: theme.palette.primary.contrastText }}>
          Please enter and confirm your new password.
        </Typography>
      </Box>
      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          sx={{
            ...authTextFieldStyle,
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
            },
            '& .MuiFormHelperText-root': {
              color: theme.palette.text.secondary,
            },
          }}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='passwordConfirmation'
          label='Confirm Password'
          type='password'
          id='passwordConfirmation'
          autoComplete='new-password'
          sx={{
            ...authTextFieldStyle,
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.text.secondary,
            },
            '& .MuiFormHelperText-root': {
              color: theme.palette.text.secondary,
            },
          }}
        />
        <Button 
          type='submit' 
          fullWidth 
          variant='contained' 
          sx={{ 
            mt: 3, 
            mb: 2,
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            '&:hover': {
              bgcolor: theme.palette.action.hover,
            },
          }}
        >
          Reset Password
        </Button>
      </Box>
    </>
  )
}

export default ResetPassword
