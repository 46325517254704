import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { 
  Typography, Box, CircularProgress, Paper, Grid
} from "@mui/material";

import ProfileInfo from "./components/ProfileInfo";
import SkillsSection from "./components/SkillsSection";
import DocumentsSection from "./components/DocumentsSection";
import { fetchFreelancerDoc, fetchFreelancerDocuments } from "core/freelancer";
import { useAuth } from "hooks/auth/useAuth";

const FreelancerProfile: React.FC = () => {
  const [freelancer, setFreelancer] = useState<Freelancer | null>(null);
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<FreelancerDocument[]>([]);
  const { userInfo } = useAuth();
  
  useEffect(() => {
    const fetchData = async () => {
      if (userInfo?.id) {
        const userId = userInfo.id;
        try {
          const freelancerData = await fetchFreelancerDoc(userId);
          setFreelancer(freelancerData);
          const documentsData = await fetchFreelancerDocuments(userId);
          setDocuments(documentsData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [userInfo?.id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!freelancer) {
    return (
      <Typography>No freelancer data found</Typography>
    );
  }

  return (
    <Box py={4}>
      <Paper elevation={3} sx={{ mb: 4 }}>
        <Box p={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <ProfileInfo 
                freelancer={freelancer} 
                onUpdate={setFreelancer} 
                userInfo={userInfo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SkillsSection freelancer={freelancer} onUpdate={setFreelancer} />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <DocumentsSection documents={documents} setDocuments={setDocuments} />
    </Box>
  );
};

export default FreelancerProfile;
