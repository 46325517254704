import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Chip,
  Avatar,
  Tooltip,
  AvatarGroup,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueFormatter,
  GridRowParams,
  GridEventListener,
  GridRowProps,
} from "@mui/x-data-grid-pro";
import { useFreelo } from "hooks/freelo/useFreelo";
import { DateTime } from "luxon";
import { useUserList } from "hooks/shared/user/useUserList";
import FreeloTicketDialog from "../FreeloTicketDialog/FreeloTicketDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmActionDialog from "components/ConfirmActionDialog";
import UnarchiveIcon from "@mui/icons-material/Unarchive";

interface ArchiveListProps {
  isArchiveView: boolean;
}

interface ContextMenuState {
  mouseX: number;
  mouseY: number;
  cardId: string;
  listId: string;
  card: Card;
}

export const ArchiveList: React.FC<ArchiveListProps> = ({ isArchiveView }) => {
  const { boardId } = useParams<{ boardId: string }>();
  const { useBoard, updateCard, moveCardToTrash } = useFreelo();
  const { data: board, refetch } = useBoard(boardId!);
  const { data: users } = useUserList();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [isTicketDialogOpen, setIsTicketDialogOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [cardToDelete, setCardToDelete] = useState<{
    cardId: string;
    listId: string;
    card: Card;
  } | null>(null);

  const getArchivedCards = () => {
    const cards: any[] = [];
    board?.columns?.forEach((list) => {
      list.cards
        .filter((card) => card.archived)
        .forEach((card) => {
          cards.push({
            id: card.id,
            humanReadableId: card.humanReadableId,
            title: card.title,
            description: card.description,
            list: list.name,
            listId: list.id,
            fullCard: card,
            assignee: card.assignee,
            additionalAssignees: card.additionalAssignees,
            labels: card.labels,
            startDate: card.startDate || null,
            dueDate: card.dueDate || null,
            createdAt: card.createdAt || new Date().toISOString(),
            updatedAt: card.updatedAt || new Date().toISOString(),
            comments: card.comments?.length || 0,
          });
        });
    });
    return cards;
  };

  const columns: GridColDef[] = [
    {
      field: "humanReadableId",
      headerName: "ID",
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ pl: 2 }}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "monospace",
              letterSpacing: "0.5px",
              color: "text.secondary",
            }}
          >
            #{params.value}
          </Typography>
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            whiteSpace: "normal",
            lineHeight: "1.2",
            py: 2.5,
            px: 2.5,
            display: "flex",
            alignItems: "center",
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleContextMenu(
              e,
              params.row.id,
              params.row.listId,
              params.row.fullCard
            );
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
              width: "100%",
              minHeight: "2.4em",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "list",
      headerName: "List",
      width: 150,
    },
    {
      field: "labels",
      headerName: "Labels",
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const labels = params.value || [];
        const displayedLabels = labels.slice(0, 2);
        const remainingCount = labels.length - 2;

        return (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              alignItems: "center",
              maxWidth: "100%",
            }}
          >
            {displayedLabels.map((label: CardLabel) => (
              <Chip
                key={label.id}
                label={label.name}
                size="small"
                sx={{
                  bgcolor: label.color,
                  color: "white",
                  height: 20,
                  fontSize: "0.75rem",
                  maxWidth: 90,
                  "& .MuiChip-label": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    px: 1,
                  },
                }}
              />
            ))}
            {remainingCount > 0 && (
              <Tooltip
                title={
                  <Box>
                    {labels.slice(2).map((label: CardLabel) => (
                      <Typography
                        key={label.id}
                        variant="caption"
                        display="block"
                      >
                        {label.name}
                      </Typography>
                    ))}
                  </Box>
                }
              >
                <Chip
                  label={`+${remainingCount}`}
                  size="small"
                  sx={{
                    height: 20,
                    fontSize: "0.75rem",
                    bgcolor: "grey.200",
                  }}
                />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "assignee",
      headerName: "Assignee",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const user = users?.find((u) => u.id === params.value);
        if (!user) return null;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              src={user.profilePicture || undefined}
              sx={{ width: 24, height: 24 }}
            >
              {user.firstName[0]}
            </Avatar>
            <Typography variant="body2">
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "additionalAssignees",
      headerName: "Additional Assignees",
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AvatarGroup
            max={3}
            sx={{
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                fontSize: "0.75rem",
                border: "2px solid white",
              },
            }}
          >
            {params.value?.map((userId: string) => {
              const user = users?.find((u) => u.id === userId);
              if (!user) return null;
              return (
                <Tooltip
                  key={user.id}
                  title={`${user.firstName} ${user.lastName}`}
                >
                  <Avatar
                    src={user.profilePicture || undefined}
                    alt={`${user.firstName} ${user.lastName}`}
                  >
                    {user.firstName[0]}
                  </Avatar>
                </Tooltip>
              );
            })}
          </AvatarGroup>
        </Box>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      valueFormatter: (value: any) =>
        value
          ? DateTime.fromISO(value.toString()).toFormat("MMM dd, yyyy")
          : "",
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 150,
      valueFormatter: (value: any) =>
        value
          ? DateTime.fromISO(value.toString()).toFormat("MMM dd, yyyy")
          : "",
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 100,
      type: "number",
    },
    {
      field: "createdAt",
      headerName: "Created",
      width: 150,
      valueFormatter: (value: any) =>
        DateTime.fromISO(value.toString()).toFormat("MMM dd, yyyy HH:mm"),
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      width: 150,
      valueFormatter: (value: any) =>
        DateTime.fromISO(value.toString()).toFormat("MMM dd, yyyy HH:mm"),
    },
  ];

  const handleCardClick = (cardId: string) => {
    setSelectedCardId(cardId);
    setIsTicketDialogOpen(true);
  };

  const findCard = (cardId: string): Card | undefined => {
    let foundCard: Card | undefined;
    board?.columns?.forEach((list) => {
      const card = list.cards.find((c) => c.id === cardId);
      if (card) foundCard = card;
    });
    return foundCard;
  };

  const handleCardUpdate = async (
    cardId: string,
    updates: Partial<Card>
  ): Promise<void> => {
    try {
      let listId: string | undefined;
      board?.columns?.forEach((list) => {
        if (list.cards.find((c) => c.id === cardId)) {
          listId = list.id;
        }
      });

      if (!listId) {
        console.error("List not found for card:", cardId);
        return;
      }

      await updateCard.mutateAsync({
        boardId: boardId!,
        cardId,
        updates,
      });
      await refetch();
    } catch (error) {
      console.error("Failed to update card:", error);
    }
  };

  const handleContextMenu = (
    event: React.MouseEvent,
    cardId: string,
    listId: string,
    card: Card
  ) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      cardId,
      listId,
      card,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleConfirmDelete = async () => {
    if (!cardToDelete || !boardId) return;

    try {
      await moveCardToTrash.mutateAsync({
        boardId,
        listId: cardToDelete.listId,
        cardId: cardToDelete.cardId,
        card: cardToDelete.card,
      });
      setShowDeleteConfirm(false);
      setCardToDelete(null);
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };
  const handleDeleteClick = async () => {
    if (contextMenu) {
      setCardToDelete({
        cardId: contextMenu.cardId,
        listId: contextMenu.listId,
        card: contextMenu.card,
      });
      //   await handleConfirmDelete();

      setShowDeleteConfirm(true);
      handleCloseContextMenu();
    }
    // }
  };

  const handleUnarchive = async () => {
    if (!contextMenu || !boardId) return;

    try {
      await updateCard.mutateAsync({
        boardId,
        cardId: contextMenu.cardId,
        updates: { archived: false },
      });
      handleCloseContextMenu();
    } catch (error) {
      console.error("Error unarchiving card:", error);
    }
  };

  if (!isArchiveView) return null;

  return (
    <Box
      sx={{
        height: "100%",
        p: { xs: 1, sm: 2 },
        width: "100%",
      }}
    >
      <Paper
        elevation={2}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Box
          sx={{
            p: { xs: 1, sm: 2 },
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Archived Cards</Typography>
        </Box>
        <Box
          sx={{ flex: 1, overflow: "hidden" }}
          onContextMenu={(e) => {
            const element = e.target as HTMLElement;
            const row = element.closest(".MuiDataGrid-row");
            if (!row) return;

            const rowId = row.getAttribute("data-id");
            if (!rowId) return;

            const rowData = getArchivedCards().find(
              (card) => card.id === rowId
            );
            if (!rowData) return;

            e.preventDefault();
            handleContextMenu(e, rowId, rowData.listId, rowData.fullCard);
          }}
        >
          <DataGridPro
            rows={getArchivedCards()}
            columns={columns}
            disableRowSelectionOnClick
            onRowClick={(params) => handleCardClick(params.row.id)}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
              row: {
                onContextMenu: (e: React.MouseEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  const rowElement = e.currentTarget as HTMLDivElement;
                  const rowId = rowElement.getAttribute("data-id");
                  if (!rowId) return;

                  const rowData = getArchivedCards().find(
                    (card) => card.id === rowId
                  );
                  if (!rowData) return;

                  handleContextMenu(e, rowId, rowData.listId, rowData.fullCard);
                },
                style: { cursor: "context-menu" },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedAt", sort: "desc" }],
              },
              columns: {
                columnVisibilityModel: {
                  additionalAssignees: !isSmallScreen,
                  startDate: !isSmallScreen,
                  createdAt: !isSmallScreen,
                  comments: !isSmallScreen,
                },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [],
                },
              },
            }}
            sx={{
              border: "none",
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: `1px solid ${theme.palette.divider}`,
                bgcolor: theme.palette.background.default,
              },
              "& .MuiDataGrid-row": {
                "&:nth-of-type(odd)": {
                  bgcolor: theme.palette.action.hover,
                },
                cursor: "pointer",
                "&:hover": {
                  bgcolor: theme.palette.action.selected,
                },
              },
              "& .MuiDataGrid-cell": {
                maxHeight: "none !important",
                minHeight: "80px !important",
                height: "auto !important",
                whiteSpace: "normal",
                lineHeight: "normal",
                display: "flex",
                alignItems: "center",
                padding: "0 !important",
                borderColor: "divider",
                "&:focus, &:focus-within": {
                  outline: "none",
                },
              },
              "& .MuiDataGrid-row, & .MuiDataGrid-renderingZone": {
                maxHeight: "none !important",
                minHeight: "80px !important",
              },
            }}
          />
        </Box>
      </Paper>

      {selectedCardId && findCard(selectedCardId) && (
        <FreeloTicketDialog
          open={isTicketDialogOpen}
          onClose={() => {
            setIsTicketDialogOpen(false);
            setSelectedCardId(null);
          }}
          card={findCard(selectedCardId)!}
          onUpdate={handleCardUpdate}
          boardId={boardId!}
        />
      )}

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleUnarchive}>
          <ListItemIcon>
            <UnarchiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Unarchive</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: "error.main" }}>
          <ListItemIcon sx={{ color: "error.main" }}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete Permanently</ListItemText>
        </MenuItem>
      </Menu>

      <ConfirmActionDialog
        open={showDeleteConfirm}
        title="Delete Card Permanently"
        message={`Are you sure you want to delete "${cardToDelete?.card.title}"? This action cannot be undone.`}
        confirmText="Delete"
        confirmButtonColor="error"
        onConfirm={handleConfirmDelete}
        onCancel={() => {
          setShowDeleteConfirm(false);
          setCardToDelete(null);
        }}
      />
    </Box>
  );
};
