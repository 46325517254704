import React, { useState, useRef } from 'react';
import { 
  TextField, 
  Popper, 
  Paper, 
  List, 
  ListItem, 
  ListItemText, 
  Avatar, 
  ListItemAvatar, 
  ListItemButton 
} from '@mui/material';
import { useUserList } from 'hooks/shared/user/useUserList';

interface MentionInputProps {
  value: string;
  onChange: (value: string, mentionedUserIds?: string[]) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  placeholder?: string;
}

export const MentionInput: React.FC<MentionInputProps> = ({
  value,
  onChange,
  onKeyDown,
  placeholder
}) => {
  const [mentionAnchorEl, setMentionAnchorEl] = useState<null | HTMLElement>(null);
  const [mentionSearch, setMentionSearch] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { data: users } = useUserList();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [mentions, setMentions] = useState<Array<{
    id: string;
    name: string;
    index: number;
  }>>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const position = e.target.selectionStart || 0;
    setCursorPosition(position);

    const beforeCursor = newValue.slice(0, position);
    const mentionMatch = beforeCursor.match(/@(\S*)$/);

    if (mentionMatch) {
      setMentionSearch(mentionMatch[1].toLowerCase());
      setMentionAnchorEl(e.target);
      setSelectedIndex(0);
    } else {
      setMentionAnchorEl(null);
    }

    const updatedMentions = mentions.filter(mention => {
      const mentionText = `@${mention.name}`;
      return newValue.includes(mentionText);
    });

    if (updatedMentions.length !== mentions.length) {
      setMentions(updatedMentions);
      const userIds = updatedMentions.map(mention => mention.id);
      onChange(newValue, userIds);
    } else {
      onChange(newValue, mentions.map(mention => mention.id));
    }
  };

  const filteredUsers = users?.filter(user => 
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(mentionSearch.toLowerCase()) ||
    user.email.toLowerCase().includes(mentionSearch.toLowerCase())
  ).map(user => {
    const userInfo: UserInfoType = {
      ...user,
      profilePicture: user.profilePicture || undefined,
      user_type: (user as UserInfoType).user_type || 'Freelancer'
    };
    return userInfo;
  }) || [];

  const handleMentionSelect = (user: UserInfoType) => {
    console.log('Selected user:', {
      user,
      id: user.id,
      fullName: `${user.firstName} ${user.lastName}`
    });
    
    const beforeMention = value.slice(0, cursorPosition).replace(/@\S*$/, '');
    const afterMention = value.slice(cursorPosition);
    const fullName = `${user.firstName} ${user.lastName}`;
    const newValue = `${beforeMention}@${fullName}${afterMention}`;
    
    const newMention = {
      id: user.id,
      name: fullName,
      index: beforeMention.length + 1
    };
    
    const newMentions = [...mentions, newMention];
    console.log('Updated mentions:', newMentions);
    
    setMentions(newMentions);
    onChange(newValue, newMentions.map(mention => mention.id));
    setMentionAnchorEl(null);
    setSelectedIndex(0);
    
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const isOpen = Boolean(mentionAnchorEl) && filteredUsers.length > 0;

    if (isOpen) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev => 
            prev < filteredUsers.length - 1 ? prev + 1 : prev
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => 
            prev > 0 ? prev - 1 : prev
          );
          break;
        case 'Enter':
          e.preventDefault();
          if (filteredUsers[selectedIndex]) {
            handleMentionSelect(filteredUsers[selectedIndex]);
          }
          break;
        case 'Escape':
          e.preventDefault();
          setMentionAnchorEl(null);
          setSelectedIndex(0);
          break;
        case 'Tab':
          if (isOpen && filteredUsers[selectedIndex]) {
            e.preventDefault();
            handleMentionSelect(filteredUsers[selectedIndex]);
          }
          break;
        default:
          onKeyDown?.(e);
      }
    } else {
      onKeyDown?.(e);
    }
  };

  return (
    <>
      <TextField
        inputRef={inputRef}
        fullWidth
        multiline
        rows={2}
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'background.paper',
          },
        }}
      />
      <Popper
        open={Boolean(mentionAnchorEl) && filteredUsers.length > 0}
        anchorEl={mentionAnchorEl}
        placement="bottom-start"
        style={{ zIndex: 1300 }}
      >
        <Paper elevation={3}>
          <List sx={{ maxHeight: 200, overflow: 'auto', width: 300 }}>
            {filteredUsers.map((user, index) => (
              <ListItem key={user.id} disablePadding>
                <ListItemButton 
                  onClick={() => handleMentionSelect(user)}
                  selected={selectedIndex === index}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'primary.light',
                      '&:hover': {
                        backgroundColor: 'primary.light',
                      },
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={user.profilePicture}
                      alt={`${user.firstName} ${user.lastName}`}
                    >
                      {user.firstName?.[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={`${user.firstName} ${user.lastName}`}
                    secondary={user.email}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
    </>
  );
}; 