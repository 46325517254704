import React, { useState, useCallback, useRef } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Snackbar,
  Alert,
  Dialog,
  Box,
  Stack,
  Avatar,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { AddComment, NotificationImportant, Message } from "@mui/icons-material";
import { PageTitle } from "components/PageTitle";
import { useManageUsers } from "hooks/admin";
import { CreateUser } from "./components/CreateUser";
import {
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridRowModel,
  GridRenderCellParams,
  GridCellParams,
} from "@mui/x-data-grid-pro";

const booleanOptions = ["true", "false"];
const user_types = ["freelancer", "stakeholder", "admin"];

const UserManagement = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    users,
    isLoading,
    isError,
    updateUser,
    // deleteUser,
    resendVerificationEmail,
  } = useManageUsers();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInfoType | null>(null);
  const notesRef = useRef("");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleOpen = useCallback((user: UserInfoType) => {
    setSelectedUser((prevState) => ({ ...prevState, ...user }));
    notesRef.current = user.notes || "";
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSaveNotes = useCallback(async () => {
    if (selectedUser) {
      const userUpdate: UserInfoType = {
        id: selectedUser.id,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        user_type: selectedUser.user_type,
        email: selectedUser.email,
        phone: selectedUser.phone,
        is_active: selectedUser.is_active,
        emailVerified: selectedUser.emailVerified,
        notes: notesRef.current,
        profilePicture: selectedUser.profilePicture,
        skills: selectedUser.skills,
      };
      await updateUser(userUpdate);
    }
    handleClose();
  }, [selectedUser, handleClose]);

  const handleChangeNotes = (event: { target: { value: string } }) => {
    notesRef.current = event.target.value;
  };

  const handleNotifyClick = (row: { email: string }) => async () => {
    try {
      await resendVerificationEmail(row.email);
      setSnackbar({
        open: true,
        message: "Verification email sent successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to send verification email",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCreateUserSuccess = () => {
    setSnackbar({
      open: true,
      message: "User created successfully",
      severity: "success",
    });
  };

  const handleCreateUserError = (error: string) => {
    setSnackbar({
      open: true,
      message: `Failed to create user: ${error}`,
      severity: "error",
    });
  };

  const handleProcessRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    try {
      const userUpdate: UserInfoType = {
        id: newRow.id,
        firstName: newRow.firstName,
        lastName: newRow.lastName,
        user_type: newRow.user_type,
        email: newRow.email,
        phone: newRow.phone || '',
        is_active: newRow.is_active,
        emailVerified: newRow.email_verified,
        notes: newRow.notes,
        profilePicture: newRow.profilePicture,
        skills: newRow.skills,
      };

      await updateUser(userUpdate);
      setSnackbar({
        open: true,
        message: "User updated successfully",
        severity: "success",
      });
      return newRow;
    } catch (error) {
      console.error('Error updating user:', error);
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : "Failed to update user",
        severity: "error",
      });
      return oldRow;
    }
  };


  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    { field: "lastName", headerName: "Last Name", width: 150, editable: true },
    {
      field: "user_type",
      headerName: "User Type",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: user_types,
    },
    { field: "email", headerName: "Email", width: 300 },
    { field: "phone", headerName: "Phone", width: 150, editable: true },
    {
      field: "is_active",
      headerName: "Active",
      width: 75,
      editable: true,
      type: "singleSelect",
      valueOptions: booleanOptions,
    },
    {
      field: "email_verified",
      headerName: "Email Verified",
      width: 130,
      editable: true,
      type: "singleSelect",
      valueOptions: booleanOptions,
    },
    {
      field: "Resend Email",
      type: "actions",
      headerName: "Resend Verification",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<NotificationImportant />}
          label="Send Reminder Email"
          onClick={handleNotifyClick(params.row)}
          disabled={params.row.email_verified === true}
        />,
      ],
    },
    {
      field: "add_notes",
      type: "actions",
      headerName: "Notes",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<AddComment />}
          label="Add Notes"
          onClick={() => handleOpen(params.row)}
        />,
      ],
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading users</div>;
  }

  return (
    <>
      <PageTitle
        title="User Management"
        subtitle="This page allows you to create, update, or delete portal users. "
        bulletPoints={[
          "Double-click the row to start editing a user, then click the save icon that appears on the right side.",
          "Use the 'Remind' icon allows you to resend a verification email to a user.",
          "Share user notes with the team by clicking the 'Add Notes' icon.",
        ]}
      />

      <CreateUser
        onSuccess={handleCreateUserSuccess}
        onError={handleCreateUserError}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent sx={{ width: "550px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="notes"
            label="Notes"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={10}
            defaultValue={notesRef.current}
            onChange={handleChangeNotes}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveNotes}>Save</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ height: 'auto', width: "100%" }}>
        <DataGridPro
          rows={users || []}
          columns={columns}
          loading={isLoading}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.error("Error updating row:", error);
          }}
          editMode="row"
          rowHeight={40}
          pagination
          paginationModel={{ page: 0, pageSize: 15 }}
        />
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserManagement;
