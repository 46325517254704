import React, { useEffect } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";

interface AuthRouteProps {
  children: React.ReactNode;
}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const { isAuthenticated, userInfo, isLoading } = useAuth();
  const navigate = useNavigate();
  const userType = userInfo?.user_type;
  useEffect(() => {
    const handleRedirect = () => {
      navigate(`/${userType}`);
    };
    if (isAuthenticated && userType) {
      handleRedirect();
    }
  }, [isAuthenticated, navigate, userType, isLoading]);

  return <>{children}</>;
};

export default AuthRoute;
