import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  ButtonGroup,
  Button,
  Divider,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineOppositeContent,
} from '@mui/lab';
import { DateTime } from 'luxon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useUserList } from 'hooks/shared/user/useUserList';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';

interface ActivityListProps {
  activities: BoardActivityEvent[];
  onClose: () => void;
}

const getActivityIcon = (type: BoardActivityEventType) => {
  switch (type) {
    case 'card-create':
      return '📝';
    case 'card-move':
      return '🔄';
    case 'card-update':
      return '✏️';
    case 'card-archive':
      return '📦';
    case 'card-delete':
      return '🗑️';
    case 'list-archive':
      return '📂';
    case 'label-delete':
      return '🏷️';
    case 'label-create':
      return '🎯';
    case 'label-update':
      return '🔖';
    case 'user-add':
      return '👥';
    case 'user-remove':
      return '👤';
    case 'card-comment':
      return '💬';
    case 'card-comment-mention':
      return '💬';
    default:
      return '📌';
  }
};

export const ActivityList: React.FC<ActivityListProps> = ({
  activities,
  onClose,
}) => {
  const { data: users } = useUserList();
  const [searchQuery, setSearchQuery] = useState('');

  const getUserName = (userId: string) => {
    const user = users?.find(u => u.id === userId);
    return user ? `${user.firstName} ${user.lastName}` : 'Unknown User';
  };

  const filteredActivities = useMemo(() => {
    if (!searchQuery.trim()) return activities;
    
    return activities.filter(activity => {
      const searchText = searchQuery.toLowerCase();
      const actorName = getUserName(activity.actor).toLowerCase();
      const info = activity.additionalInfo?.toLowerCase() || '';
      
      return actorName.includes(searchText) || info.includes(searchText);
    });
  }, [activities, searchQuery, users]);

  const groupActivitiesByDate = (activities: BoardActivityEvent[]) => {
    const groups: { [key: string]: BoardActivityEvent[] } = {};
    activities.forEach(activity => {
      const date = DateTime.fromISO(activity.dateTimeOccured).toFormat('yyyy-MM-dd');
      if (!groups[date]) groups[date] = [];
      groups[date].push(activity);
    });
    return groups;
  };

  const groupedActivities = groupActivitiesByDate(filteredActivities);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default',
      }}
    >
      {/* Search Input */}
      <Box
        sx={{
          p: 2,
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
        }}
      >
        <TextField
          fullWidth
          size="small"
          placeholder="Search activities..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: 'background.default',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            },
          }}
        />
      </Box>

      {/* Activity Timeline */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          px: { xs: 2, sm: 4 },
          py: 3,
        }}
      >
        {Object.entries(groupedActivities).length > 0 ? (
          Object.entries(groupedActivities).map(([date, dateActivities]) => (
            <Box key={date} sx={{ mb: 4 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: 'text.secondary',
                  mb: 2,
                  textTransform: 'uppercase',
                  letterSpacing: 0.5,
                  fontWeight: 600,
                }}
              >
                {DateTime.fromISO(date).toFormat('cccc, LLLL d')}
              </Typography>
              <Timeline
                sx={{
                  [`& .MuiTimelineItem-root`]: {
                    minHeight: 'auto',
                    '&:before': {
                      flex: 0,
                      padding: 0,
                    },
                  },
                }}
              >
                {dateActivities.map((activity) => (
                  <TimelineItem key={activity.id}>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          bgcolor: 'primary.main',
                          boxShadow: 1,
                          p: 1,
                          my: 0.5,
                        }}
                      >
                        {getActivityIcon(activity.type)}
                      </TimelineDot>
                      <TimelineConnector sx={{ bgcolor: 'grey.200' }} />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        py: '12px',
                        px: 2,
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: 'background.paper',
                          p: 2,
                          borderRadius: 1,
                          boxShadow: 1,
                          '&:hover': {
                            bgcolor: 'grey.50',
                          },
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          sx={{ fontWeight: 600 }}
                        >
                          {getUserName(activity.actor)}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.primary',
                            mt: 0.5,
                            lineHeight: 1.5,
                          }}
                        >
                          {activity.additionalInfo}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: 'text.secondary',
                            mt: 1,
                            display: 'block',
                          }}
                        >
                          {DateTime.fromISO(activity.dateTimeOccured).toFormat('HH:mm')}
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              textAlign: 'center',
              py: 4,
              color: 'text.secondary',
            }}
          >
            <Typography variant="body1">
              No activities found matching your search
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}; 