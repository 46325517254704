import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useTimesheet } from "hooks/shared/timesheet/useTimesheet";
import { useAuth } from "hooks/auth/useAuth";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import TimeTrackerInput from "./components/TimeTrackerInput";
import { adjustEndTime, calculateDuration } from "hooks/shared/timesheet/utils";
import TimeTrackerGrid from "./components/TimeTrackerGrid";

const TimeTracker: React.FC = () => {
  const [entries, setEntries] = useState<Timesheet[]>([]);
  const { userInfo } = useAuth();
  const userId = userInfo?.id;

  const {
    getTimesheets,
    deleteTimesheet,
    getClientsProjectsAndAssignments,
    updateTimesheet,
  } = useTimesheet(userId || "");

  const {
    data: clientsProjectsAndAssignments,
    isLoading: isClientsProjectsAndAssignmentsLoading,
    error: clientsProjectsAndAssignmentsError,
  } = getClientsProjectsAndAssignments();

  

  const {
    data: timesheets,
    isLoading: isTimesheetsLoading,
    refetch: refetchTimesheets,
  } = getTimesheets({ userId: userInfo?.id });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  useEffect(() => {
    if (timesheets) {
      setEntries(timesheets);
    }
  }, [timesheets]);

  const handleDeleteEntry = async (id: string) => {
    await deleteTimesheet.mutateAsync(id);
    refetchTimesheets();
  };

  const handleProcessRowUpdate = useCallback(
    async (newRow: Timesheet, oldRow: Timesheet) => {
      try {
        const date = DateTime.fromISO(newRow.date);
        const startTime = DateTime.fromISO(newRow.startTime);
        let endTime = DateTime.fromISO(newRow.endTime);

        if (!date.isValid || !startTime.isValid || !endTime.isValid) {
          console.error("Invalid date or time values:", {
            date,
            startTime,
            endTime,
          });
          throw new Error("Invalid date or time values");
        }

        if (newRow.duration !== oldRow.duration) {
          endTime = DateTime.fromISO(adjustEndTime(startTime.toISO() || "", newRow.duration));
        }

        const updatedRow: Timesheet = {
          ...newRow,
          date: date.toISODate() || "",
          startTime: startTime.toISO() || "",
          endTime: endTime.toISO() || "",
          duration: newRow.duration,
          longDescription: newRow.longDescription || oldRow.longDescription,
        };

        setEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.id === updatedRow.id ? updatedRow : entry
          )
        );

        await updateTimesheet.mutateAsync({
          ...updatedRow,
          longDescription: updatedRow.longDescription,
        });

        await refetchTimesheets();
        
        return updatedRow;
      } catch (error) {
        console.error("Failed to update timesheet:", error);
        setEntries((prevEntries) =>
          prevEntries.map((entry) => (entry.id === newRow.id ? oldRow : entry))
        );
        throw error;
      }
    },
    [updateTimesheet, refetchTimesheets]
  );

  const groupEntriesByWeek = useMemo(() => {
    return entries.reduce(
      (acc, entry) => {
        const entryDate = DateTime.fromISO(entry.date);
        const weekStart = entryDate.startOf("week");
        const weekEnd = entryDate.endOf("week");
        const weekKey = `${weekStart.toFormat("yyyy-MM-dd")} to ${weekEnd.toFormat("yyyy-MM-dd")}`;

        if (!acc[weekKey]) {
          acc[weekKey] = [];
        }
        acc[weekKey].push(entry);
        return acc;
      },
      {} as Record<string, Timesheet[]>
    );
  }, [entries]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    const newItemsPerPage = event.target.value as number;
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);

    const sortedEntries = [...entries].sort(
      (a, b) =>
        DateTime.fromISO(b.date).toMillis() -
        DateTime.fromISO(a.date).toMillis()
    );
    setEntries(sortedEntries.slice(0, newItemsPerPage * 5));
  };

  const paginatedEntries = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return entries.slice(startIndex, endIndex);
  }, [entries, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(entries.length / itemsPerPage);
  const totalItems = entries.length;
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  if (isClientsProjectsAndAssignmentsLoading || isTimesheetsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (clientsProjectsAndAssignmentsError) {
    return (
      <Typography color="error">
        Error loading data. Please try again.
      </Typography>
    );
  }

  const activeProjectAssignments = clientsProjectsAndAssignments?.projectAssignments || [];

  return (
    <Box sx={{ height: "100%", width: "100%", p: 2 }}>
      <TimeTrackerInput
        activeProjectAssignments={activeProjectAssignments}
      />

      {entries.length === 0 ? (
        <Box 
          sx={{ 
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", 
            alignItems: "center", 
            height: "200px",
            backgroundColor: "background.paper",
            borderRadius: 1,
            mt: 2,
            gap: 1
          }}
        >
          <Typography variant="h6" color="text.secondary">
            No timesheet entries found, start logging time to get started!
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ fontStyle: "italic" }}>
            If you're having any issues, please contact <span style={{ fontWeight: "bold" }}>admin@freetech.co</span>.
          </Typography>
        </Box>
      ) : (
        <>
          {Object.entries(groupEntriesByWeek).map(([weekKey, weekEntries]) => (
            <Box key={weekKey} sx={{ position: "relative", mb: 4 }}>
              <TimeTrackerGrid
                weekEntries={weekEntries}
                weekKey={weekKey}
                paginatedEntries={paginatedEntries}
                handleProcessRowUpdate={handleProcessRowUpdate}
                activeProjectAssignments={activeProjectAssignments}
                handleDeleteEntry={handleDeleteEntry}
              />
            </Box>
          ))}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
              <Typography variant="body2" sx={{ ml: 2 }}>
                {`${startItem}-${endItem} of ${totalItems} items`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ mr: 2 }}>
                Items per page:
              </Typography>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TimeTracker;
