import React from 'react';
import { Box, Typography, CircularProgress, Grid, Paper, Button } from '@mui/material';
import StakeholderProjectCard from '../components/StakeholderProjectCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useStakeholderProjects } from 'hooks/stakeholder/useStakeholderProjects';

const StakeholderProjects = () => {
  const { data: projects, isLoading, error } = useStakeholderProjects();
  

  const handleSubmitNewIdea = () => {
    // TODO: Implement the logic to open a modal or navigate to a new page for idea submission
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center', maxWidth: 400 }}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Error
            </Typography>
            <Typography color="error">{error.message}</Typography>
          </Paper>
        </Box>
      );
    }

    return (
      <Box sx={{ p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5">Stakeholder Projects</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LightbulbIcon />}
            onClick={handleSubmitNewIdea}
          >
            Submit a New Idea
          </Button>
        </Box>
        {!projects?.length ? (
          <Typography>No projects found for this stakeholder.</Typography>
        ) : (
          <Grid container spacing={3}>
            {projects.map((project) => (
              <Grid item xs={12} sm={6} md={4} key={project.id}>
                <StakeholderProjectCard project={project} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  };

  return <>{renderContent()}</>;
};

export default StakeholderProjects;
