import { useQuery, useMutation } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { useAuth } from "hooks/auth/useAuth";

// Standalone function for logging activity
export const logBoardActivity = async (
  boardId: string,
  type: BoardActivityEventType,
  userId: string,
  additionalInfo?: string
) => {
  try {
    const activityRef = collection(db, "boards", boardId, "activity");

    await addDoc(activityRef, {
      type,
      actor: userId,
      additionalInfo,
      dateTimeOccured: DateTime.now().toISO(),
    });
  } catch (error) {
    console.error("Error logging board activity:", error);
  }
};

export const useBoardActivity = (boardId: string) => {
  // Query to fetch board activities
  const {
    data: activities,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["board", boardId, "activity"],
    queryFn: async () => {
      if (!boardId) throw new Error("Board ID is required");

      const activityRef = collection(db, "boards", boardId, "activity");
      // Query with descending order to get latest activities first
      const q = query(activityRef, orderBy("dateTimeOccured", "desc"));
      const snapshot = await getDocs(q);

      // Map and sort by date to ensure latest first
      const activities = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as BoardActivityEvent[];

      return activities.sort(
        (a, b) =>
          DateTime.fromISO(b.dateTimeOccured).toMillis() -
          DateTime.fromISO(a.dateTimeOccured).toMillis()
      );
    },
    enabled: !!boardId,
  });

  return {
    activities,
    isLoading,
    error,
  };
};
