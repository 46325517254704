import React, { useState } from 'react'
import { Box, Button, Badge, Menu, MenuItem, List, ListItem, ListItemIcon, ListItemText, Divider, Typography, Avatar } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import { useNavigate } from 'react-router-dom'
import { Payment, Assignment, Description } from '@mui/icons-material'
import { INotifications, useNotifications } from '../../../../hooks/notifications/useNotifications'
import { useAuth } from 'hooks/auth/useAuth'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { getIcon, getNotificationMessage } from 'views/Shared/Notifications/getNotificationDetails'
import { handleNotificationClick } from 'views/Shared/Notifications/handleNotificationClick'
const NotificationAccordion: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const { userInfo } = useAuth()
  const { notifications, markAsRead, markAllAsRead } = useNotifications(userInfo?.id || '')
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


  const unreadNotifications = notifications
    .sort((a, b) => {
      const dateA = a.createdAt?.toDate?.() || new Date(0);
      const dateB = b.createdAt?.toDate?.() || new Date(0);
      return dateB.getTime() - dateA.getTime();
    })
    .filter(n => !n.read);


  const handleMarkAsRead = (event: React.MouseEvent, notificationId: string) => {
    event.stopPropagation()
    markAsRead.mutate(notificationId)
  }

  return (
    <Box>
      <Button onClick={handleClick} sx={{ padding: 0, minWidth: 'auto', marginRight: 1 }}>
        <Badge badgeContent={unreadNotifications.length} color="error" overlap="circular">
          <MailIcon />
        </Badge>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 6,
            width: '30ch',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
            borderRadius: '16px',
            border: '1px solid #e0e0e0',
          },
        }}
        TransitionProps={{
          timeout: 350,
        }}
      >
        <Box sx={{ padding: '8px 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'medium', color: 'text.secondary' }}>
            Notifications
          </Typography>
          <Button 
            size="small" 
            onClick={() => markAllAsRead.mutate()}
            disabled={markAllAsRead.isPending || unreadNotifications.length === 0}
            sx={{ color: 'primary.main' }}
          >
            Mark all as read
          </Button>
        </Box>
        <Divider />
        <List>
          {unreadNotifications.length > 0 ? (
            unreadNotifications.map(notification => (
              <ListItem 
                key={notification.id} 
                component="button"
                onClick={() => {
                  const destination = handleNotificationClick(notification);
                  if (destination) {
                    navigate(destination);
                  }
                }}
                sx={{ 
                  backgroundColor: 'white', 
                  textAlign: 'left',
                  '&:hover': { backgroundColor: 'grey.100' },
                  padding: '10px 16px',
                  borderBottom: '1px solid #f0f0f0',
                  borderRadius: '12px',
                  margin: '4px 0',
                  border: '1px solid rgba(173, 216, 230, 0.5)',
                  position: 'relative',
                }}
              >
                <ListItemIcon>
                  {React.createElement(getIcon(notification.type) || 'div', {
                    sx: { 
                      color: 'primary.main',
                      fontSize: 24
                    }
                  })}
                </ListItemIcon>
                <ListItemText 
                  primary={getNotificationMessage(notification)} 
                  secondary={notification.createdAt?.toDate?.() 
                    ? notification.createdAt.toDate().toLocaleString()
                    : 'No date'
                  }
                  primaryTypographyProps={{ 
                    fontSize: '0.875rem',
                    fontWeight: 'medium',
                    color: 'text.primary',
                  }}
                />
                <Button
                  onClick={(e) => handleMarkAsRead(e, notification.id)}
                  sx={{
                    minWidth: 'auto',
                    padding: '4px',
                    position: 'absolute',
                    right: 8,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }
                  }}
                >
                  <VisibilityIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                </Button>
              </ListItem>
            ))
          ) : (
            <Typography sx={{ padding: '16px', textAlign: 'center', color: 'text.secondary' }}>
              No notifications
            </Typography>
          )}
        </List>
        <Divider />
        <MenuItem 
          onClick={() => { handleClose(); navigate('/notifications') }}
          sx={{ 
            justifyContent: 'center', 
            fontWeight: 'medium', 
            color: 'primary.main', 
            '&:hover': { backgroundColor: 'primary.light', color: 'white' }
          }}
        >
          See All
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default NotificationAccordion
