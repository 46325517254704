import React from "react";
import { Account, DashboardLayout } from "@toolpad/core";
import { Box, Button } from "@mui/material";
import Footer from "components/Footer";
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationAccordion from "./components/NotificationAccordion";
import { useAuth } from "hooks/auth/useAuth";
import { NotificationAdd, Person } from "@mui/icons-material";

// const ToolbarActions: React.FC = () => {
//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
//       <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
//         <TimerDisplay />
//       </Box>
//     </Box>
//   );
// };

export const CoreLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  // i luv cursor
  const isFreeloBoard = location.pathname.startsWith('/freelo/') && location.pathname !== '/freelo';
  const isKnowledgeBase = location.pathname.startsWith('/knowledge-base');

  return (
      <DashboardLayout
      slots={{
        toolbarAccount: () => (
          <>
            <Box display="flex" alignItems="center">
              <Box sx={{ marginRight: 1 }}>
                <NotificationAccordion />
              </Box>
              <AccountViewProfile />

            </Box>
          </>
        ),
      }}
        // slots={{
        //   toolbarActions: ToolbarActions,
        // }}
        defaultSidebarCollapsed={true}
        slotProps={{
          toolbarAccount: {
            slotProps: {
              signInButton: {
                sx: { display: "none" },
              },
            },
          },
        }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ...(isFreeloBoard || isKnowledgeBase ? {
              marginTop: 0,
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
              minHeight: '100vh',
            } : {
              marginTop: 2,
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingLeft: 2,
              paddingRight: 2,
              minHeight: '80vh',
            })
          }}
        >
          {children}
        </Box>
        <Footer />
      </DashboardLayout>
  );
};

export default CoreLayout

const AccountViewProfile = () => {
  const { userInfo } = useAuth()
  const navigate = useNavigate()

  return (
    <Account
      slots={{
        menuItems: () => (
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Button onClick={() => navigate('/profile')} startIcon={<Person />}>
              View Profile
            </Button>
            {userInfo?.user_type === 'admin' && (
              <Button onClick={() => navigate('/profile#notifications')} startIcon={<NotificationAdd />}>
                Notifications
              </Button>
            )}
          </Box>
        ),
      }}
    />
  )
}