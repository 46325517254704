import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { Box } from '@mui/material';

interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
  onSave?: () => void;
  placeholder?: string;
  minHeight?: number | string;
  readOnly?: boolean;
  preview?: 'live' | 'edit' | 'preview';
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  value,
  onChange,
  onSave,
  placeholder = 'Write something...',
  minHeight = 200,
  readOnly = false,
  preview = 'live'
}) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey) && onSave) {
      e.preventDefault();
      onSave();
    }
  };

  return (
    <Box 
      sx={{ 
        width: '100%',
        '& .w-md-editor': {
          backgroundColor: 'background.paper',
          minHeight,
        },
        '& .w-md-editor-text': {
          color: 'text.primary',
        },
        '& .w-md-editor-text-pre > code': {
          color: 'text.primary !important',
        },
        '& .w-md-editor-text-input': {
          color: 'text.primary !important',
        },
        '& .wmde-markdown': {
          backgroundColor: 'background.paper',
          color: 'text.primary',
        },
        '& .wmde-markdown-color': {
          color: 'text.primary !important',
        },
        // Preview styles
        '& .wmde-markdown h1, & .wmde-markdown h2, & .wmde-markdown h3': {
          color: 'text.primary',
          borderBottomColor: 'divider',
        },
        '& .wmde-markdown p': {
          color: 'text.primary',
        },
        // Code blocks
        '& .wmde-markdown-pre': {
          backgroundColor: '#2b2b2b !important',
          border: '1px solid #3e3e3e',
          borderRadius: '4px',
        },
        '& .wmde-markdown-pre code': {
          color: '#e6e6e6 !important',
          backgroundColor: 'transparent !important',
          textShadow: 'none',
        },
      }}
      onKeyDown={handleKeyDown}
    >
      <MDEditor
        value={value}
        onChange={(val) => onChange(val || '')}
        preview={readOnly ? 'preview' : preview}
        hideToolbar={readOnly}
        visibleDragbar={false}
      />
    </Box>
  );
};

export default MarkdownEditor; 