import { functionsBaseUrl } from "constants/functionsBaseUrl";
import { UseEversignTemplateInput, UseEversignTemplateOutput } from "./types";

interface GenerateCSAInput {}
interface GenerateCSAOutput {}

interface DownloadCSAInput {}
interface DownloadCSAOutput {}

interface CreateFirebaseUserInput {}
interface CreateFirebaseUserOutput {}

interface DeleteFirebaseUserInput {}
interface DeleteFirebaseUserOutput {}

interface SendEmailInput {}
interface SendEmailOutput {}

interface ResendVerificationEmailInput {}
interface ResendVerificationEmailOutput {}

interface DownloadFinalPDFInput {
  document_hash: string;
  audit_trail?: boolean;
}
interface DownloadFinalPDFOutput {}

interface GetClockifyDataForFreelancerInput {
  dateRangeStart: string;
  dateRangeEnd: string;
  firebaseUserIds: string[];
}
interface GetClockifyDataForFreelancerOutput {
  firebaseUserId: string;
  report: ClockifyData;
}

interface CancelDocumentInput {
  document_hash: string;
}
interface CancelDocumentOutput {
  success: boolean;
}

const functionUrlsConfig = {
  users: {
    createFirebaseUser: {
      url: `${functionsBaseUrl}/users-createFirebaseUser`,
      method: "POST",
      input: {} as CreateFirebaseUserInput,
      output: {} as CreateFirebaseUserOutput,
    },
    deleteFirebaseUser: {
      url: `${functionsBaseUrl}/users-deleteFirebaseUser`,
      method: "DELETE",
      input: {} as DeleteFirebaseUserInput,
      output: {} as DeleteFirebaseUserOutput,
    },
  },
  mail: {
    sendEmail: {
      url: `${functionsBaseUrl}/mail-sendEmail`,
      method: "POST",
      input: {} as SendEmailInput,
      output: {} as SendEmailOutput,
    },
    resendVerificationEmail: {
      url: `${functionsBaseUrl}/mail-resendVerificationEmail`,
      method: "POST",
      input: {} as ResendVerificationEmailInput,
      output: {} as ResendVerificationEmailOutput,
    },
  },
  eversign: {
    useEversignTemplate: {
      url: `${functionsBaseUrl}/eversign-useEversignTemplate`,
      method: "POST",
      input: {} as UseEversignTemplateInput,
      output: {} as UseEversignTemplateOutput,
    },
    downloadFinalPDF: {
      url: `${functionsBaseUrl}/eversign-downloadFinalPDF`,
      method: "POST",
      input: {} as DownloadFinalPDFInput,
      output: {} as DownloadFinalPDFOutput,
    },
    cancelDocument: {
      url: `${functionsBaseUrl}/eversign-cancelDocument`,
      method: "POST",
      input: {} as CancelDocumentInput,
      output: {} as CancelDocumentOutput,
    },
  },
  misc: {
    generateCSA: {
      url: `${functionsBaseUrl}/generateCSA`,
      method: "POST",
      input: {} as GenerateCSAInput,
      output: {} as GenerateCSAOutput,
    },
    downloadCSA: {
      url: `${functionsBaseUrl}/downloadCSA`,
      method: "GET",
      input: {} as DownloadCSAInput,
      output: {} as DownloadCSAOutput,
    },
  },
  clockify: {
    getClockifyDataForFreelancer: {
      url: `${functionsBaseUrl}/clockify/reports/full-user-report`,
      method: "POST",
      input: {} as GetClockifyDataForFreelancerInput,
      output: {} as GetClockifyDataForFreelancerOutput[],
    },
  },
} as const;

export const functionUrls: NestedFunctionUrls<typeof functionUrlsConfig> =
  functionUrlsConfig;
