import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery } from '@tanstack/react-query';

interface YearlyStats {
  amountPaid: number;
  totalHours: number;
  averageBillRate: number;
  uniqueClients: number;
}

interface MonthlyEarnings {
  month: string;
  earnings: number;
}

export const useFreelancerHomeStatistics = (selectedYear: number) => {
  const { userInfo } = useAuth();
  
  const fetchAssignments = async (userId: string) => {
    const db = getFirestore();
    const userAssignmentsRef = collection(db, `users/${userId}/projectAssignments`);
    const userAssignmentsSnapshot = await getDocs(userAssignmentsRef);

    const assignmentsData: ProjectAssignment[] = [];

    for (const userAssignmentDoc of userAssignmentsSnapshot.docs) {
      const userAssignmentData = userAssignmentDoc.data();
      const { clientId, assignmentId, projectId } = userAssignmentData;

      const projectAssignmentRef = doc(db, `clients/${clientId}/projectAssignments/${assignmentId}`);
      const projectAssignmentDoc = await getDoc(projectAssignmentRef);
      const projectAssignmentData = projectAssignmentDoc.data() as ProjectAssignment;

      if (projectAssignmentData) {
        const projectRef = doc(db, `clients/${clientId}/projects/${projectId}`);
        const projectDoc = await getDoc(projectRef);
        const projectData = projectDoc.data();

        assignmentsData.push({
          ...projectAssignmentData,
          id: assignmentId,
          projectId,
          projectName: projectData?.name || "Unknown Project",
          engineerId: userId,
          startDate: projectAssignmentData.startDate || null,
          endDate: projectAssignmentData.endDate || null,
        });
      }
    }
    return assignmentsData;
  };

  const calculateYearlyStats = (assignments: ProjectAssignment[], year: number): YearlyStats => {
    const clientSet = new Set();
    let totalPaid = 0;
    let totalHours = 0;
    let totalBillRate = 0;
    let assignmentCount = 0;

    assignments.forEach((assignment) => {
      const startDate = assignment.startDate ? new Date(assignment.startDate) : null;
      const endDate = assignment.endDate ? new Date(assignment.endDate) : new Date();

      if (startDate && startDate.getFullYear() <= year && endDate.getFullYear() >= year) {
        clientSet.add(assignment.clientId);
        totalPaid += assignment.amountPaid || 0;
        totalHours += assignment.utilizedHours || 0;
        totalBillRate += assignment.billableRate || 0;
        assignmentCount++;
      }
    });

    return {
      amountPaid: totalPaid,
      totalHours: totalHours,
      averageBillRate: assignmentCount > 0 ? totalBillRate / assignmentCount : 0,
      uniqueClients: clientSet.size,
    };
  };

  const calculateMonthlyEarnings = (assignments: ProjectAssignment[], year: number): MonthlyEarnings[] => {
    const monthlyEarnings: { [key: string]: number } = {};
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    assignments.forEach(assignment => {
      if (assignment.startDate) {
        const startDate = new Date(assignment.startDate);
        const endDate = assignment.endDate ? new Date(assignment.endDate) : new Date();
        
        let currentDate = new Date(startDate);
        currentDate.setDate(1);

        while (currentDate <= endDate && currentDate.getFullYear() <= year) {
          if (currentDate.getFullYear() === year) {
            const month = monthNames[currentDate.getMonth()];
            const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
            let daysWorked = daysInMonth;

            if (currentDate.getMonth() === startDate.getMonth() && currentDate.getFullYear() === startDate.getFullYear()) {
              daysWorked = daysInMonth - startDate.getDate() + 1;
            } else if (currentDate.getMonth() === endDate.getMonth() && currentDate.getFullYear() === endDate.getFullYear()) {
              daysWorked = endDate.getDate();
            }

            const monthlyEarning = (assignment.billableRate || 0) * (assignment.utilizedHours || 160) * (daysWorked / daysInMonth);
            monthlyEarnings[month] = (monthlyEarnings[month] || 0) + monthlyEarning;
          }
          currentDate.setMonth(currentDate.getMonth() + 1);
        }
      }
    });

    return monthNames.map(month => ({
      month,
      earnings: Math.round(monthlyEarnings[month] || 0)
    }));
  };

  const { data: assignments = [], isLoading, error } = useQuery({
    queryKey: ['freelancerHomeStatistics', userInfo?.id],
    queryFn: () => fetchAssignments(userInfo?.id || ''),
    enabled: !!userInfo?.id,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const stats = calculateYearlyStats(assignments, selectedYear);
  const monthlyEarnings = calculateMonthlyEarnings(assignments, selectedYear);

  return {
    assignments,
    stats,
    monthlyEarnings,
    isLoading,
    error,
  };
}; 