import { doc, getDoc, collection, query, where, getDocs, collectionGroup } from '@firebase/firestore';
import { db } from 'config/firebase';

export const loadSingleStakeholder = async (stakeholderId: string): Promise<Stakeholder | null> => {
  try {
    // Get user document
    const userDocRef = doc(db, 'users', stakeholderId);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      return null;
    }

    const userData = userDocSnap.data() as UserInfoType;
    

    // Get stakeholder document
    const stakeholdersRef = collectionGroup(db, 'stakeholders');
    const stakeholderQuery = query(stakeholdersRef, where('id', '==', stakeholderId));
    const stakeholderSnap = await getDocs(stakeholderQuery);

    if (stakeholderSnap.empty) {
      return null;
    }

    const stakeholderData = stakeholderSnap.docs[0].data();

    // Combine user and stakeholder data
    return {
      ...userData,
      ...stakeholderData,
      id: stakeholderId,
    };

  } catch (error) {
    console.error('Error loading stakeholder:', error);
    return null;
  }
};