import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { HillChartClass } from './HillChartClass';

interface SingleHillChartProps {
  value: number;
  onChange: (value: number) => void;
  onSave: (value: number) => void;
  disabled?: boolean;
}

export const SingleHillChart: React.FC<SingleHillChartProps> = ({ 
  value, 
  onChange, 
  onSave,
  disabled = false 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const svgRef = useRef<SVGSVGElement>(null);
  const chartRef = useRef<HillChartClass | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const config = {
      target: svgRef.current,
      width: 700,
      height: 270,
      margin: {
        top: 15,
        right: 60,
        bottom: 35,
        left: 60
      },
      initialValue: value,
      editable: isEditing
    };

    chartRef.current = new HillChartClass(config);

    if (isEditing) {
      chartRef.current.onProgressChange((newValue) => {
        setTempValue(newValue);
      });
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      if (svgRef.current) {
        svgRef.current.innerHTML = '';
      }
    };
  }, [isEditing, value]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.setEditable(isEditing);
      chartRef.current.updateProgress(isEditing ? tempValue : value);
    }
  }, [isEditing, value, tempValue]);

  const handleSave = () => {
    onSave(tempValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setTempValue(value);
    setIsEditing(false);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
        {!isEditing ? (
          <Tooltip title="Edit Progress">
            <IconButton 
              onClick={() => setIsEditing(true)}
              disabled={disabled}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Save">
              <IconButton 
                onClick={handleSave}
                color="primary"
                size="small"
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton 
                onClick={handleCancel}
                color="error"
                size="small"
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      <Box
        sx={{
          width: '700px',
          height: '270px',
          margin: '0 auto',
          border: '1px solid #ddd',
          borderRadius: 1,
          '& svg': {
            width: '100%',
            height: '100%',
            cursor: isEditing ? 'pointer' : 'default'
          }
        }}
      >
        <svg ref={svgRef} />
      </Box>
    </Box>
  );
};
