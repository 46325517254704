import React, { useEffect } from "react";
import { AppProvider } from "@toolpad/core";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import type {
  NavigationDividerItem,
  NavigationPageItem,
  NavigationSubheaderItem,
  Router,
  Session,
} from "@toolpad/core";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {
  Home as HomeIcon,
  Description as DescriptionIcon,
  Paid as PaidIcon,
  Group as GroupIcon,
  Person2,
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  Approval as ApprovalIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  AccountBalance as AccountBalanceIcon,
  MonetizationOn as MonetizationOnIcon,
  Build as ToolsIcon,
  AssignmentInd as CSAIcon,
  Timer as ClockifyIcon,
  AssignmentTurnedIn as ProjectAssignmentIcon,
  BorderColor as BorderColorIcon,
} from "@mui/icons-material";
import { useAuth } from "hooks/auth/useAuth";
import {
  Home as FreelancerHome,
  ProjectAssignments,
  Manage,
  SignAgreements,
} from "views/Freelancer";
import { ClientInvoices, StakeholderProjects } from "views/Stakeholder";
import {
  CSAGenerator,
  UserManagement,
  ClientManagement,
  FreelancerManagement,
  SuperAdminHome,
  InvoiceManagement,
  Bank,
  ProjectAssignmentGenerator,
  TimeTracker,
  TimesheetApprovals,
  AdminSignAgreements,
  AdminProjectAssginments,
  ClientOverview,
} from "views/SuperAdmin";
import FreelancerOverview from "views/SuperAdmin/FreelancerManagement/FreelancerOverview/FreelancerOverview";
import CoreLayout from "./CoreLayout";
import TimesheetExplorer from "../../views/SuperAdmin/TimesheetExplorer";
import {
  superuserPages,
  freelancerPages,
  stakeholderPages,
} from "router/pages";

interface CustomNavigationPageItem extends NavigationPageItem {
  children?: CustomNavigation;
  component?: React.ReactNode;
  slugBabyPattern?: string;
  slugBabyComponent?: React.ReactNode;
}

type CustomNavigationItem =
  | CustomNavigationPageItem
  | NavigationSubheaderItem
  | NavigationDividerItem;

export type CustomNavigation = CustomNavigationItem[];

export const DashboardLayoutProvider = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const router = React.useMemo<Router>(
    () => ({
      pathname: location.pathname,
      searchParams: new URLSearchParams(location.search),
      navigate: (path) => navigate(path),
    }),
    [location, navigate]
  );

  const { userInfo, logout, isLoading } = useAuth();

  useEffect(() => {
    if (userInfo) {
      setSession({
        user: {
          id: userInfo?.id,
          name: userInfo?.firstName + " " + userInfo?.lastName,
          email: userInfo?.email,
          image: userInfo?.profilePicture,
        },
      });
    }
  }, [userInfo]);

  const userType = userInfo?.user_type;

  const pages = React.useMemo(() => {
    switch (userType) {
      case "admin":
        return superuserPages;
      case "freelancer":
        return freelancerPages;
      case "stakeholder":
        return stakeholderPages;
      default:
        return [];
    }
  }, [userType]);

  const [session, setSession] = React.useState<Session | null>({
    user: {
      id: userInfo?.id,
      name: userInfo?.firstName + " " + userInfo?.lastName,
      email: userInfo?.email,
      image: userInfo?.profilePicture,
    },
  });

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession({
          user: {
            id: userInfo?.id,
            name: userInfo?.firstName + " " + userInfo?.lastName,
            email: userInfo?.email,
            image: userInfo?.profilePicture,
          },
        });
      },
      signOut: () => {
        setSession(null);
        logout();
        navigate("/login");
      },
    };
  }, [userInfo, logout]);

  return (
    <AppProvider
      authentication={authentication}
      session={session}
      navigation={pages}
      theme={theme}
      router={router}
      defaultSidebarCollapsed={true}
      branding={{
        logo: (
          <Box sx={{ paddingLeft: 2 }}>
            <Box
              component="img"
              src={
                "https://raw.githubusercontent.com/the-freetech-company/freetech-image-content/refs/heads/main/freeteh-cropped-larger.png"
              }
              alt="FreeTech"
              sx={{
                maxWidth: {
                  xs: "200px", // for extra small screens
                  sm: "250px", // for small screens
                  md: "300px", // for medium screens
                  lg: "350px", // for large screens
                  xl: "400px", // for extra large screens
                },
                width: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </Box>
        ),
        title: "",
      }}
    >
      <CoreLayout>
        <Outlet />
      </CoreLayout>
    </AppProvider>
  );
};