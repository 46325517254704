import React, { useState, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  Divider,
  Chip,
  Select,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useFreelancerStatisticsForClient } from "hooks/admin/useFreelancerStatisticsForClient";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { AssignmentCard } from "./components/AssignmentCard";
import ConfirmDialog from "components/ConfirmDialog";
import { useAssociatedEngineer } from "hooks/admin/useAssociatedEngineer";

interface EngineerCardProps {
  clientId: string;
  engineerId: string;
  onRemove: () => void;
}

const EngineerCard: React.FC<EngineerCardProps> = ({
  clientId,
  engineerId,
  onRemove,
}) => {
  const { engineer, assignments, isLoading, error, updateEngineer, removeEngineer } = useAssociatedEngineer(clientId, engineerId);


  

  const [isExpanded, setIsExpanded] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "error" | "success";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState<ProjectAssignment | null>(null);
  const { deleteNestedDocument } = useAdminClient(clientId);
  const theme = useTheme();

  
  const {
    data: freelancerStats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
  } = useFreelancerStatisticsForClient(clientId, engineerId);

  const handleUpdateEngineerStatus = useCallback(async (newStatus: FreelancerStatus) => {
    try {
      await updateEngineer({ status: newStatus });
      setSnackbar({
        open: true,
        message: "Engineer status updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating engineer status:", error);
      setSnackbar({
        open: true,
        message: "Error updating engineer status",
        severity: "error",
      });
    }
  }, [updateEngineer]);

  const handleStatusChange = (event: SelectChangeEvent<FreelancerStatus>) => {
    const newStatus = event.target.value as FreelancerStatus;
    handleUpdateEngineerStatus(newStatus);
  };

  const handleAccordionChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setIsExpanded(isExpanded);
  };

  const handleDeleteClick = (assignment: ProjectAssignment) => {
    setAssignmentToDelete(assignment);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (assignmentToDelete) {
      try {
        await deleteNestedDocument.mutateAsync({
          collectionName: "projectAssignments",
          docId: assignmentToDelete.id,
        });
        setSnackbar({
          open: true,
          message: "Assignment deleted successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error deleting assignment:", error);
        setSnackbar({
          open: true,
          message: "Error deleting assignment",
          severity: "error",
        });
      }
    }
    setDeleteConfirmOpen(false);
    setAssignmentToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setAssignmentToDelete(null);
  };

  const getStatusColor = (status: FreelancerStatus) => {
    switch (status) {
      case "ready":
        return "#3498db"; // Blue
      case "active":
        return "#2ecc71"; // Green
      case "inactive":
        return "#e74c3c"; // Red
      default:
        return "#95a5a6"; // Grey
    }
  };

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;
  if (!engineer) return null;

  return (
    <Grid item xs={isExpanded ? 12 : 6}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 2,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent
          sx={{ flexGrow: 0, backgroundColor: "background.paper", padding: 2 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={engineer.engineer?.profilePicture}
                alt={`${engineer.engineer?.firstName} ${engineer.engineer?.lastName}`}
                sx={{ width: 60, height: 60, marginRight: 2 }}
              />
              <Box>
                <Typography variant="h6">
                  {engineer.engineer?.firstName} {engineer.engineer?.lastName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {engineer.engineer?.email}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Select
                value={engineer.status}
                onChange={handleStatusChange}
                sx={{
                  height: 32,
                  mb: 1,
                  "& .MuiSelect-select": {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                renderValue={(selected) => (
                  <Chip
                    label={selected}
                    size="small"
                    sx={{
                      backgroundColor: getStatusColor(selected),
                      color: "white",
                      fontWeight: "bold",
                      "& .MuiChip-label": { px: 2 },
                    }}
                  />
                )}
              >
                {["ready", "active", "inactive"].map((status) => (
                  <MenuItem key={status} value={status}>
                    <Chip
                      label={status}
                      size="small"
                      sx={{
                        backgroundColor: getStatusColor(
                          status as FreelancerStatus
                        ),
                        color: "white",
                        fontWeight: "bold",
                        "& .MuiChip-label": { px: 2 },
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="textSecondary">
                Associated:{" "}
                {new Date(engineer.associationDate).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>

          {freelancerStats && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, my: 1 }}>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ width: "100%" }}
              >
                Freelancer Statistics
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="caption">
                  Active:{" "}
                  <strong>{freelancerStats.activeAssignments.value}</strong>
                </Typography>
                <Typography variant="caption">
                  Completed:{" "}
                  <strong>{freelancerStats.completedAssignments.value}</strong>
                </Typography>
                <Typography variant="caption">
                  Hours:{" "}
                  <strong>{freelancerStats.totalUtilizedHours.value}</strong>
                </Typography>
                <Typography variant="caption">
                  Avg. Rate:{" "}
                  <strong>
                    ${freelancerStats.averageHourlyRate.value.toFixed(2)}
                  </strong>
                </Typography>
                <Typography variant="caption">
                  Avg. Rating:{" "}
                  <strong>
                    {freelancerStats.averagePerformanceRating.value.toFixed(1)}
                  </strong>
                </Typography>
              </Box>
            </Box>
          )}
        </CardContent>

        <Divider />

        <Accordion
          expanded={isExpanded}
          onChange={handleAccordionChange}
          sx={{
            "&:before": { display: "none" },
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-assignments-content"
            id="panel-assignments-header"
            sx={{
              backgroundColor: "background.paper",
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <Box display="flex" alignItems="center" width="100%">
              <Typography variant="h6" flexGrow={1}>
                Project Assignments
              </Typography>
              <Typography variant="body2" color="text.secondary" mr={1}>
                {assignments ? `${assignments.length} ${assignments.length === 1 ? "Assignment" : "Assignments"}` : "No Assignments"}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2 }}>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Add Assignment
              </Button>
              <TextField
                placeholder="Search assignments"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "200px" }}
              />
            </Box>
            <Grid container spacing={2}>
              {assignments?.map((assignment) => (
                <AssignmentCard
                  key={assignment.id}
                  assignmentId={assignment.id}
                  onDelete={handleDeleteClick}
                  client={{ id: clientId } as Client}
                />
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        open={deleteConfirmOpen}
        title="Confirm Delete"
        content="Are you sure you want to delete this project assignment? This action cannot be undone."
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </Grid>
  );
};

export default React.memo(EngineerCard);
