import { useQuery } from '@tanstack/react-query';
import { collection, getDocs } from 'firebase/firestore';
import { db } from 'config/firebase';
import { fetchProfilePictureUrl } from './fetchProfilePictureUrl';


// firestore rule to only allow read access to certain attributes - email, firstName, lastName, profilePicture

export const useUserList = () => {
  return useQuery({
    queryKey: ['users', 'basic-info'],
    queryFn: async () => {
      const usersRef = collection(db, 'users');
      const snapshot = await getDocs(usersRef);

      const users = await Promise.all(snapshot.docs.map(async doc => {
        const data = doc.data();
        const profilePicture = await fetchProfilePictureUrl(doc.id);
        return {
          id: doc.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          profilePicture: profilePicture
        };
      }));

      return users;
    }
  });
};