import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Box, Chip, Button } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { getClientCompanyName } from 'core/clients';
import { formatDateStringToPretty } from 'core/utils/formatDateStringToPretty';
import NumberIcon from '@mui/icons-material/Numbers';

interface AgreementToSignCardProps {
  assignment: ProjectAssignment;
  onClick: (assignment: ProjectAssignment) => void;
  onCancel?: (assignment: ProjectAssignment) => void;
  isAdminView?: boolean;
}

const ProjAssToSignCard: React.FC<AgreementToSignCardProps> = ({ assignment, onClick, onCancel, isAdminView = false }) => {
  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Not set';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const [clientCompanyName, setClientCompanyName] = React.useState<string>('');

  useEffect(() => {
    const fetchClientCompanyName = async () => {
      const name = await getClientCompanyName(assignment.clientId);
      setClientCompanyName(name);
    };
    fetchClientCompanyName();
  }, [assignment.clientId]);

  const StatusChip = () => {
    if (isAdminView) {
      if (assignment.everSignDocumentStatus === 'awaiting_admin_signature') {
        return (
          <Chip
            icon={<CheckCircleIcon />}
            label="Ready to Sign"
            color="success"
            size="small"
          />
        );
      } else if (assignment.everSignDocumentStatus === 'awaiting_engineer_signature') {
        return (
          <Chip
            icon={<WarningIcon />}
            label="Awaiting Engineer Signature"
            color="warning"
            size="small"
          />
        );
      }
    } else {
      if (assignment.everSignDocumentStatus === 'awaiting_admin_signature') {
        return (
          <Chip
            icon={<WarningIcon />}
            label="Awaiting Admin Signature"
            color="warning"
            size="small"
          />
        );
      } else if (assignment.everSignDocumentStatus === 'awaiting_engineer_signature') {
        return (
          <Chip
            icon={<CheckCircleIcon />}
            label="Ready to Sign"
            color="success"
            size="small"
          />
        );
      }
    }
    return null;
  };

  return (
    <Card 
      sx={{ 
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: 6
        },
        position: 'relative',
      }} 
      onClick={() => onClick(assignment)}
    >
      {isAdminView && onCancel && (
        <Button
          color="error"
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
            minWidth: 'auto',
            p: '4px 8px',
            backgroundColor: 'background.paper',
            '&:hover': {
              backgroundColor: 'error.light',
              color: 'white',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            onCancel(assignment);
          }}
        >
          Cancel
        </Button>
      )}
      <CardContent sx={{ flexGrow: 1, pb: 1 }}>
        <Typography variant="h6" gutterBottom color="primary">{assignment.projectName || 'Unknown Project'}</Typography>
        <Box display="flex" alignItems="center" mb={1}>
          <NumberIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2">{assignment.id}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <BusinessIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2">{clientCompanyName}</Typography>
        </Box>
        {isAdminView && (
          <Box display="flex" alignItems="center" mb={1}>
            <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">{assignment.freelancerName}</Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" mb={1}>
          <DescriptionIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2">CSA: {assignment.csaName || 'N/A'}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <AttachMoneyIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2">Rate: ${assignment.billableRate}/hr</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box display="flex" alignItems="center">
            <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">Max Hours: {assignment.budgetedHours || 'N/A'}</Typography>
          </Box>
          <StatusChip />
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <AccountBalanceIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2">
            Max Budget: ${assignment.budgetedHours && assignment.billableRate 
              ? (assignment.budgetedHours * assignment.billableRate).toFixed(2) 
              : 'N/A'}
          </Typography>
        </Box>
      </CardContent>
      <Box sx={{ 
        p: 2, 
        backgroundColor: 'background.default', 
        mt: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CalendarTodayIcon sx={{ fontSize: '1.2rem', mr: 1, color: 'text.secondary' }} />
          <Box>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', lineHeight: 1 }}>
              Start
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ fontWeight: 'medium' }}>
              {formatDateStringToPretty(assignment.startDate)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CalendarTodayIcon sx={{ fontSize: '1.2rem', mr: 1, color: 'text.secondary' }} />
          <Box>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', lineHeight: 1 }}>
              End
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ fontWeight: 'medium' }}>
              {formatDateStringToPretty(assignment.endDate)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ProjAssToSignCard;
