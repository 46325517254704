import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Paper,
  useTheme,
  alpha,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatter,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SendIcon from "@mui/icons-material/Send";

interface ProjectTotal {
  projectName: string;
  totalHours: number;
}

interface TimesheetApprovalsGridProps {
  userEntries: Timesheet[];
  userName: string;
  handleApproveTimesheet?: (id: string) => Promise<void>;
  handleRejectTimesheet?: (id: string, reason?: string) => Promise<void>;
  handleSubmitForUser?: (id: string) => Promise<void>;
  showActions: boolean;
  isUnsubmittedTab: boolean;
  isArchiveTab: boolean;
}

const TimesheetApprovalsGrid: React.FC<TimesheetApprovalsGridProps> = ({
  userEntries,
  userName,
  handleApproveTimesheet,
  handleRejectTimesheet,
  handleSubmitForUser,
  showActions,
  isUnsubmittedTab,
  isArchiveTab,
}) => {
  const theme = useTheme();

  const projectTotals = useMemo(() => {
    return userEntries.reduce(
      (acc, entry) => {
        const projectKey = entry.projectId;
        if (!acc[projectKey]) {
          acc[projectKey] = {
            projectName: entry.projectName || "Unknown Project",
            totalHours: 0,
          };
        }
        acc[projectKey].totalHours += (entry.duration || 0) / 3600; // Convert to hours
        return acc;
      },
      {} as Record<string, ProjectTotal>
    );
  }, [userEntries]);

  const dateRange = useMemo(() => {
    if (userEntries.length === 0) return "";
    const dates = userEntries.map((entry) => DateTime.fromISO(entry.date));
    const minDate = DateTime.min(...dates);
    const maxDate = DateTime.max(...dates);
    return `(${minDate.toFormat("MMM d")} - ${maxDate.toFormat("MMM d, yyyy")})`;
  }, [userEntries]);

  const columns: GridColDef[] = [
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 120,
      //   valueFormatter: (params: GridValueFormatter) => DateTime.fromISO(params.value).toFormat("yyyy-MM-dd"),
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      minWidth: 120,
      valueGetter: (value, row, column, apiRef) => {
        const startTime = DateTime.fromISO(row.startTime).toFormat("HH:mm");
        const endTime = DateTime.fromISO(row.endTime).toFormat("HH:mm");
        return `${startTime} - ${endTime}`;
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value: number) => {
        const totalSeconds = value; // Convert ms to seconds
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },
  ];

  if (isArchiveTab) {
    columns.push(
      {
        field: "approvedAt",
        headerName: "Approval Date",
        flex: 1,
        minWidth: 150,
        type: "date",
        valueGetter: (params: any) => DateTime.fromISO(params.value).toJSDate(),
        // valueFormatter: (params) =>
        //   DateTime.fromJSDate(params.value as Date).toFormat("yyyy-MM-dd HH:mm"),
        // valueSetter: (params) => {
        //   const newDate = DateTime.fromJSDate(params.value as Date);
        //   return { ...params.row, date: newDate.toISODate() };
        // },
      },
      {
        field: "approvedByEmail",
        headerName: "Approved By",
        flex: 1,
        minWidth: 150,
      }
    );
  }

  if (showActions) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {isUnsubmittedTab ? (
            <Tooltip title="Submit for User">
              <IconButton
                onClick={() =>
                  handleSubmitForUser && handleSubmitForUser(params.row.id)
                }
                color="primary"
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Approve">
                <IconButton
                  onClick={() =>
                    handleApproveTimesheet &&
                    handleApproveTimesheet(params.row.id)
                  }
                  color="primary"
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton
                  onClick={() =>
                    handleRejectTimesheet &&
                    handleRejectTimesheet(params.row.id)
                  }
                  color="error"
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    });
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Paper
        elevation={3}
        sx={{ p: 2, borderRadius: theme.shape.borderRadius }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">
            {userName} {dateRange}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            {(Object.entries(projectTotals) as [string, ProjectTotal][]).map(
              ([projectId, project]) => (
                <Typography key={projectId} variant="subtitle2" color="primary">
                  {project.projectName}: {project.totalHours.toFixed(2)}h
                </Typography>
              )
            )}
          </Box>
        </Box>
        <DataGridPro
          rows={userEntries}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          hideFooter
          sx={{
            border: "none",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default TimesheetApprovalsGrid;
