import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getStorage, ref, listAll } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button } from '@mui/material';
import { downloadCSA } from 'core/csa/legacy/downloadCSA';

const storage = getStorage();
const functions = getFunctions();

const ExistingCSADataGrid = (props: any) => {

    const { formSubmitted } = props;
    const [rows, setRows] = useState([]);

    const fetchDocuments = async () => {
        const listRef = ref(storage, 'generatedDocs');
        let items = [];

        const res = await listAll(listRef);
        for (const itemRef of res.items) {

            

            items.push({
                id: items.length + 1,
                fullPath: itemRef.fullPath,
                fileName: itemRef.name,
            });
        }
        // @ts-ignore
        setRows(items);
    };

    useEffect(() => {
        if (formSubmitted) {
            
            fetchDocuments();
        }
    }, [formSubmitted]);

    useEffect(() => {

        fetchDocuments();
    }, []);

    const downloadFileOnClick = async (fullPath: string) => {

        // const generateSignedUrl = httpsCallable(functions, 'downloadCSA');
        // const res = await fetch('https://us-central1-freetech-production.cloudfunctions.net/downloadCSA',
        //     {
        //         method: 'POST',
        //         body: JSON.stringify({ gcsUri: fullPath })
        //     }
        // );

        const data = await downloadCSA(fullPath)

        

        // if (!data) {
        //     console.error('No data found');
        //     return;
        // }

        // @ts-ignore
        const url = data.fileUrl;

        

        if (!url) {
            console.error('No download URL found');
            return;
        }
        window.open(url, '_blank');
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'fileName', headerName: 'File Name', width: 200 },
        { field: 'createdBy', headerName: 'Created By', width: 200 },
        {
            field: 'fullPath', headerName: 'Download', width: 400,

            // @ts-ignore        
            renderCell: (params) =>
                <Button
                    onClick={() => {
                        // window.open(params.value, '_blank');
                         downloadFileOnClick(params.value);
                    }}
                >
                    Download</Button>
        },
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                checkboxSelection
            />
        </div>
    );
};

export default ExistingCSADataGrid;
