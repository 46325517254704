import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  DocumentData,
  deleteDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { useAuth } from "hooks/auth/useAuth";

export const useKnowledgeBase = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();

  // Fetch entire knowledge base structure
  const getKnowledgeBaseStructure =
    async (): Promise<KnowledgeBaseStructure> => {
      // Get all folders
      const foldersRef = collection(db, "knowledgeBaseFolders");
      const filesRef = collection(db, "knowledgeBaseFiles");

      const [folderDocs, fileDocs] = await Promise.all([
        getDocs(foldersRef),
        getDocs(filesRef),
      ]);

      // Create a map of folders by their path
      const folderMap = new Map<string, KnowledgeBaseFolder>();

      // Initialize root folder
      const rootFolder: KnowledgeBaseFolder = {
        id: "root",
        name: "Knowledge Base",
        path: "root",
        folders: [],
        files: [],
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
        createdBy: "system",
      };
      folderMap.set("root", rootFolder);

      // Process all folders
      folderDocs.docs.forEach((doc) => {
        const folderData = doc.data();
        const folder: KnowledgeBaseFolder = {
          id: doc.id,
          name: folderData.name,
          path: folderData.path,
          folders: [],
          files: [],
          createdAt: folderData.createdAt,
          updatedAt: folderData.updatedAt,
          createdBy: folderData.createdBy,
        };
        folderMap.set(folder.path, folder);
      });

      // Build folder hierarchy
      folderMap.forEach((folder) => {
        if (folder.path !== "root") {
          const parentPath =
            folder.path.split("/").slice(0, -1).join("/") || "root";
          const parentFolder = folderMap.get(parentPath);
          if (parentFolder) {
            parentFolder.folders.push(folder);
          }
        }
      });

      // Process all files
      fileDocs.docs.forEach((doc) => {
        const fileData = doc.data();
        const file: KnowledgeBaseFile = {
          id: doc.id,
          name: fileData.name,
          content: fileData.content,
          path: fileData.path,
          storageUrl: fileData.storageUrl,
          createdAt: fileData.createdAt,
          updatedAt: fileData.updatedAt,
          createdBy: fileData.createdBy,
          lastModifiedBy: fileData.lastModifiedBy,
          metadata: fileData.metadata,
        };

        const parentPath =
          file.path.split("/").slice(0, -1).join("/") || "root";
        const parentFolder = folderMap.get(parentPath);
        if (parentFolder) {
          parentFolder.files.push(file);
        }
      });

      return { rootFolder: folderMap.get("root")! };
    };

  // Query hook for fetching structure
  const useKnowledgeBaseStructure = () => {
    return useQuery<KnowledgeBaseStructure, Error>({
      queryKey: ["knowledgeBase"],
      queryFn: getKnowledgeBaseStructure,
    });
  };

  // Create new folder
  const createFolder = useMutation({
    mutationFn: async ({
      parentPath,
      name,
    }: {
      parentPath: string;
      name: string;
    }) => {
      const folderData: Omit<KnowledgeBaseFolder, "id" | "folders" | "files"> =
        {
          name,
          path: parentPath === "root" ? name : `${parentPath}/${name}`,
          createdAt: DateTime.now().toISO(),
          updatedAt: DateTime.now().toISO(),
          createdBy: userInfo?.id || "system",
        };

      const folderRef = collection(db, "knowledgeBaseFolders");
      const newFolderDoc = await addDoc(folderRef, folderData);
      return newFolderDoc.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });

  // Create new file
  const createFile = useMutation({
    mutationFn: async (data: {
      folderPath: string;
      name: string;
      content: string;
    }) => {
      const fileData: Omit<KnowledgeBaseFile, "id"> = {
        name: data.name,
        content: data.content,
        path: `${data.folderPath}/${data.name}`,
        storageUrl: "",
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
        createdBy: userInfo?.id || "system",
        lastModifiedBy: userInfo?.id || "system",
        metadata: {
          description: "",
          tags: [],
        },
      };

      const fileRef = collection(db, "knowledgeBaseFiles");
      const newFileDoc = await addDoc(fileRef, fileData);
      return newFileDoc.id;
    },
    onMutate: async (newFile) => {
      await queryClient.cancelQueries({ queryKey: ["knowledgeBase"] });

      const previousData = queryClient.getQueryData<KnowledgeBaseStructure>([
        "knowledgeBase",
      ]);

      if (previousData) {
        const updatedData = { ...previousData };
        const newFileData: KnowledgeBaseFile = {
          id: `temp-${Date.now()}`,
          name: newFile.name,
          content: newFile.content,
          path: `${newFile.folderPath}/${newFile.name}`,
          createdAt: DateTime.now().toISO(),
          updatedAt: DateTime.now().toISO(),
          createdBy: userInfo?.id || "system",
          lastModifiedBy: userInfo?.id || "system",
          metadata: { tags: [], description: "" },
          storageUrl: "",
        };

        const addFileToFolder = (folder: KnowledgeBaseFolder) => {
          if (folder.path === newFile.folderPath) {
            folder.files = [...folder.files, newFileData];
            return true;
          }
          for (const subFolder of folder.folders) {
            if (addFileToFolder(subFolder)) return true;
          }
          return false;
        };

        addFileToFolder(updatedData.rootFolder);
        queryClient.setQueryData(["knowledgeBase"], updatedData);
      }

      return { previousData };
    },
    onError: (_err, _newFile, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(["knowledgeBase"], context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });

  // Update existing file
  const updateFile = useMutation({
    mutationFn: async ({
      fileId,
      name,
      content,
    }: {
      fileId: string;
      name: string;
      content: string;
    }) => {
      const fileRef = doc(db, "knowledgeBaseFiles", fileId);
      const fileData = {
        name,
        content,
        updatedAt: DateTime.now().toISO(),
        lastModifiedBy: userInfo?.id || "system",
      };

      await updateDoc(fileRef, fileData);
      return fileId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });
  const moveToTrash = useMutation({
    mutationFn: async ({
      knowledgeBaseDocumentId,
    }: {
      knowledgeBaseDocumentId: string;
    }) => {
      // Get the file data first
      const fileRef = doc(db, "knowledgeBaseFiles", knowledgeBaseDocumentId);
      const fileSnap = await getDoc(fileRef);
      const fileData = fileSnap.data();

      // Create new doc in trash collection
      const trashRef = doc(
        db,
        "knowledgeBaseFilesTrash",
        knowledgeBaseDocumentId
      );
      await setDoc(trashRef, {
        ...fileData,
        deletedAt: DateTime.now().toISO(),
        deletedBy: userInfo?.id || "system",
      });

      // Delete original file
      await deleteDoc(fileRef);

      return knowledgeBaseDocumentId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });

  return {
    useKnowledgeBaseStructure,
    createFolder,
    createFile,
    updateFile,
    moveToTrash,
  };
};
