import React, { useEffect, useState, Suspense } from "react";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useAuth } from "hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import EarningsChart from "./components/EarningsChart";
import { useFreelancerHomeStatistics } from "hooks/freelancer/useFreelancerHomeStatistics";

const FreelancerHome: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const { stats, assignments, isLoading, error } = useFreelancerHomeStatistics(selectedYear);
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  useEffect(() => {
    if (userInfo?.user_type === "admin") {
      navigate("/admin");
    }
  }, [userInfo, navigate]);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Welcome, {userInfo?.firstName + " " + userInfo?.lastName || userInfo?.email || "Freelancer"}!
          </Typography>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              label="Year"
              onChange={handleYearChange}
            >
              {[...Array(5)].map((_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Typography variant="body1" sx={{ marginBottom: 4, color: "#555" }}>
          Please bear with us as we build out the FreeTech Portal! Reach out
          to admin@freetech.co with any questions or if you would like to contribute to the project.
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 4 }}>
          <Grid item xs={3}>
            <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
              <Typography variant="h6">
                ${stats.amountPaid.toFixed(2)}
              </Typography>
              <Typography variant="body2">Amount Paid</Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
              <Typography variant="h6">
                {stats.totalHours.toFixed(2)}
              </Typography>
              <Typography variant="body2">Total Hours Worked</Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
              <Typography variant="h6">
                ${stats.averageBillRate.toFixed(2)}/hr
              </Typography>
              <Typography variant="body2">Average Bill Rate</Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
              <Typography variant="h6">{stats.uniqueClients}</Typography>
              <Typography variant="body2">Total Unique Clients</Typography>
            </Paper>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
          Your Earnings:
        </Typography>
        <Suspense fallback={<CircularProgress />}>
          <EarningsChart assignments={assignments} selectedYear={selectedYear} onYearChange={handleYearChange} />
        </Suspense>
      </Paper>
    </>
  );
};

export default FreelancerHome;
