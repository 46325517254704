import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { collection, getDocs, doc, updateDoc, deleteDoc, getDoc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, deleteUser as deleteAuthUser } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from "config/firebase";


export const useManageUsers = () => {
  const queryClient = useQueryClient();
  const auth = getAuth();
  const storage = getStorage();

  // Fetch all users
  const { data: users, isLoading, isError } = useQuery<UserInfoType[], Error>({
    queryKey: ["users"],
    queryFn: async () => {
      const usersRef = collection(db, "users");
      const snapshot = await getDocs(usersRef);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as UserInfoType));
    },
    staleTime: 5 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // Create user
  const createUserMutation = useMutation({
    mutationFn: async (userData: Omit<UserInfoType, 'id'>) => {
      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, 'temporaryPassword');
      const user = userCredential.user;
      await setDoc(doc(db, `users/${user.uid}`), userData);
      await sendEmailVerification(user);
      return user.uid;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  // Update user
  const updateUserMutation = useMutation({
    mutationFn: async (userData: UserInfoType) => {
      const userRef = doc(db, 'users', userData.id);
      
      // Remove any undefined or null values
      const cleanedData = Object.entries(userData).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

      // Convert string boolean values to actual booleans
      if (typeof cleanedData.is_active === 'string') {
        cleanedData.is_active = cleanedData.is_active === 'true';
      }
      if (typeof cleanedData.emailVerified === 'string') {
        cleanedData.emailVerified = cleanedData.emailVerified === 'true';
      }

      await updateDoc(userRef, cleanedData);
      return userData;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  // Delete user
  const deleteUserMutation = useMutation({
    mutationFn: async (userId: string) => {
      const user = auth.currentUser;
      if (user) {
        await deleteAuthUser(user);
      }
      await deleteDoc(doc(db, `users/${userId}`));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  // Upload profile picture
  const uploadProfilePictureMutation = useMutation({
    mutationFn: async ({ userId, file }: { userId: string; file: File }) => {
      const storageRef = ref(storage, `users/${userId}/profilePicture`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      await updateDoc(doc(db, `users/${userId}`), { profilePicture: downloadURL });
      return downloadURL;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  // Get verified status
  const getVerifiedStatus = async (userId: string): Promise<boolean> => {
    const functions = getFunctions();
    const getVerifiedStatus = httpsCallable(functions, 'getVerifiedStatus');
    const result = await getVerifiedStatus({ userId });
    return (result.data as { isVerified: boolean }).isVerified;
  };

  // Resend verification email
  const resendVerificationEmailMutation = useMutation({
    mutationFn: async (email: string) => {
      const functions = getFunctions();
      const resendVerificationEmail = httpsCallable(functions, 'resendVerificationEmail');
      await resendVerificationEmail({ email });
    },
  });

  return {
    users,
    isLoading,
    isError,
    createUser: createUserMutation.mutate,
    updateUser: updateUserMutation.mutate,
    deleteUser: deleteUserMutation.mutate,
    uploadProfilePicture: uploadProfilePictureMutation.mutate,
    getVerifiedStatus,
    resendVerificationEmail: resendVerificationEmailMutation.mutate,
  };
};
