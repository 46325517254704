import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { timesheetConverter } from "./timesheetConverter";
import { getClientCompanyName } from "../../../core/clients";
import { getProjectName } from "../../../core/projects";
import { getUserProfile } from "../../../core/users";
import { useState, useCallback } from "react";
import { getUserEmailById } from "../../../core/users";

// Add this type definition
type MutationContext = {
  previousTimesheets?: Timesheet[];
};

export const useAdminTimesheet = () => {
  const queryClient = useQueryClient();
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);

  const fetchTimesheets = useCallback(async (filters: Partial<Timesheet> = {}) => {
    const timesheetsRef = collection(db, "timesheets").withConverter(timesheetConverter);
    let q = query(timesheetsRef);

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        q = query(q, where(key, "==", value));
      }
    });

    const querySnapshot = await getDocs(q);

    const fetchedTimesheets: Timesheet[] = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const timesheet = doc.data() as Timesheet;
        let clientName: string | undefined;
        let projectName: string | undefined;

        // Only fetch client and project names if we have the IDs
        if (timesheet.clientId) {
          clientName = await getClientCompanyName(timesheet.clientId);
        }
        if (timesheet.clientId && timesheet.projectId) {
          projectName = await getProjectName(timesheet.clientId, timesheet.projectId);
        }

        // Get user profile
        const userProfile = await getUserProfile(timesheet.userId);

        // Get approver/rejector emails if present
        let approvedByEmail;
        let rejectedByEmail;
        if (timesheet.approvedBy) {
          approvedByEmail = await getUserEmailById(timesheet.approvedBy);
        }
        if (timesheet.rejectedBy) {
          rejectedByEmail = await getUserEmailById(timesheet.rejectedBy);
        }

        return {
          ...timesheet,
          clientName,
          projectName,
          userEmail: userProfile?.email,
          userName: `${userProfile?.firstName} ${userProfile?.lastName}`,
          approvedByEmail,
          rejectedByEmail,
        };
      })
    );

    const sortedTimesheets = fetchedTimesheets.sort((a, b) => {
      const dateA = DateTime.fromISO(a.date);
      const dateB = DateTime.fromISO(b.date);
      if (dateA.equals(dateB)) {
        const startTimeA = DateTime.fromISO(a.startTime);
        const startTimeB = DateTime.fromISO(b.startTime);
        return startTimeB.toMillis() - startTimeA.toMillis();
      }
      return dateB.toMillis() - dateA.toMillis();
    });

    return sortedTimesheets;
  }, []);

  const getAllTimesheets = () => {
    return useQuery<Timesheet[], Error>({
      queryKey: ["allTimesheets"],
      queryFn: () => fetchTimesheets(),
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    });
  };

  const getTimesheet = async (id: string): Promise<Timesheet | null> => {
    const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
    const docSnap = await getDoc(timesheetRef);
    if (docSnap.exists()) {
      const timesheet = docSnap.data() as Timesheet;
      const [clientName, projectName] = await Promise.all([
        getClientCompanyName(timesheet.clientId),
        getProjectName(timesheet.clientId, timesheet.projectId),
      ]);
      return {
        ...timesheet,
        clientName,
        projectName,
      };
    }
    return null;
  };

  const createTimesheet = useMutation<string, Error, Omit<Timesheet, "id">>({
    mutationFn: async (newTimesheet) => {
      const timesheetsRef = collection(db, "timesheets").withConverter(
        timesheetConverter
      );
      const docRef = await addDoc(timesheetsRef, newTimesheet);
      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const updateTimesheet = useMutation<void, Error, Partial<Timesheet>, MutationContext>({
    mutationFn: async ({ id, ...updateData }) => {
      if (!id) throw new Error("Timesheet ID is required for update");
      const timesheetRef = doc(db, "timesheets", id).withConverter(
        timesheetConverter
      );
      await updateDoc(timesheetRef, updateData);
    },
    onMutate: async (updatedTimesheet) => {
      await queryClient.cancelQueries({ queryKey: ["allTimesheets"] });
      const previousTimesheets = queryClient.getQueryData<Timesheet[]>(["allTimesheets"]);

      queryClient.setQueryData<Timesheet[]>(["allTimesheets"], (old) =>
        old?.map((timesheet) =>
          timesheet.id === updatedTimesheet.id ? { ...timesheet, ...updatedTimesheet } : timesheet
        )
      );

      return { previousTimesheets };
    },
    onError: (err, newTimesheet, context) => {
      if (context?.previousTimesheets) {
        queryClient.setQueryData(["allTimesheets"], context.previousTimesheets);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const deleteTimesheet = useMutation<void, Error, string>({
    mutationFn: async (id) => {
      await deleteDoc(doc(db, "timesheets", id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const approveTimesheet = useMutation<void, Error, { id: string; approverId: string }>({
    mutationFn: async ({ id, approverId }) => {
      const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
      await updateDoc(timesheetRef, { 
        approved: true, 
        approvedAt: DateTime.now().toISO(),
        approvedBy: approverId
      });
    },
    onMutate: ({ id, approverId }) => {
      const updatedTimesheets = timesheets.map(timesheet =>
        timesheet.id === id
          ? {
              ...timesheet,
              approved: true,
              approvedAt: DateTime.now().toISO(),
              approvedBy: approverId,
            }
          : timesheet
      );
      setTimesheets(updatedTimesheets);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const rejectTimesheet = useMutation<void, Error, { id: string; rejectorId: string; reason?: string }>({
    mutationFn: async ({ id, rejectorId, reason }) => {
      const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
      await updateDoc(timesheetRef, { 
        submitted: false, 
        rejected: true,
        rejectedAt: DateTime.now().toISO(),
        rejectedBy: rejectorId,
        rejectionReason: reason || null
      });
    },
    onMutate: ({ id, rejectorId, reason }) => {
      const updatedTimesheets = timesheets.map(timesheet =>
        timesheet.id === id
          ? {
              ...timesheet,
              submitted: false,
              rejected: true,
              rejectedAt: DateTime.now().toISO(),
              rejectedBy: rejectorId,
              rejectionReason: reason || null,
            }
          : timesheet
      );
      setTimesheets(updatedTimesheets);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const getTimesheetsAwaitingApproval = () => {
    return useQuery<Timesheet[], Error>({
      queryKey: ["timesheetsAwaitingApproval"],
      queryFn: async () => {
        const timesheetsRef = collection(db, "timesheets").withConverter(timesheetConverter);
        const q = query(timesheetsRef, where("submitted", "==", true), where("approved", "==", false));
        const querySnapshot = await getDocs(q);
        return processTimesheets(querySnapshot);
      },
      staleTime: 5 * 60 * 1000,
    });
  };

  const getUnsubmittedTimesheets = () => {
    return useQuery<Timesheet[], Error>({
      queryKey: ["unsubmittedTimesheets"],
      queryFn: async () => {
        const timesheetsRef = collection(db, "timesheets").withConverter(timesheetConverter);
        const q = query(timesheetsRef, where("submitted", "==", false), where("approved", "==", false));
        const querySnapshot = await getDocs(q);
        return processTimesheets(querySnapshot);
      },
      staleTime: 5 * 60 * 1000,
    });
  };

  const getApprovedTimesheets = () => {
    return useQuery<Timesheet[], Error>({
      queryKey: ["approvedTimesheets"],
      queryFn: async () => {
        const timesheetsRef = collection(db, "timesheets").withConverter(timesheetConverter);
        const q = query(timesheetsRef, where("submitted", "==", true), where("approved", "==", true));
        const querySnapshot = await getDocs(q);
        return processTimesheets(querySnapshot);
      },
      staleTime: 5 * 60 * 1000,
    });
  };

  const processTimesheets = async (querySnapshot: any) => {
    const timesheets = await Promise.all(
      querySnapshot.docs.map(async (doc: any) => {
        const timesheet = doc.data() as Timesheet;
        const [clientName, projectName] = await Promise.all([
          getClientCompanyName(timesheet.clientId),
          getProjectName(timesheet.clientId, timesheet.projectId),
        ]);
        return {
          ...timesheet,
          clientName,
          projectName,
        };
      })
    );

    return timesheets.sort((a, b) => {
      const dateA = DateTime.fromISO(a.date);
      const dateB = DateTime.fromISO(b.date);
      if (dateA.equals(dateB)) {
        const startTimeA = DateTime.fromISO(a.startTime);
        const startTimeB = DateTime.fromISO(b.startTime);
        return startTimeB.toMillis() - startTimeA.toMillis();
      }
      return dateB.toMillis() - dateA.toMillis();
    });
  };

  return {
    timesheets,
    setTimesheets,
    getAllTimesheets,
    getTimesheet,
    createTimesheet,
    updateTimesheet,
    deleteTimesheet,
    approveTimesheet,
    rejectTimesheet,
    getTimesheetsAwaitingApproval,
    getUnsubmittedTimesheets,
    getApprovedTimesheets,
  };
};
