import { useQuery } from '@tanstack/react-query';
import { fetchStakeholderProjects } from 'core/stakeholders';
import { useAuth } from "hooks/auth/useAuth";

export const useStakeholderProjects = () => {
  const { userInfo } = useAuth();

  return useQuery<Project[], Error>({
    queryKey: ['stakeholderProjects', userInfo?.id],
    queryFn: async () => {
      if (!userInfo?.id) {
        throw new Error('User information not available');
      }
      const projects = await fetchStakeholderProjects(userInfo.id);
      
      return projects;
    },
    enabled: !!userInfo?.id,
  });
};
