import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AccountDeactivated, NotFound } from "components";
import { AuthRoute, SuperUserRoute, FreelancerRoute } from "./routes";
import { Login, ForgotPassword, ResetPassword } from "views/Auth";
import {
  stakeholderPages,
  superuserPages,
  freelancerPages,
} from "router/pages";
import StakeholderRoute from "./routes/StakeholderRoute";
import { mapToolpadToReactRouter } from "router/layouts/mapToolpadToReactRouter";
import { AuthLayout } from "./layouts";
import { DashboardLayoutProvider } from "./layouts/DashboardLayoutProvider";
import { useAuth } from "hooks/auth/useAuth";
import type { CustomNavigation } from "./types/customNavigation";
import StandardErrorBoundary from "views/Shared/ErrorBoundaries/StandardErrorBoundary";

export const AppRoutes = () => {
  const { userInfo } = useAuth();

  const AuthRoutes = () => {
    return (
      <>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />

        <Route
          path="/reset-password"
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        />
      </>
    );
  };

  const RedirectRoutes = () => {
    return (
      <>
        {/* Not Found - other pages redirect here */}
        <Route path="/not-found" element={<NotFound />} />

        {/* Redirect logic to /login or /user_type occurs in AuthLayout */}
        <Route path="/" />

        {/* Redirect * to NotFound to hide SuperUser Routes  */}
        <Route path="*" element={<Navigate to="/not-found" replace />} />

        <Route path="/account-deactivated" element={<AccountDeactivated />} />
      </>
    );
  };

  const FreelancerRoutes = () => {
    return <>{mapToolpadToReactRouter(freelancerPages as CustomNavigation, FreelancerRoute)}</>;
  };

  const SuperUserRoutes = () => {
    return <>{mapToolpadToReactRouter(superuserPages as CustomNavigation, SuperUserRoute)}</>;
  };

  const StakeholderRoutes = () => {
    return <>{mapToolpadToReactRouter(stakeholderPages as CustomNavigation, StakeholderRoute)}</>;
  };

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          {AuthRoutes()}
          {RedirectRoutes()}
        </Route>

        <Route element={<DashboardLayoutProvider />}>
          <Route
            element={
              <StandardErrorBoundary>
                <Outlet />
              </StandardErrorBoundary>
            }
          >
            {FreelancerRoutes()}
            {SuperUserRoutes()}
            {StakeholderRoutes()}
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};