import { useQuery } from '@tanstack/react-query';
import { getClientProjects } from '../../core/projects';
import { loadAllClients } from '../../core/clients';

export const useProjects = () => {
  return useQuery({
    queryKey: ['all-projects'],
    queryFn: async () => {
      const clients = await loadAllClients();
      const projectPromises = clients.map(client => getClientProjects(client.id));
      const projectsByClient = await Promise.all(projectPromises);
      return projectsByClient.flat();
    }
  });
}; 