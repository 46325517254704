import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  alpha,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Divider,
  Alert,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridValueFormatter,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DateTime, DateTimeOptions } from "luxon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { calculateDuration } from "hooks/shared/timesheet/utils";
import {
  formatDurationForEdit,
  parseDuration,
  adjustEndTime,
} from "hooks/shared/timesheet/utils";
import { calculateTotalTimeForWeek } from "hooks/shared/timesheet/utils";
import {
  AccessTime,
  CheckCircle,
  HourglassBottom,
  HourglassTop,
  Lock,
  Pending,
  PendingActions,
  Publish,
  Replay,
  EditNote,
} from "@mui/icons-material";
import { keyframes } from "@mui/system";
import MarkdownEditor from "components/MarkdownEditor/MarkdownEditor";
import { useAuth } from "hooks/auth/useAuth";

interface TimeTrackerGridProps {
  weekEntries: Timesheet[];
  weekKey: string;
  paginatedEntries: Timesheet[];
  handleProcessRowUpdate: (
    newRow: Timesheet,
    oldRow: Timesheet
  ) => Promise<Timesheet>;
  activeProjectAssignments: ProjectAssignment[];
  handleDeleteEntry: (id: string) => void;
}

interface LongDescriptionDialogProps {
  open: boolean;
  onClose: () => void;
  timesheet: Timesheet;
  onSave: (newLongDescription: string) => Promise<void>;
}

const TimeTrackerGrid: React.FC<TimeTrackerGridProps> = ({
  weekEntries,
  weekKey,
  paginatedEntries,
  handleProcessRowUpdate,
  activeProjectAssignments,
  handleDeleteEntry,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRowId, setSelectedRowId] = React.useState<string | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [longDescriptionDialogOpen, setLongDescriptionDialogOpen] =
    useState(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState<Timesheet | null>(
    null
  );
  const { userInfo } = useAuth();
  const isAdmin = userInfo?.user_type === "admin";

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const handleDeleteClick = () => {
    if (selectedRowId) {
      handleDeleteEntry(selectedRowId);
    }
    handleMenuClose();
  };

  const handleSubmitForApproval = async (row: Timesheet) => {
    handleMenuClose();
    const newRow = { ...row, submitted: true };
    await handleProcessRowUpdate(newRow, row);
  };

  const handleWithdrawForApproval = async (row: Timesheet) => {
    handleMenuClose();
    const newRow = { ...row, submitted: false };
    await handleProcessRowUpdate(newRow, row);
  };

  const handleSubmitWeekForApproval = async (weekEntries: Timesheet[]) => {
    for (const entry of weekEntries) {
      if (!entry.submitted && !entry.approved) {
        await handleSubmitForApproval(entry);
      }
    }
  };

  const handleWithdrawWeekForApproval = async (weekEntries: Timesheet[]) => {
    for (const entry of weekEntries) {
      if (entry.submitted && !entry.approved) {
        await handleWithdrawForApproval(entry);
      }
    }
  };

  const handleLongDescriptionUpdate = async (newLongDescription: string) => {
    if (selectedTimesheet) {
      try {
        const updatedRow = {
          ...selectedTimesheet,
          longDescription: newLongDescription,
        };

        await handleProcessRowUpdate(updatedRow, selectedTimesheet);

        setLongDescriptionDialogOpen(false);
        setSelectedTimesheet(null);
      } catch (error) {
        console.error("Failed to update long description:", error);
      }
    }
  };

  // Add this animation keyframe
  const hourglassAnimation = keyframes`
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(180deg); }
    50% { transform: rotate(180deg); }
    75% { transform: rotate(360deg); }
  `;

  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 150,
      editable: true,
      sortable: false,
      renderCell: (params) =>
        params.row.description || (
          <span style={{ fontStyle: "italic", fontWeight: 300 }}>
            Enter description..
          </span>
        ),
    },
    {
      field: "clientId",
      headerName: "Client",
      flex: 1,
      minWidth: 120,
      editable: false,
      sortable: false,
      type: "singleSelect",
      // valueOptions: Array.from(
      //   new Set(
      //     activeProjectAssignments.map((assignment) => assignment.clientName)
      //   )
      // ).map((clientName) => {
      //   const assignment = activeProjectAssignments.find(
      //     (a) => a.clientName === clientName
      //   );
      //   return {
      //     value: assignment?.clientId || '',
      //     label: clientName,
      //   };
      // }),
      // renderCell: (params) => {
      //   return isSmallScreen ? null : <>{params.row.clientName}</>;
      // },
    },
    {
      field: "projectId",
      headerName: "Project",
      flex: 1,
      minWidth: 120,
      editable: false,
      sortable: false,
      type: "singleSelect",
      valueOptions: activeProjectAssignments.map((assignment) => ({
        value: assignment.projectId,
        label: assignment.projectName,
      })),
      // valueOptions: Array.from(
      //   new Set(
      //     activeProjectAssignments.map((assignment) => assignment.projectName)
      //   )
      // ).map((projectName) => {
      //   const assignment = activeProjectAssignments.find(
      //     (a) => a.projectName === projectName
      //   );
      //   return {
      //     value: assignment?.id || '',
      //     label: projectName,
      //   };
      // }),
      // renderCell: (params) => {
      //   return isSmallScreen ? null : <>{params.row.projectName}</>;
      // },
    },
    {
      field: "projectAssignmentId",
      headerName: "Assignment",
      flex: 1,
      editable: isAdmin,
      sortable: false,
      type: "singleSelect",
      valueOptions: activeProjectAssignments.map((assignment) => ({
        value: assignment.id,
        label: assignment.displayName,
      })),
      // renderCell: (params: GridRenderCellParams) => {
      //   return isMediumScreen ? null : <>{params.label}</>;
      // },
    },
    {
      field: "billable",
      headerName: "",
      flex: 0.5,
      minWidth: 50,
      maxWidth: 50,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const isDisabled =
          params.row.submitted ||
          params.row.approved ||
          !params.row.projectAssignmentId;
        return (
          <Tooltip
            title={
              params.row.submitted
                ? "Cannot modify submitted entries"
                : params.row.approved
                  ? "Cannot modify approved entries"
                  : !params.row.projectAssignmentId
                    ? "Assignment is required for billable entries."
                    : "Toggle billable status"
            }
          >
            <span>
              <IconButton
                onClick={() => {
                  if (!isDisabled) {
                    const newValue = !params.value;
                    const updatedRow = { ...params.row, billable: newValue };
                    handleProcessRowUpdate(updatedRow, params.row);
                  }
                }}
                sx={{
                  color: params.value
                    ? theme.palette.success.main
                    : theme.palette.text.secondary,
                  pointerEvents: isDisabled ? "none" : "auto",
                  opacity: !params.row.projectAssignmentId ? 0.5 : 1,
                  "&:hover": {
                    backgroundColor: isDisabled ? "transparent" : undefined,
                  },
                }}
              >
                {params.value ? <AttachMoneyIcon /> : <MoneyOffIcon />}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      editable: true,
      sortable: false,
      type: "date",
      valueGetter: (value: Date, row, column, apiRef) =>
        value && DateTime.fromISO(value.toString()).toJSDate(),
      valueFormatter: (params) =>
        DateTime.fromJSDate(params).toFormat("yyyy-MM-dd"),
      valueSetter: (value, row, column, apiRef) => {
        if (!value) return;
        const newDate = DateTime.fromJSDate(value as Date);
        return { ...row, date: newDate.toISODate() };
      },
    },
    {
      field: "startTime",
      headerName: "Start",
      flex: 0.7,
      minWidth: 60,
      maxWidth: 90,
      editable: true,
      sortable: false,
      valueGetter: (value: any, row, column, apiRef) => {
        return value && DateTime.fromISO(value.toString()).toFormat("HH:mm:ss");
      },
      renderCell: (params) =>
        DateTime.fromISO(params.value).toFormat("HH:mm:ss"),
      valueFormatter: (params: any) => params.toString(),
      valueSetter: (value, row, column, apiRef) => {
        if (!value) return;
        const [hours, minutes] = value.split(":");
        const selectedDate = DateTime.fromISO(row.date);
        const newTime = DateTime.fromISO(row.startTime).set({
          hour: parseInt(hours),
          minute: parseInt(minutes),
          year: selectedDate.year,
          month: selectedDate.month,
          day: selectedDate.day,
        });
        return { ...row, startTime: newTime.toISO() };
      },
    },
    {
      field: "endTime",
      headerName: "End",
      flex: 0.7,
      minWidth: 60,
      maxWidth: 90,
      editable: true,
      sortable: false,
      valueGetter: (value: any, row, column, apiRef) => {
        return value && DateTime.fromISO(value.toString()).toFormat("HH:mm:ss");
      },
      renderCell: (params) =>
        DateTime.fromISO(params.value).toFormat("HH:mm:ss"),
      valueFormatter: (params: any) => params.toString(),
      valueSetter: (value, row, column, apiRef) => {
        if (!value) return;
        const [hours, minutes] = value.split(":");
        const selectedDate = DateTime.fromISO(row.date);
        const newTime = DateTime.fromISO(row.endTime).set({
          hour: parseInt(hours),
          minute: parseInt(minutes),
          year: selectedDate.year,
          month: selectedDate.month,
          day: selectedDate.day,
        });
        return { ...row, endTime: newTime.toISO() };
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 0.7,
      minWidth: 80,
      maxWidth: 100,
      editable: true,
      sortable: false,
      type: "string",
      renderCell: (params) => {
        if (params.row.timerActive) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Tooltip title="Timing in progress...">
                <Box
                  sx={{
                    animation: `${hourglassAnimation} 4s infinite steps(1, end)`,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <HourglassBottom />
                </Box>
              </Tooltip>
            </Box>
          );
        }
        // If timer is not active, display the duration
        const durationInSeconds = calculateDuration(
          params.row.startTime,
          params.row.endTime
        );
        return formatDurationForEdit(durationInSeconds);
      },
      valueGetter: (value, row, column, apiRef) => {
        const durationInSeconds = calculateDuration(row.startTime, row.endTime);
        return formatDurationForEdit(durationInSeconds);
      },
      valueSetter: (value, row, column, apiRef) => {
        if (!value) return;
        const newDurationInSeconds = parseDuration(value);

        return { ...row, duration: newDurationInSeconds };
      },
    },
    {
      field: "submitted",
      headerName: "",
      flex: 0.5,
      minWidth: 50,
      maxWidth: 50,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        let icon;
        let tooltipTitle;

        if (params.value.timerActive) {
          icon = <HourglassBottom />;
          tooltipTitle = "Timer Active";
        } else if (params.row.approved) {
          icon = <CheckCircle />;
          tooltipTitle = "Approved";
        } else if (params.row.submitted) {
          icon = <PendingActions />;
          tooltipTitle = "Submitted";
        } else {
          icon = <AccessTime />;
          tooltipTitle = "Not Submitted";
        }

        return <Tooltip title={tooltipTitle}>{icon}</Tooltip>;
      },
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      minWidth: 40,
      maxWidth: 60,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {!params.row.approved && !params.row.timerActive ? (
            <>
              <IconButton
                onClick={(event) => handleMenuOpen(event, params.row.id)}
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedRowId === params.row.id}
                onClose={handleMenuClose}
              >
                {params.row.submitted && !params.row.approved ? (
                  <MenuItem
                    onClick={() => handleWithdrawForApproval(params.row)}
                  >
                    <Replay fontSize="small" style={{ marginRight: 8 }} />
                    Withdraw
                  </MenuItem>
                ) : !params.row.submitted &&
                  !params.row.approved &&
                  !params.row.timerActive ? (
                  <MenuItem onClick={() => handleSubmitForApproval(params.row)}>
                    <Publish fontSize="small" style={{ marginRight: 8 }} />
                    Submit
                  </MenuItem>
                ) : null}
                <MenuItem onClick={handleDeleteClick}>
                  <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                  Delete
                </MenuItem>
                {!params.row.approved && !params.row.timerActive && (
                  <MenuItem
                    onClick={() => {
                      setSelectedTimesheet(params.row);
                      setLongDescriptionDialogOpen(true);
                      handleMenuClose();
                    }}
                  >
                    <EditNote fontSize="small" style={{ marginRight: 8 }} />
                    Long Description
                  </MenuItem>
                )}
              </Menu>
            </>
          ) : null}
          {params.row.approved ? (
            <Tooltip title="Time entry is approved, cannot edit.">
              <Lock />
            </Tooltip>
          ) : null}
        </Box>
      ),
    },
  ];

  const filteredWeekEntries = weekEntries.filter((entry) =>
    paginatedEntries.includes(entry)
  );

  if (filteredWeekEntries.length > 0) {
    const [startDate, endDate] = weekKey.split(" to ");
    const formattedStartDate = DateTime.fromISO(startDate).toFormat("MMMM d");
    const formattedEndDate = DateTime.fromISO(endDate).toFormat("MMMM d, yyyy");
    const totalTime = calculateTotalTimeForWeek(weekEntries);
    return (
      <Box key={weekKey} sx={{ mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            border: `1px solid ${theme.palette.divider}`,
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.text.primary,
                  mr: 2,
                }}
              >
                {`${formattedStartDate} - ${formattedEndDate}`}
              </Typography>
              {filteredWeekEntries.every((entry) => entry.submitted) ? (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleWithdrawWeekForApproval(filteredWeekEntries)
                  }
                  sx={{
                    textTransform: "none",
                    fontSize: "0.75rem",
                    padding: "2px 8px",
                    minWidth: "auto",
                    border: "none",
                    "&:hover": {
                      border: `1px solid ${theme.palette.primary.main}`,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Withdraw pending approvals
                </Button>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleSubmitWeekForApproval(filteredWeekEntries)
                  }
                  sx={{
                    textTransform: "none",
                    fontSize: "0.75rem",
                    padding: "2px 8px",
                    minWidth: "auto",
                    border: "none",
                    "&:hover": {
                      border: `1px solid ${theme.palette.primary.main}`,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Submit Week For Approval
                </Button>
              )}
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              Total: {totalTime}
            </Typography>
          </Box>
          <Box sx={{ pt: 2 }}>
            <DataGridPro
              rows={filteredWeekEntries}
              columns={columns}
              autoHeight
              disableColumnFilter
              disableColumnResize
              disableColumnReorder
              disableColumnMenu
              disableColumnSelector
              disableChildrenSorting
              disableMultipleColumnsSorting
              disableDensitySelector
              disableRowSelectionOnClick
              columnHeaderHeight={40}
              sortingMode="server"
              rowHeight={50}
              editMode="row"
              onProcessRowUpdateError={(error) => {
                console.error("Error updating row:", error);
              }}
              processRowUpdate={handleProcessRowUpdate}
              isCellEditable={(params) => {
                if (params.field === "projectAssignmentId" && !isAdmin) {
                  return false;
                }
                return (
                  !params.row.timerActive &&
                  !params.row.submitted &&
                  !params.row.approved
                );
              }}
              hideFooter
              // getDetailPanelContent={({ row }) => (
              //   <div>{`Project: ${row.projectName} \n Assignment: ${row.projectAssignmentId} \n Client: ${row.clientName}`}</div>
              // )}
              // getDetailPanelHeight={() => 50}
              sx={{
                border: "none",
                "& .MuiDataGrid-cell": {
                  padding: "8px",
                  borderBottom: "none",
                  color: theme.palette.text.primary,
                },
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  color: theme.palette.text.primary,
                },
                "& .MuiDataGrid-virtualScroller": {
                  marginTop: "0 !important",
                },
                "& .MuiDataGrid-row": {
                  "&:nth-of-type(odd)": {
                    backgroundColor: alpha(
                      theme.palette.background.default,
                      0.5
                    ),
                  },
                  "&:nth-of-type(even)": {
                    backgroundColor: alpha(theme.palette.background.paper, 0.5),
                  },
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.action.hover, 0.1),
                  },
                },
                "& .MuiDataGrid-columnHeader": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-cellEditable": {
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                },
                "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                  outline: `2px solid ${theme.palette.primary.main}`,
                  outlineOffset: "-2px",
                },
                "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
                  {
                    outline: "none",
                  },
              }}
            />
          </Box>
        </Paper>
        {selectedTimesheet && (
          <LongDescriptionDialog
            open={longDescriptionDialogOpen}
            onClose={() => {
              setLongDescriptionDialogOpen(false);
              setSelectedTimesheet(null);
            }}
            timesheet={selectedTimesheet}
            onSave={handleLongDescriptionUpdate}
          />
        )}
      </Box>
    );
  }
  return null;
};

const LongDescriptionDialog: React.FC<LongDescriptionDialogProps> = ({
  open,
  onClose,
  timesheet,
  onSave,
}) => {
  const [longDescription, setLongDescription] = useState(
    timesheet.longDescription || ""
  );
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);
    try {
      await onSave(longDescription);
    } catch (error) {
      console.error("Failed to save long description:", error);
      setError("Failed to save long description. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Long Description</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {/* Timesheet Details */}
        <Box sx={{ mb: 3, mt: 1 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Entry Details (Read-only)
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
            }}
          >
            <Typography variant="body2">
              <strong>Date:</strong>{" "}
              {DateTime.fromISO(timesheet.date).toFormat("dd LLL yyyy")}
            </Typography>
            <Typography variant="body2">
              <strong>Duration:</strong>{" "}
              {formatDurationForEdit(timesheet.duration)}
            </Typography>
            <Typography variant="body2">
              <strong>Description:</strong> {timesheet.description}
            </Typography>
            <Typography variant="body2">
              <strong>Status:</strong>{" "}
              {timesheet.approved
                ? "Approved"
                : timesheet.submitted
                  ? "Submitted"
                  : "Not Submitted"}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Markdown Editor */}
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Long Description
        </Typography>
        <Box
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            overflow: "hidden",
            "& .markdown-editor-toolbar": {
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              backgroundColor: (theme) =>
                alpha(theme.palette.background.default, 0.5),
            },
            "& .markdown-editor-content": {
              minHeight: 300,
              padding: 2,
            },
          }}
        >
          <MarkdownEditor
            value={longDescription}
            onChange={setLongDescription}
            placeholder="Add additional details here..."
            minHeight={300}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={isSaving}
          startIcon={isSaving && <CircularProgress size={20} />}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeTrackerGrid;