import React, { useEffect } from "react";
import {
  Home as HomeIcon,
  Person2,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  BorderColor as BorderColorIcon,
} from "@mui/icons-material";
import { useAuth } from "hooks/auth/useAuth";
import {
  Home as FreelancerHome,
  ProjectAssignments,
  Manage,
  SignAgreements,
} from "views/Freelancer";
import {
  TimeTracker,
} from "views/SuperAdmin";
import { CustomNavigation } from "router/types/customNavigation";
import Freelo from "views/Freelo/Freelo";
import FreeloBoard from "views/Freelo/FreeloBoard/FreeloBoard";
import KnowledgeBase from "views/KnowledgeBase/KnowledgeBase";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { Group as GroupIcon } from "@mui/icons-material";
import FreeloTicketDialog from "views/Freelo/FreeloTicketDialog/FreeloTicketDialog";

export const freelancerPages: CustomNavigation = [
    { kind: "header", title: "General" },
    {
      segment: "freelancer",
      title: "Home",
      icon: <HomeIcon />,
      component: <FreelancerHome />,
    },
    {
      segment: "freelancer-manage",
      title: "Profile",
      icon: <Person2 />,
      component: <Manage />,
    },
    {
      segment: "timesheet",
      title: "Time Tracker",
      icon: <AccessTimeIcon />,
      component: <TimeTracker />,
    },
    {
      segment: "project-assignments",
      title: "Project Assignments",
      icon: <AssignmentIcon />,
      component: <ProjectAssignments />,
    },
    {
      segment: "sign-agreements",
      title: "Sign Agreements",
      icon: <BorderColorIcon />,
      component: <SignAgreements />,
    },
    { kind: "header", title: "Beta Features" },
    { kind: "divider" },
    {
      kind: "page",
      title: "Freelo",
      segment: "freelo",
      icon: <ViewKanbanIcon />,
      component: <Freelo />,
      slugBabyPattern: ":boardId",
      slugBabyComponent: <FreeloBoard />,
    },
    {
      segment: "knowledge-base",
      title: "Knowledge Base",
      icon: <GroupIcon />,
      component: <KnowledgeBase />,
    },
  ];
  