/* eslint-disable */

// TODO: @adamsiwiec1 i will type this

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import {
  User,
  UserCredential,
  onAuthStateChanged,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  getAdditionalUserInfo,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  applyActionCode,
} from "@firebase/auth";
import { auth } from "config/firebase";
import { removeJwtToken, setJwtToken, getJwtToken } from "core";
import { getUserProfile, signUpAndSendEmail } from "core/users";
import { getUTCtimeNow } from "core/utils";
import { handleLoginWithGoogle } from "core/users/handleLoginWithGoogle";
import { handleLoginWithEmail } from "core/users/handleLoginWithEmail";
import { resendVerificationEmail } from "core/users";
import HyperDX from "@hyperdx/browser";
import { logHyperDxAction } from "core/hyperdx/logHyperDxAction";

// TODO: Doing this as i type ill probably finish https://firebase.google.com/docs/auth/custom-email-handler#create_the_email_action_handler_page

export const AuthContext = createContext<AuthContextType | null>(null);

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  userInfo: UserInfoType | null;
  setUserInfo: (userInfo: UserInfoType) => void;
  isLoading: boolean;
  loginWithEmail: (credentials: LoginCredentials) => Promise<any>;
  loginWithGoogle: () => Promise<any>;
  logout: () => void;
  // verify: (token: string) => Promise<Response>;
  handleResendVerificationEmail: (email: string) => Promise<boolean>;
  handleSendPasswordResetEmail: (email: string) => Promise<boolean>;
  handleVerifyPasswordResetCode: (code: string) => Promise<string>;
  handleVerifyActionCode: (code: string) => Promise<boolean>;
  handleConfirmPasswordReset: (
    code: string,
    newPassword: string
  ) => Promise<boolean>;
  getUserInfo: () => Promise<UserInfoType | null>;
  signUp: (credentials: SignUpCredentials) => Promise<boolean>;
  isVerified: () => boolean;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true); // handle auth check loading state

  const isVerified = () => true;
  // userInfo ? userInfo.email_verified : false;

  const loginWithEmail = async (credentials: LoginCredentials) => {
    return await handleLoginWithEmail(
      credentials.email,
      credentials.password,
      setUserInfo,
      setIsAuthenticated
    );
  };

  const loginWithGoogle = async () => {
    setIsLoading(true);
    const response = await handleLoginWithGoogle(
      setIsAuthenticated,
      setUserInfo
    );
    setIsLoading(false);
    return response;
  };

  const logout = async () => {
    removeJwtToken();
    setIsAuthenticated(false);
    setUserInfo(null);
    return await signOut(auth);
  };

  const handleResendVerificationEmail = async (email: string) => {
    try {
      await resendVerificationEmail(email);
      return true;
    } catch (error) {
      console.error("Error resending verification email:", error);
      return false;
    }
  };

  const handleVerifyActionCode = async (code: string) => {
    try {
      await applyActionCode(auth, code);
      return true;
    } catch (error) {
      console.error("Error verifying action code:", error);
      return false;
    }
  };

  const handleVerifyPasswordResetCode = async (code: string) => {
    const userEmail = await verifyPasswordResetCode(auth, code);
    return userEmail;
  };

  const handleSendPasswordResetEmail = async (email: string) => {
    const actionCodeSettings = {
      url: "http://localhost:3000",
      handleCodeInApp: false,
    };

    try {
      const response = await sendPasswordResetEmail(
        auth,
        email,
        actionCodeSettings
      );
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      return false;
    }
  };

  const handleConfirmPasswordReset = async (
    code: string,
    newPassword: string
  ) => {
    try {
      const response = await confirmPasswordReset(auth, code, newPassword);
      return true;
    } catch (error) {
      console.error("Error confirming password reset:", error);
      return false;
    }
  };

  const refreshUserData = async (currentUser: User) => {
    const jwtToken = await currentUser?.getIdToken(true);
    if (jwtToken) {
      setJwtToken(jwtToken);
    } else {
      console.error("Failed to get JWT token");
      await logout();
      window.location.href = "/login";
    }
    const docSnapData = await getUserProfile(currentUser.uid);
    const verified = currentUser.emailVerified;
    const data = { ...docSnapData, email_verified: verified };
    
    if (docSnapData) {
      if (!docSnapData.is_active) {
        
        await logout();
        window.location.href = "/account-deactivated";
      }

      // @ts-ignore
      setUserInfo((prev) => ({ ...prev, ...data })); // Set the state here
    } else {
      
    }
  };

  const getUserInfo = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("Failed to getUserInfo.");
      return null;
    }

    return (await getUserProfile(currentUser?.uid)) as UserInfoType | null;
  };
  const signUp = async (credentials: SignUpCredentials) => {
    return await signUpAndSendEmail(credentials);
  };

  useEffect(() => {
    setIsLoading(true);
    
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsAuthenticated(!!user);
      if (user) {
        if (user.email) {
          HyperDX.setGlobalAttributes({
            userId: user.uid,
            userEmail: user.email,
          });
        }

        await refreshUserData(user);
      }
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [auth, auth.currentUser]);

  

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userInfo,
        setUserInfo,
        isLoading,
        isVerified,
        loginWithEmail,
        getUserInfo,
        loginWithGoogle,
        logout,
        signUp,
        handleResendVerificationEmail,
        handleSendPasswordResetEmail,
        handleVerifyPasswordResetCode,
        handleConfirmPasswordReset,
        handleVerifyActionCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
