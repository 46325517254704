import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  getDoc,
  where,
} from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { useAuth } from "hooks/auth/useAuth";
import { logBoardActivity } from "./useBoardActivity";
import { generateActivityMessage } from './generateActivityMessage';
import { getUserName } from "hooks/shared/user/useUserName";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "config/firebase";

interface UpdateCardParams {
  boardId: string;
  cardId: string;
  updates: Partial<Card>;
}

interface CreateCardParams {
  boardId: string;
  listId: string;
  name: string;
  description?: string;
  assignee?: string;
  reporter?: string;
  additionalAssignees?: string[];
  labels?: CardLabel[];
  dueDate?: string | null;
  startDate?: string | null;
  comments?: CardComment[];
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
}

const getListNameById = (board: Board | undefined, listId: string): string => {
  console.log("board", board);
  console.log("listId", listId);
  return board?.columns?.find((col) => col.id === listId)?.name || listId;
};

const sanitizeForFirestore = (data: any): any => {
  if (data === undefined) return null;
  if (Array.isArray(data)) {
    return data.map(sanitizeForFirestore);
  }
  if (data && typeof data === 'object') {
    const sanitized: Record<string, any> = {};
    for (const [key, value] of Object.entries(data)) {
      sanitized[key] = sanitizeForFirestore(value);
    }
    return sanitized;
  }
  return data;
};

export const useFreelo = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();

  // Fetch boards
  const getBoards = async (): Promise<Board[]> => {
    const boardsRef = collection(db, "boards");
    const q = query(boardsRef, orderBy("order"));
    const snapshot = await getDocs(q);

    const boards = await Promise.all(
      snapshot.docs.map(async (boardDoc) => {
        // Get lists for this board
        const listsRef = collection(db, "boards", boardDoc.id, "lists");
        const listsSnapshot = await getDocs(listsRef);

        const lists = listsSnapshot.docs.map((listDoc) => ({
          id: listDoc.id,
          ...listDoc.data(),
        }));

        console.log("lists", lists);

        return {
          id: boardDoc.id,
          ...boardDoc.data(),
          columns: lists,
        } as Board;
      })
    );

    return boards;
  };

  // Query hook for fetching boards
  const {
    data: boards,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["boards"],
    queryFn: getBoards,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const generateHumanReadableCardId = async (boardId: string) => {
    const board = boards?.find((b) => b.id === boardId);

    // Get all cards directly from the board's cards collection
    const cardsRef = collection(db, "boards", boardId, "cards");
    const cardsSnapshot = await getDocs(cardsRef);
    const totalCardCount = cardsSnapshot.size;

    const cardCountString = (totalCardCount + 1).toString().padStart(2, "0");

    return `${board?.name
      .split(" ")
      .slice(0, 2)
      .map((n) => n[0])
      .join("")}-${cardCountString}`;
  };

  // Create new board
  const createBoard = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const boardData: Omit<Board, "id"> = {
        name,
        order: (boards?.length || 0) + 1,
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
        createdBy: userInfo?.id || "system",
        columns: [],
      };

      const boardRef = collection(db, "boards");
      const newBoardDoc = await addDoc(boardRef, boardData);
      return newBoardDoc.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["boards"] });
    },
  });

  // Update board order
  const updateBoardOrder = useMutation({
    mutationFn: async (updatedBoards: Board[]) => {
      const updates = updatedBoards.map((board, index) => {
        const boardRef = doc(db, "boards", board.id);
        return updateDoc(boardRef, { order: index + 1 });
      });
      await Promise.all(updates);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["boards"] });
    },
  });

  // Get specific board with its lists and cards
  const useBoard = (boardId: string) => {
    return useQuery<Board, Error>({
      queryKey: ["board", boardId],
      queryFn: async () => {
        const boardRef = doc(db, "boards", boardId);
        const boardDoc = await getDoc(boardRef);

        if (!boardDoc.exists()) {
          throw new Error("Board not found");
        }

        const boardData = boardDoc.data() as Board;

        // Get all lists
        const listsRef = collection(db, "boards", boardId, "lists");
        const listsSnapshot = await getDocs(listsRef);
        const lists = listsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Column[];

        // Get all cards
        const cardsRef = collection(db, "boards", boardId, "cards");
        const cardsSnapshot = await getDocs(cardsRef);
        const cards = cardsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Card[];

        // Group cards by listId
        const listsWithCards = lists.map((list) => ({
          ...list,
          cards: cards.filter((card) => card.listId === list.id),
        }));

        // Get labels
        const labelsRef = collection(db, "boards", boardId, "labels");
        const labelsSnapshot = await getDocs(labelsRef);
        const labels = labelsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as CardLabel[];

        return {
          ...boardData,
          id: boardDoc.id,
          columns: listsWithCards,
          labels,
        } as Board;
      },
      enabled: !!boardId,
    });
  };

  // Update board
  const updateBoard = useMutation({
    mutationFn: async ({
      boardId,
      data,
    }: {
      boardId: string;
      data: Partial<Board>;
    }) => {
      const boardRef = doc(db, "boards", boardId);
      await updateDoc(boardRef, {
        ...data,
        updatedAt: DateTime.now().toISO(),
      });

      if (data.readUserIds || data.writeUserIds) {
        await logBoardActivity(
          boardId,
          data.readUserIds ? "user-add" : "user-remove",
          userInfo?.id || "system",
          `Updated board access permissions`
        );
      }

      return boardId;
    },
    onSuccess: (boardId) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
      queryClient.invalidateQueries({ queryKey: ["boards"] });
    },
  });

  // Create new list
  const createList = useMutation({
    mutationFn: async ({
      boardId,
      name,
    }: {
      boardId: string;
      name: string;
    }) => {
      // Get the current board to find the highest order
      const boardRef = doc(db, "boards", boardId);
      const boardDoc = await getDoc(boardRef);
      const board = boardDoc.data() as Board;

      // Get all lists to find the highest order
      const listsRef = collection(db, "boards", boardId, "lists");
      const listsSnapshot = await getDocs(listsRef);

      // Find the highest order value with proper null handling
      const highestOrder = Math.max(
        ...listsSnapshot.docs.map((doc) => doc.data().order || 0),
        ...(board.columns?.map((col) => col.order || 0) || []),
        -1
      );

      // Create new list with order = highest + 1
      const newListRef = await addDoc(listsRef, {
        name,
        order: highestOrder + 1,
        cards: [],
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
      });

      // Update the board's columns array
      const newColumns = [
        ...(board.columns || []),
        {
          id: newListRef.id,
          name,
          order: highestOrder + 1,
          cards: [],
          createdAt: DateTime.now().toISO(),
          updatedAt: DateTime.now().toISO(),
        },
      ];

      // Update the board document
      await updateDoc(boardRef, {
        columns: newColumns,
        updatedAt: DateTime.now().toISO(),
      });

      return newListRef.id;
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  // Create new card
  const createCard = useMutation({
    mutationFn: async ({
      boardId,
      listId,
      name,
      description,
      assignee,
      reporter,
      additionalAssignees,
      labels,
      dueDate,
      startDate,
      comments,
      createdBy,
      createdAt,
      updatedAt,
    }: CreateCardParams) => {
      const cardsCollectionRef = collection(db, "boards", boardId, "cards");
      const humanReadableId = await generateHumanReadableCardId(boardId);

      const newCardRef = await addDoc(cardsCollectionRef, {
        title: name,
        humanReadableId,
        listId,
        description: description || "",
        assignee: assignee || "",
        reporter: reporter || "",
        additionalAssignees: additionalAssignees || [],
        labels: labels || [],
        dueDate: dueDate || null,
        startDate: startDate || null,
        comments: comments || [],
        createdAt: createdAt || DateTime.now().toISO(),
        updatedAt: updatedAt || DateTime.now().toISO(),
        createdBy: createdBy || userInfo?.id || "system",
        archived: false,
      });

      await logBoardActivity(
        boardId,
        "card-create",
        userInfo?.id || "system",
        `Created card '${humanReadableId}: ${name}' in list '${getListNameById(
          boards?.find((b) => b.id === boardId),
          listId
        )}'`
      );

      return newCardRef.id;
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  // Update list
  const updateList = useMutation({
    mutationFn: async ({
      boardId,
      listId,
      name,
      cardOrder,
    }: {
      boardId: string;
      listId: string;
      name?: string;
      cardOrder?: string[];
    }) => {
      const listRef = doc(db, "boards", boardId, "lists", listId);
      const updates: any = {
        updatedAt: new Date().toISOString(),
      };
      if (name !== undefined) updates.name = name;
      if (cardOrder !== undefined) updates.cardOrder = cardOrder;
      
      await updateDoc(listRef, updates);
      return { boardId, listId, updates };
    },
    onMutate: async ({ boardId, listId, name, cardOrder }) => {
      await queryClient.cancelQueries({ queryKey: ["board", boardId] });
      const previousBoard = queryClient.getQueryData<Board>(["board", boardId]);

      if (previousBoard) {
        const updatedBoard = {
          ...previousBoard,
          columns: (previousBoard.columns || []).map((col) =>
            col.id === listId ? { ...col, name, cardOrder } : col
          ),
        };
        queryClient.setQueryData(["board", boardId], updatedBoard);
      }

      return { previousBoard };
    },
    onError: (err, { boardId }, context) => {
      if (context?.previousBoard) {
        queryClient.setQueryData(["board", boardId], context.previousBoard);
      }
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  // Delete list
  const deleteList = useMutation({
    mutationFn: async ({
      boardId,
      listId,
    }: {
      boardId: string;
      listId: string;
    }) => {
      // Delete all cards associated with this list
      const cardsRef = collection(db, "boards", boardId, "cards");
      const q = query(cardsRef, where("listId", "==", listId));
      const cardsSnapshot = await getDocs(q);

      await Promise.all(
        cardsSnapshot.docs.map((cardDoc) =>
          deleteDoc(doc(db, "boards", boardId, "cards", cardDoc.id))
        )
      );

      // Delete the list itself
      const listRef = doc(db, "boards", boardId, "lists", listId);
      await deleteDoc(listRef);

      return listId;
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const updateCard = useMutation({
    mutationFn: async ({ boardId, cardId, updates }: UpdateCardParams) => {
      const sanitizedUpdates = sanitizeForFirestore(updates);
      
      const cardRef = doc(db, 'boards', boardId, 'cards', cardId);
      await updateDoc(cardRef, sanitizedUpdates);
      
      const cardDoc = await getDoc(cardRef);
      const card = cardDoc.data() as Card;

      const updateField = Object.keys(updates)[0] as keyof Card;
      const updateValue = updates[updateField];
      const oldValue = card[updateField];

      const updatedCard = {
        ...card,
        ...updates,
        updatedAt: DateTime.now().toISO(),
      };

      let assigneeName, oldAssigneeName, addedUsers, removedUsers;
      if (updateField === 'assignee') {
        assigneeName = updateValue ? await getUserName(updateValue as string) : undefined;
        oldAssigneeName = oldValue ? await getUserName(oldValue as string) : undefined;
      } else if (updateField === 'additionalAssignees') {
        const oldAssignees = oldValue as string[] || [];
        const newAssignees = updateValue as string[] || [];
        
        const added = newAssignees.filter(id => !oldAssignees.includes(id));
        const removed = oldAssignees.filter(id => !newAssignees.includes(id));
        
        addedUsers = await Promise.all(added.map(id => getUserName(id)));
        removedUsers = await Promise.all(removed.map(id => getUserName(id)));
      }

      const activityMessage = generateActivityMessage({
        type: "card-update",
        cardHumanReadableId: card.humanReadableId,
        fieldName: updateField,
        newValue: String(updateValue),
        oldValue: String(oldValue),
        assigneeName,
        oldAssigneeName,
        addedUsers,
        removedUsers,
      });

      await logBoardActivity(
        boardId,
        "card-update",
        userInfo?.id || "system",
        activityMessage
      );

      return { cardId, ...updatedCard };
    },
    onMutate: async ({ boardId, cardId, updates }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["board", boardId] });

      // Snapshot the previous value
      const previousBoard = queryClient.getQueryData<Board>(["board", boardId]);

      if (previousBoard && previousBoard.columns) {
        // Create a new board with the updated card
        const updatedBoard = {
          ...previousBoard,
          columns: previousBoard.columns.map((column) => ({
            ...column,
            cards: Array.isArray(column.cards)
              ? column.cards.map((card) =>
                  card.id === cardId
                    ? {
                        ...card,
                        ...updates,
                        updatedAt: DateTime.now().toISO(),
                      }
                    : card
                )
              : [],
          })),
        };

        // Optimistically update to the new value
        queryClient.setQueryData(["board", boardId], updatedBoard);
      }

      return { previousBoard };
    },
    onError: (err, { boardId }, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      console.error("board error", err, boardId, context);
      if (context?.previousBoard) {
        queryClient.setQueryData(["board", boardId], context.previousBoard);
        // Only invalidate queries on error
        queryClient.invalidateQueries({ queryKey: ["board", boardId] });
      }
    },
  });

  // Add this mutation for updating list color
  const updateListColor = useMutation({
    mutationFn: async ({
      boardId,
      listId,
      color,
    }: {
      boardId: string;
      listId: string;
      color: string | null;
    }) => {
      const listRef = doc(db, "boards", boardId, "lists", listId);
      await updateDoc(listRef, {
        color,
        updatedAt: DateTime.now().toISO(),
      });
      return { listId, color };
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const createLabel = useMutation({
    mutationFn: async ({
      boardId,
      name,
      color,
    }: {
      boardId: string;
      name: string;
      color: string;
    }) => {
      const labelsRef = collection(db, "boards", boardId, "labels");
      const newLabelRef = await addDoc(labelsRef, {
        name,
        color,
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
      });
      return newLabelRef.id;
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const updateCardLabels = useMutation({
    mutationFn: async ({
      boardId,
      cardId,
      labels,
    }: {
      boardId: string;
      cardId: string;
      labels: CardLabel[];
    }) => {
      const cardRef = doc(db, "boards", boardId, "cards", cardId);
      const cardDoc = await getDoc(cardRef);
      const card = cardDoc.data() as Card;

      await updateDoc(cardRef, {
        labels,
        updatedAt: DateTime.now().toISO(),
      });

      await logBoardActivity(
        boardId,
        "card-update",
        userInfo?.id || "system",
        `Updated labels on card '${card.humanReadableId}: ${card.title}'`
      );
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const deleteLabel = useMutation({
    mutationFn: async ({
      boardId,
      labelId,
    }: {
      boardId: string;
      labelId: string;
    }) => {
      const labelRef = doc(db, "boards", boardId, "labels", labelId);
      await deleteDoc(labelRef);

      // Log activity
      await logBoardActivity(
        boardId,
        "label-delete",
        userInfo?.id || "system",
        `Deleted label ${labelId}`
      );
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const archiveList = useMutation({
    mutationFn: async ({
      boardId,
      listId,
    }: {
      boardId: string;
      listId: string;
    }) => {
      const board = await getDoc(doc(db, "boards", boardId));
      const listName = getListNameById(board.data() as Board, listId);

      // Get all cards in the list
      const cardsRef = collection(db, "boards", boardId, "cards");
      const q = query(cardsRef, where("listId", "==", listId));
      const cardsSnapshot = await getDocs(q);

      // Update all cards to archived status
      const updatePromises = cardsSnapshot.docs.map((cardDoc) => {
        return updateDoc(doc(db, "boards", boardId, "cards", cardDoc.id), {
          archived: true,
          updatedAt: DateTime.now().toISO(),
        });
      });

      await Promise.all(updatePromises);

      await logBoardActivity(
        boardId,
        "list-archive",
        userInfo?.id || "system",
        `Archived list '${listName}'`
      );

      return listId;
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const moveCardToTrash = useMutation({
    mutationFn: async ({
      boardId,
      listId,
      cardId,
      card,
    }: {
      boardId: string;
      listId: string;
      cardId: string;
      card: Card;
    }) => {
      const cardTrashRef = collection(db, "boards", boardId, "cardTrash");
      await addDoc(cardTrashRef, {
        ...card,
        originalListId: listId,
        deletedAt: DateTime.now().toISO(),
        comments: card.comments || [],
      });

      const cardRef = doc(db, "boards", boardId, "cards", cardId);
      await deleteDoc(cardRef);

      await logBoardActivity(
        boardId,
        "card-delete",
        userInfo?.id || "system",
        `Moved card '${card.humanReadableId}: ${card.title}' to trash`
      );
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  // Update the moveCard mutation to handle optimistic updates properly
  const moveCard = useMutation({
    mutationFn: async ({
      board,
      cardId,
      newListId,
    }: {
      board: Board;
      cardId: string;
      newListId: string;
    }) => {
      const boardId = board.id;
      const cardRef = doc(db, "boards", boardId, "cards", cardId);
      const cardDoc = await getDoc(cardRef);
      const card = cardDoc.data() as Card;
      
      const oldListName = getListNameById(board, card.listId);
      const newListName = getListNameById(board, newListId);

      const updates = {
        listId: newListId,
        updatedAt: DateTime.now().toISO(),
      };

      await updateDoc(cardRef, updates);

      const activityMessage = generateActivityMessage({
        type: "card-move",
        cardHumanReadableId: card.humanReadableId,
        actorName: userInfo?.firstName + " " + userInfo?.lastName || "System",
        listName: newListName,
        oldListName: oldListName,
      });

      await logBoardActivity(
        boardId,
        "card-move",
        userInfo?.id || "system",
        activityMessage
      );

      return { cardId, newListId };
    },
    onMutate: async ({ board, cardId, newListId }) => {
      await queryClient.cancelQueries({ queryKey: ["board", board.id] });
      const previousBoard = queryClient.getQueryData<Board>([
        "board",
        board.id,
      ]);

      if (previousBoard?.columns) {
        // Find the card and its current list
        let cardToMove: Card | undefined;
        let sourceListId: string | undefined;

        const updatedColumns = previousBoard.columns.map((col) => {
          const cardIndex = col.cards.findIndex((c) => c.id === cardId);
          if (cardIndex !== -1) {
            cardToMove = { ...col.cards[cardIndex] };
            sourceListId = col.id;
            return {
              ...col,
              cards: col.cards.filter((c) => c.id !== cardId),
            };
          }
          return col;
        });

        if (cardToMove) {
          // Add card to new list
          const updatedBoard = {
            ...previousBoard,
            columns: updatedColumns.map((col) =>
              col.id === newListId
                ? {
                    ...col,
                    cards: [
                      ...col.cards,
                      {
                        ...cardToMove!,
                        listId: newListId,
                        updatedAt: DateTime.now().toISO(),
                      },
                    ],
                  }
                : col
            ),
          };

          queryClient.setQueryData(["board", board.id], updatedBoard);
        }
      }

      return { previousBoard };
    },
    onError: (err, { board }, context) => {
      if (context?.previousBoard) {
        queryClient.setQueryData(["board", board.id], context.previousBoard);
      }
    },
    onSettled: (_, __, { board }) => {
      queryClient.invalidateQueries({ queryKey: ["board", board.id] });
    },
  });

  const addComment = useMutation({
    mutationFn: async ({
      boardId,
      cardId,
      content,
      mentionedUserIds,
    }: {
      boardId: string;
      cardId: string;
      content: string;
      mentionedUserIds: string[];
    }) => {
      const cardRef = doc(db, "boards", boardId, "cards", cardId);
      const cardDoc = await getDoc(cardRef);
      const card = cardDoc.data() as Card;

      const newComment: CardComment = {
        id: crypto.randomUUID(),
        content,
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
        createdBy: userInfo?.id || "system",
        mentionedUserIds,
      };

      const updatedCard = {
        ...card,
        comments: [...(card.comments || []), newComment],
        updatedAt: DateTime.now().toISO(),
      };

      await updateDoc(cardRef, updatedCard);

      const activityMessage = generateActivityMessage({
        type: "card-comment",
        cardHumanReadableId: card.humanReadableId,
        actorName: userInfo?.firstName + " " + userInfo?.lastName || "System",
        commentContent: content,
      });

      await logBoardActivity(
        boardId,
        "card-comment",
        userInfo?.id || "system",
        activityMessage
      );

      return { cardId, ...updatedCard };
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const uploadAttachment = useMutation({
    mutationFn: async ({ boardId, cardId, file }: { 
      boardId: string; 
      cardId: string; 
      file: File;
    }) => {
      const cardRef = doc(db, "boards", boardId, "cards", cardId);
      const cardDoc = await getDoc(cardRef);
      const card = cardDoc.data() as Card;

      // Generate a unique filename to prevent collisions
      const uniqueFileName = `${Date.now()}-${file.name}`;
      const storageRef = ref(storage, `boards/${boardId}/cards/${cardId}/${uniqueFileName}`);
      
      // Upload file
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);

      // Handle image thumbnails
      let thumbnailUrl: string | undefined;
      if (file.type.startsWith('image/')) {
        // For images, use the same URL as thumbnail
        thumbnailUrl = downloadUrl;
      }

      // Create attachment object
      const newAttachment: CardAttachment = {
        id: crypto.randomUUID(),
        fileName: file.name,
        url: downloadUrl,
        fileSize: file.size,
        fileType: file.type,
        createdAt: DateTime.now().toISO(),
        createdBy: userInfo?.id || "system",
        thumbnailUrl,
      };

      // Update card with new attachment
      const updatedCard = {
        ...card,
        attachments: [...(card.attachments || []), newAttachment],
        updatedAt: DateTime.now().toISO(),
      };

      await updateDoc(cardRef, updatedCard);

      await logBoardActivity(
        boardId,
        "card-attachment-add",
        userInfo?.id || "system",
        generateActivityMessage({
          type: "card-attachment-add",
          cardHumanReadableId: card.humanReadableId,
          attachmentName: file.name,
          attachmentType: file.type,
        })
      );

      return { cardId, ...updatedCard };
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const deleteAttachment = useMutation({
    mutationFn: async ({ 
      boardId, 
      cardId, 
      attachmentId 
    }: { 
      boardId: string; 
      cardId: string; 
      attachmentId: string;
    }) => {
      const cardRef = doc(db, "boards", boardId, "cards", cardId);
      const cardDoc = await getDoc(cardRef);
      const card = cardDoc.data() as Card;

      const attachment = card.attachments?.find(a => a.id === attachmentId);
      if (!attachment) throw new Error("Attachment not found");

      // Extract filename from the URL or use stored filename
      const fileName = attachment.fileName;
      
      try {
        // Delete from storage
        const storageRef = ref(storage, `boards/${boardId}/cards/${cardId}/${fileName}`);
        await deleteObject(storageRef);
      } catch (error) {
        console.error("Error deleting file from storage:", error);
        // Continue with metadata deletion even if storage deletion fails
      }

      // Update card
      const updatedCard = {
        ...card,
        attachments: card.attachments?.filter(a => a.id !== attachmentId) || [],
        updatedAt: DateTime.now().toISO(),
      };

      await updateDoc(cardRef, updatedCard);

      await logBoardActivity(
        boardId,
        "card-attachment-delete",
        userInfo?.id || "system",
        generateActivityMessage({
          type: "card-attachment-delete",
          cardHumanReadableId: card.humanReadableId,
          attachmentName: attachment.fileName,
        })
      );

      return { cardId, ...updatedCard };
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  return {
    boards,
    isLoading,
    error,
    createBoard,
    updateBoardOrder,
    useBoard,
    updateBoard,
    createList,
    createCard,
    updateList,
    deleteList,
    updateCard,
    updateListColor,
    createLabel,
    updateCardLabels,
    deleteLabel,
    archiveList,
    moveCardToTrash,
    moveCard,
    addComment,
    uploadAttachment,
    deleteAttachment,
  };
};
