import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Avatar, Divider } from '@mui/material';
import { INotifications, useNotifications } from '../../../hooks/notifications/useNotifications';
import { Payment, Assignment, Description } from '@mui/icons-material';
import { useAuth } from 'hooks/auth/useAuth';
import { useNavigate } from 'react-router-dom';
import { getIcon, getNotificationMessage } from './getNotificationDetails';
import { handleNotificationClick } from './handleNotificationClick';

const NotificationCenter = () => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { notifications } = useNotifications(userInfo?.id || '');

  const sortedNotifications = [...notifications].sort((a, b) => {
    const dateA = a.createdAt?.toDate?.() || new Date(0);
    const dateB = b.createdAt?.toDate?.() || new Date(0);
    return dateB.getTime() - dateA.getTime();
  });




  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Notifications
      </Typography>
      <List>
        {sortedNotifications.length > 0 ? (
          sortedNotifications.map(notification => (
            <ListItem 
              key={notification.id} 
              sx={{ 
                padding: '10px 0',
                cursor: notification.type === 'mention' ? 'pointer' : 'default',
                '&:hover': notification.type === 'mention' ? {
                  backgroundColor: 'action.hover'
                } : {}
              }}
              onClick={() => {
                const destination = handleNotificationClick(notification);
                if (destination) {
                  navigate(destination);
                }
              }}
            >
              <ListItemIcon>
                <Avatar sx={{ bgcolor: notification.read ? 'grey.300' : 'primary.light' }}>
                  {React.createElement(getIcon(notification.type) || 'div')}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={getNotificationMessage(notification)}
                secondary={notification.createdAt?.toDate?.()
                  ? notification.createdAt.toDate().toLocaleString()
                  : 'No date'}
                primaryTypographyProps={{
                  fontSize: '0.875rem',
                  fontWeight: notification.read ? 'regular' : 'medium',
                  color: notification.read ? 'text.secondary' : 'text.primary',
                }}
              />
            </ListItem>
          ))
        ) : (
          <Typography sx={{ padding: '16px', textAlign: 'center', color: 'text.secondary' }}>
            No notifications
          </Typography>
        )}
      </List>
      <Divider />
    </Box>
  );
};

export default NotificationCenter;
