import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchProfilePictureUrl } from "./fetchProfilePictureUrl";


export const useProfilePicture = (userId: string) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["profilePicture", userId],
    queryFn: () => fetchProfilePictureUrl(userId),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const refetchProfilePicture = async () => {
    await queryClient.invalidateQueries({ queryKey: ["profilePicture", userId] });
    return query.refetch();
  };

  return {
    url: query.data,
    isLoading: query.isLoading,
    error: query.error,
    refetch: refetchProfilePicture
  };
}; 