import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Typography, Box } from '@mui/material';
import { ErrorOutline as ErrorIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path: string) => {
    resetErrorBoundary();
    navigate(path);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        zIndex: 1,
        gap: 2,
        p: 3
      }}
    >
      <ErrorIcon 
        sx={{ 
          fontSize: 80,
          color: 'error.main',
          mb: 2
        }}
      />
      
      <Typography 
        variant="h4" 
        color="error"
        sx={{ 
          fontWeight: 600,
          textAlign: 'center'
        }}
      >
        Oops! Something went wrong
      </Typography>

      <Typography 
        variant="body1" 
        color="text.secondary"
        sx={{ 
          mb: 4,
          maxWidth: 500,
          textAlign: 'center'
        }}
      >
        {"We encountered an unexpected error. Don't worry, it's not your fault. Our team has been notified and will fix the issue as soon as possible."}
      </Typography>

      <Box 
        sx={{ 
          display: 'flex', 
          gap: 2, 
          justifyContent: 'center',
          flexWrap: { xs: 'wrap', sm: 'nowrap' }
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleNavigation(location.pathname)}
          startIcon={<RefreshIcon />}
          sx={{
            minWidth: { xs: '100%', sm: 160 },
            py: 1.5,
            px: 3,
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-2px)',
            }
          }}
        >
          Try again
        </Button>
        
        <Button
          variant="outlined"
          onClick={() => handleNavigation('/dashboard')}
          sx={{
            minWidth: { xs: '100%', sm: 160 },
            py: 1.5,
            px: 3,
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-2px)',
            }
          }}
        >
          Back to Dashboard
        </Button>
      </Box>
    </Box>
  );
}

const StandardErrorBoundary: React.FC<{ 
  children: React.ReactNode;
  onReset?: () => void;
}> = ({ children, onReset }) => {
  const location = useLocation();



  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={onReset}
      resetKeys={[location.pathname]}
      onError={(error) => {
        console.error('Error caught by boundary:', error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default StandardErrorBoundary;
