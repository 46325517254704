import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { DropResult } from "@hello-pangea/dnd";
import { useFreelo } from "hooks/freelo/useFreelo";
import ShareBoardDialog from "../ShareBoardDialog/ShareBoardDialog";
import { useAuth } from "hooks/auth/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { useUserList } from "hooks/shared/user/useUserList";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { ListColorManager } from "components/ListColorManager/ListColorManager";
import { DeleteListConfirmDialog } from "components/DeleteListConfirmDialog/DeleteListConfirmDialog";
import { ArchiveList } from "../ArchiveList/ArchiveList";
import ArchiveIcon from "@mui/icons-material/Archive";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmActionDialog from "components/ConfirmActionDialog";
import { useBoardActivity } from "hooks/freelo/useBoardActivity";
import { ActivityList } from "../ActivityList/ActivityList";
import { FreeloTicketDialog } from "../FreeloTicketDialog";
import { FreeloBoardHeader } from "./components/FreeloBoardHeader";
import { DraggableCardArea } from "../DraggableCardArea/DraggableCardArea";
import SettingsIcon from '@mui/icons-material/Settings';
import { BoardSettingsDialog } from '../BoardSettingsDialog/BoardSettingsDialog';

const FreeloBoard: React.FC = () => {
  const queryClient = useQueryClient();
  const { boardId } = useParams<{ boardId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: users } = useUserList();
  const { userInfo } = useAuth();
  const isAdmin = userInfo?.user_type === "admin";

  // Move all hooks to the top
  const [newListName, setNewListName] = useState("");
  const [newCardName, setNewCardName] = useState("");
  const [selectedListId, setSelectedListId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [colorAnchorEl, setColorAnchorEl] = useState<null | HTMLElement>(null);
  const [editingListId, setEditingListId] = useState<string | null>(null);
  const [inlineEditName, setInlineEditName] = useState("");
  const [isAddingList, setIsAddingList] = useState(false);
  const [isEditingBoardTitle, setIsEditingBoardTitle] = useState(false);
  const [editBoardTitle, setEditBoardTitle] = useState("");
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  const [isAddingCard, setIsAddingCard] = useState<string | null>(null);
  const [deleteListDialog, setDeleteListDialog] = useState<{
    open: boolean;
    listId: string | null;
  }>({
    open: false,
    listId: null,
  });
  const [isArchiveView, setIsArchiveView] = useState(false);
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);
  const [isActivityView, setIsActivityView] = useState(false);
  const [isCreatingCard, setIsCreatingCard] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [openSettings, setOpenSettings] = useState(false);

  const {
    useBoard,
    updateBoard,
    createList,
    createCard,
    updateList,
    deleteList,
    updateCard,
    updateListColor,
    archiveList,
    moveCard,
  } = useFreelo();

  // These hooks should be called even if boardId is null
  const {
    data: board,
    isLoading: boardLoading,
    error: boardError,
  } = useBoard(boardId || "");
  const {
    activities,
    isLoading: activitiesLoading,
    error: activitiesError,
  } = useBoardActivity(boardId || "");

  // Handle hash changes
  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      // Find card by humanReadableId instead of id
      const cardId = board?.columns?.reduce<string | undefined>(
        (found, column) => {
          if (found) return found;
          const card = column.cards.find((c) => c.humanReadableId === hash);
          return card?.id;
        },
        undefined
      );

      if (cardId) {
        setSelectedCardId(cardId);
      } else {
        setSelectedCardId(null);
      }
    } else {
      setSelectedCardId(null);
    }
  }, [location.hash, board?.columns]);

  // Find the selected card and its list
  const findCardAndList = () => {
    if (!selectedCardId || !board?.columns) return null;

    for (const list of board.columns) {
      const card = list.cards.find((c) => c.id === selectedCardId);
      if (card) {
        return { card, listId: list.id };
      }
    }
    return null;
  };

  const cardAndList = findCardAndList();

  // Render loading/error states
  if (!boardId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="grey.100"
      >
        <Typography variant="h6" color="text.secondary">
          No board selected
        </Typography>
      </Box>
    );
  }

  if (boardLoading || activitiesLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="grey.100"
        gap={2}
      >
        <CircularProgress size={40} />
        <Typography variant="body1" color="text.secondary">
          Loading board...
        </Typography>
      </Box>
    );
  }

  if (boardError || activitiesError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="grey.100"
        gap={2}
      >
        <Typography variant="h6" color="error">
          Error loading board
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {boardError?.message ||
            activitiesError?.message ||
            "An unexpected error occurred"}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            queryClient.invalidateQueries({ queryKey: ["board", boardId] });
            queryClient.invalidateQueries({
              queryKey: ["boardActivities", boardId],
            });
          }}
        >
          Retry
        </Button>
      </Box>
    );
  }

  if (!board) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="grey.100"
        gap={2}
      >
        <Typography variant="h6" color="text.secondary">
          Board not found
        </Typography>
        <Typography variant="body2" color="text.secondary">
          The requested board could not be found
        </Typography>
      </Box>
    );
  }

  const listColors = [
    { id: "green", bg: "#4CAF50" },
    { id: "yellow", bg: "#FFC107" },
    { id: "orange", bg: "#FF9800" },
    { id: "red", bg: "#f44336" },
    { id: "purple", bg: "#9C27B0" },
    { id: "blue", bg: "#2196F3" },
    { id: "teal", bg: "#009688" },
    { id: "lime", bg: "#8BC34A" },
    { id: "pink", bg: "#E91E63" },
  ];

  const getVisibleTickets = (listId: string) => {
    const list = board?.columns?.find((col) => col.id === listId);
    if (!list) return [];
    
    const nonArchivedCards = list.cards.filter((card) => !card.archived);
    
    // Use cardOrder if available, otherwise fallback to existing cards array
    if (list.cardOrder) {
      return list.cardOrder
        .map(cardId => nonArchivedCards.find(card => card.id === cardId))
        .filter((card): card is Card => card !== undefined);
    }
    
    return nonArchivedCards;
  };

  const handleCreateList = async () => {
    if (!newListName.trim() || !boardId) return;

    try {
      await createList.mutateAsync({
        boardId,
        name: newListName.trim(),
      });
      setNewListName("");
      setIsAddingList(false);
    } catch (error) {
      console.error("Error creating list:", error);
    }
  };

  const handleCreateCard = async (listId: string) => {
    if (!newCardName.trim() || !boardId || isCreatingCard) return;

    try {
      setIsCreatingCard(true);
      await createCard.mutateAsync({
        boardId,
        listId,
        name: newCardName.trim(),
      });
      setNewCardName("");
      setIsAddingCard(null);
    } catch (error) {
      console.error("Error creating card:", error);
      setSnackbar({
        open: true,
        message: "Failed to create card",
        severity: "error",
      });
    } finally {
      setIsCreatingCard(false);
    }
  };

  const handleListMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    listId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedListId(listId);
  };

  const handleListMenuClose = () => {
    setAnchorEl(null);
    setSelectedListId(null);
  };

  const handleInlineEditSubmit = async (listId: string) => {
    if (!inlineEditName.trim() || !boardId) {
      setEditingListId(null);
      setInlineEditName("");
      return;
    }

    try {
      // Optimistically update the UI
      queryClient.setQueryData<Board>(["board", boardId], (oldBoard) => {
        if (!oldBoard) return oldBoard;
        return {
          ...oldBoard,
          columns: oldBoard.columns?.map((col) =>
            col.id === listId ? { ...col, name: inlineEditName.trim() } : col
          ),
        };
      });

      // Update both the board's columns and the list document
      await Promise.all([
        // Update the board's columns
        updateList.mutateAsync({
          boardId,
          listId,
          name: inlineEditName.trim(),
        }),
        // Update the list document directly
        updateDoc(doc(db, "boards", boardId, "lists", listId), {
          name: inlineEditName.trim(),
          updatedAt: new Date().toISOString(),
        }),
      ]);

      // Clear editing state
      setEditingListId(null);
      setInlineEditName("");
    } catch (error) {
      console.error("Error updating list:", error);
      // Revert optimistic update on error
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
      setEditingListId(null);
      setInlineEditName("");
    }
  };

  const handleConfirmDelete = async (targetListId: string | null) => {
    if (!boardId || !deleteListDialog.listId || !board) return;

    const listToDelete = board.columns?.find(
      (col) => col.id === deleteListDialog.listId
    );
    if (!listToDelete || !board.columns) return;

    try {
      if (targetListId && listToDelete.cards.length > 0) {
        // Get target list
        const targetList = board.columns.find((col) => col.id === targetListId);
        if (!targetList) return;

        // Move cards to target list
        const updatePromises = listToDelete.cards.map((card) =>
          updateCard.mutateAsync({
            boardId: boardId!,
            cardId: card.id,
            updates: { listId: targetListId },
          })
        );

        await Promise.all(updatePromises);
      }

      // Delete the list
      await deleteList.mutateAsync({
        boardId,
        listId: deleteListDialog.listId,
      });

      setDeleteListDialog({ open: false, listId: null });
      handleListMenuClose();

      setSnackbar({
        open: true,
        message: "List deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting list:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete list",
        severity: "error",
      });
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    if (!destination || !board?.columns) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    try {
      if (type === "list") {
        // Handle list reordering
        const newColumns = Array.from(board.columns);
        const [removed] = newColumns.splice(source.index, 1);
        newColumns.splice(destination.index, 0, removed);

        // Create new listOrder array
        const newListOrder = newColumns.map((col) => col.id);

        // Optimistic update
        queryClient.setQueryData<Board>(["board", boardId], (old) => ({
          ...old!,
          columns: newColumns,
          listOrder: newListOrder,
        }));

        // Update in backend
        await updateBoard.mutateAsync({
          boardId: boardId!,
          data: {
            columns: newColumns,
            listOrder: newListOrder,
          },
        });

        // Update the list documents with their new order
        await Promise.all(
          newColumns.map((col, index) =>
            updateDoc(doc(db, "boards", boardId!, "lists", col.id), {
              order: index,
              updatedAt: new Date().toISOString(),
            })
          )
        );
      } else {
        const sourceList = board.columns.find(
          (col) => col.id === source.droppableId
        );
        const destList = board.columns.find(
          (col) => col.id === destination.droppableId
        );

        if (!sourceList || !destList) return;

        try {
          // Get current card orders
          const sourceCardOrder = [...(sourceList.cardOrder || sourceList.cards.map(c => c.id))];
          const destCardOrder = [...(destList.cardOrder || destList.cards.map(c => c.id))];

          // Remove card from source order
          const cardIndex = sourceCardOrder.indexOf(draggableId);
          if (cardIndex !== -1) {
            sourceCardOrder.splice(cardIndex, 1);
          }

          // Add card to destination order
          if (source.droppableId === destination.droppableId) {
            // Same list - just reorder
            sourceCardOrder.splice(destination.index, 0, draggableId);
            
            // Update the single list
            await updateList.mutateAsync({
              boardId: boardId!,
              listId: source.droppableId,
              cardOrder: sourceCardOrder
            });
          } else {
            // Different lists - update both lists
            destCardOrder.splice(destination.index, 0, draggableId);
            
            // Update both lists
            await Promise.all([
              updateList.mutateAsync({
                boardId: boardId!,
                listId: source.droppableId,
                cardOrder: sourceCardOrder
              }),
              updateList.mutateAsync({
                boardId: boardId!,
                listId: destination.droppableId,
                cardOrder: destCardOrder
              })
            ]);

            // Update the card's listId
            await moveCard.mutateAsync({
              board: board,
              cardId: draggableId,
              newListId: destination.droppableId,
            });
          }

          // Optimistic update
          queryClient.setQueryData<Board>(["board", boardId], (old) => {
            if (!old?.columns) return old;
            return {
              ...old,
              columns: old.columns.map(col => {
                if (col.id === source.droppableId) {
                  return { ...col, cardOrder: sourceCardOrder };
                }
                if (col.id === destination.droppableId) {
                  return { ...col, cardOrder: destCardOrder };
                }
                return col;
              })
            };
          });

        } catch (error) {
          console.error("Error updating card order:", error);
          queryClient.invalidateQueries({ queryKey: ["board", boardId] });
          setSnackbar({
            open: true,
            message: "Failed to update card order",
            severity: "error"
          });
        }
      }
    } catch (error) {
      console.error("Error updating order:", error);
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
      setSnackbar({
        open: true,
        message: "Failed to update order",
        severity: "error",
      });
    }
  };

  const handleColorSelect = async (listId: string, color: string | null) => {
    if (!board) return;
    if (!color) return;

    try {
      await updateListColor.mutateAsync({
        boardId: board.id,
        listId,
        color,
      });
    } catch (error) {
      console.error("Error updating list color:", error);
    }
    setColorAnchorEl(null);
  };

  const handleBoardTitleSubmit = async () => {
    if (!editBoardTitle.trim() || !board) return;

    try {
      await updateBoard.mutateAsync({
        boardId: board.id,
        data: { name: editBoardTitle.trim() },
      });
      setIsEditingBoardTitle(false);
    } catch (error) {
      console.error("Error updating board title:", error);
    }
  };

  const handleArchiveCard = async (cardId: string, listId: string) => {
    try {
      await updateCard.mutateAsync({
        boardId: boardId!,
        cardId,
        updates: { archived: true },
      });

      setSnackbar({
        open: true,
        message: "Card archived successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error archiving card:", error);
      setSnackbar({
        open: true,
        message: "Failed to archive card",
        severity: "error",
      });
    }
  };

  const handleCopyCard = async (cardId: string, sourceListId: string) => {
    try {
      const sourceList = board?.columns?.find((col) => col.id === sourceListId);
      const cardToCopy = sourceList?.cards.find((card) => card.id === cardId);

      if (!cardToCopy) return;

      await createCard.mutateAsync({
        boardId: boardId!,
        listId: sourceListId,
        name: `${cardToCopy.title} (Copy)`,
        description: cardToCopy.description,
        assignee: cardToCopy.assignee,
        reporter: cardToCopy.reporter,
        additionalAssignees: cardToCopy.additionalAssignees,
        labels: cardToCopy.labels,
        dueDate: cardToCopy.dueDate,
        startDate: cardToCopy.startDate,
      });

      setSnackbar({
        open: true,
        message: "Card copied successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error copying card:", error);
      setSnackbar({
        open: true,
        message: "Failed to copy card",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleArchiveList = async () => {
    if (!boardId || !selectedListId) return;

    try {
      await archiveList.mutateAsync({
        boardId,
        listId: selectedListId,
      });
      setAnchorEl(null);
      setShowConfirmArchive(false);
    } catch (error) {
      console.error("Error archiving list:", error);
      setSnackbar({
        open: true,
        message: "Failed to archive list",
        severity: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        bgcolor: "grey.100",
        overflow: "hidden",
        marginLeft: 0,
        backgroundImage: board.backgroundUrl ? `url(${board.backgroundUrl})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        '&::before': board.backgroundUrl ? {
          content: '""',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          zIndex: 0,
          pointerEvents: 'none',
        } : {},
        '& > *': {
          position: 'relative',
          zIndex: 1,
        },
      }}
    >
      <FreeloBoardHeader
        board={board}
        // @ts-ignore
        users={users}
        isAdmin={isAdmin}
        isArchiveView={isArchiveView}
        isActivityView={isActivityView}
        editBoardTitle={editBoardTitle}
        isEditingBoardTitle={isEditingBoardTitle}
        onEditBoardTitle={setEditBoardTitle}
        onBoardTitleSubmit={handleBoardTitleSubmit}
        onSetIsEditingBoardTitle={setIsEditingBoardTitle}
        onViewChange={(view) => {
          setIsActivityView(view === "activity");
          setIsArchiveView(view === "archive");
        }}
        onOpenShareDialog={() => setOpenShareDialog(true)}
        onOpenSettings={() => setOpenSettings(true)}
      />

      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          minHeight: 0, // Important for proper scrolling
        }}
      >
        {/* Board Content */}
        {isArchiveView ? (
          <ArchiveList isArchiveView={isArchiveView} />
        ) : isActivityView ? (
          <ActivityList
            activities={activities || []}
            onClose={() => setIsActivityView(false)}
          />
        ) : (
          <DraggableCardArea
            board={board}
            boardId={boardId}
            isAddingCard={isAddingCard}
            newCardName={newCardName}
            isCreatingCard={isCreatingCard}
            editingListId={editingListId}
            inlineEditName={inlineEditName}
            onDragEnd={handleDragEnd}
            onListMenuOpen={handleListMenuOpen}
            onInlineEditSubmit={handleInlineEditSubmit}
            onSetInlineEditName={setInlineEditName}
            onSetEditingListId={setEditingListId}
            onSetIsAddingCard={setIsAddingCard}
            onSetNewCardName={setNewCardName}
            onCreateCard={handleCreateCard}
            onArchiveCard={handleArchiveCard}
            onCopyCard={handleCopyCard}
            onUpdateCard={async (cardId, updates) => {
              try {
                await updateCard.mutateAsync({
                  boardId: boardId!,
                  cardId,
                  updates,
                });
              } catch (error) {
                console.error("Error updating card:", error);
              }
            }}
            onSetIsAddingList={setIsAddingList}
            onSetNewListName={setNewListName}
            onCreateList={handleCreateList}
            isAddingList={isAddingList}
            newListName={newListName}
            getVisibleTickets={getVisibleTickets}
          />
        )}
      </Box>

      {/* List Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleListMenuClose}
      >
        <MenuItem
          onClick={() => {
            setColorAnchorEl(anchorEl);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ColorLensIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Pick Color</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setShowConfirmArchive(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive List</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setDeleteListDialog({
              open: true,
              listId: selectedListId,
            });
            setAnchorEl(null);
          }}
          sx={{ color: "error.main" }}
        >
          <ListItemIcon sx={{ color: "error.main" }}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete List</ListItemText>
        </MenuItem>
      </Menu>

      {/* Color Picker Popover */}
      <ListColorManager
        open={Boolean(colorAnchorEl)}
        anchorEl={colorAnchorEl}
        onClose={() => setColorAnchorEl(null)}
        onColorSelect={(color) =>
          selectedListId && handleColorSelect(selectedListId, color)
        }
        currentColor={
          board?.columns?.find((col) => col.id === selectedListId)?.color ||
          null
        }
      />

      {/* Share Dialog */}
      {isAdmin && (
        <ShareBoardDialog
          open={openShareDialog}
          onClose={() => setOpenShareDialog(false)}
          board={board}
          onUpdate={async (boardId, updates) => {
            try {
              await updateBoard.mutateAsync({
                boardId,
                data: updates,
              });
            } catch (error) {
              console.error("Error updating board:", error);
            }
          }}
        />
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Delete List Confirmation Dialog */}
      <DeleteListConfirmDialog
        open={deleteListDialog.open}
        onClose={() => setDeleteListDialog({ open: false, listId: null })}
        onConfirm={handleConfirmDelete}
        listToDelete={
          board?.columns?.find((col) => col.id === deleteListDialog.listId) ||
          null
        }
        availableLists={
          board?.columns?.filter((col) => col.id !== deleteListDialog.listId) ||
          []
        }
        hasCards={Boolean(
          board?.columns?.find((col) => col.id === deleteListDialog.listId)
            ?.cards.length
        )}
      />

      {/* Add confirmation dialog for archiving */}
      <ConfirmActionDialog
        open={showConfirmArchive}
        title="Archive List"
        message={`Are you sure you want to archive all cards in "${board?.columns?.find((col) => col.id === selectedListId)?.name || ""}"? You can find them later in the archived items.`}
        confirmText="Archive"
        confirmButtonColor="warning"
        onConfirm={handleArchiveList}
        onCancel={() => setShowConfirmArchive(false)}
      />

      {/* Render dialog if card is selected from URL */}
      {cardAndList && (
        <FreeloTicketDialog
          open={true}
          onClose={() => {
            navigate(`/freelo/${boardId}`, { replace: true });
            setSelectedCardId(null);
          }}
          card={cardAndList.card}
          boardId={boardId}
          onUpdate={async (cardId: string, updates: Partial<Card>) => {
            try {
              await updateCard.mutateAsync({
                boardId: boardId!,
                cardId,
                updates,
              });
            } catch (error) {
              console.error("Error updating card:", error);
            }
          }}
          onArchive={async () => {
            try {
              await updateCard.mutateAsync({
                boardId: boardId!,
                cardId: cardAndList.card.id,
                updates: { archived: true },
              });
              navigate(`/freelo/${boardId}`, { replace: true });
            } catch (error) {
              console.error("Error archiving card:", error);
            }
          }}
        />
      )}

      {/* Settings Dialog */}
      <BoardSettingsDialog
        open={openSettings}
        onClose={() => setOpenSettings(false)}
        board={board}
        onUpdateBoard={async (updates) => {
          try {
            await updateBoard.mutateAsync({
              boardId: board.id,
              data: updates,
            });
          } catch (error) {
            console.error('Error updating board:', error);
            setSnackbar({
              open: true,
              message: 'Failed to update board settings',
              severity: 'error',
            });
          }
        }}
      />
    </Box>
  );
};

export default FreeloBoard;
