import React, { useState } from 'react';
import {
  Box,
  Typography,
  Popover,
  TextField,
  IconButton,
  Stack,
  Chip,
  Button,
  Divider,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFreelo } from 'hooks/freelo/useFreelo';

interface LabelManagerProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  boardId: string;
  card: Card;
}

export const LabelManager: React.FC<LabelManagerProps> = ({
  open,
  anchorEl,
  onClose,
  boardId,
  card,
}) => {
  const { useBoard, createLabel, updateCardLabels, deleteLabel } = useFreelo();
  const { data: board } = useBoard(boardId);
  const [newLabelName, setNewLabelName] = useState('');
  const [selectedColor, setSelectedColor] = useState('#4CAF50');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleCreateLabel = async () => {
    if (!newLabelName.trim()) return;
    
    try {
      await createLabel.mutateAsync({
        boardId,
        name: newLabelName.trim(),
        color: selectedColor,
      });
      setNewLabelName('');
      setShowColorPicker(false);
    } catch (error) {
      console.error('Error creating label:', error);
    }
  };

  const handleLabelToggle = async (label: CardLabel) => {
    const currentLabels = card.labels || [];
    const isSelected = currentLabels.some(l => l.id === label.id);
    
    let updatedLabels: CardLabel[];
    if (isSelected) {
      updatedLabels = currentLabels.filter(l => l.id !== label.id);
    } else {
      updatedLabels = [...currentLabels, label];
    }

    try {
      await updateCardLabels.mutateAsync({
        boardId,
        cardId: card.id,
        labels: updatedLabels,
      });
    } catch (error) {
      console.error('Error updating labels:', error);
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          width: 300,
          ml: 1,
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="subtitle1">Labels</Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Stack spacing={1}>
          {board?.labels?.map(label => (
            <Chip
              key={label.id}
              label={label.name}
              deleteIcon={
                card.labels?.some(l => l.id === label.id) ? (
                  <CancelIcon sx={{ color: 'white', opacity: 0.8 }} />
                ) : undefined
              }
              onDelete={
                card.labels?.some(l => l.id === label.id) 
                  ? () => handleLabelToggle(label)
                  : undefined
              }
              sx={{
                bgcolor: label.color,
                color: 'white',
                width: '100%',
                justifyContent: 'space-between',
                height: 32,
                '& .MuiChip-label': {
                  flex: 1,
                },
                '& .MuiChip-deleteIcon': {
                  '&:hover': {
                    color: 'white',
                    opacity: 1,
                  },
                },
                '&:hover': {
                  bgcolor: label.color,
                  opacity: 0.9,
                },
              }}
              onClick={() => !card.labels?.some(l => l.id === label.id) && handleLabelToggle(label)}
            />
          ))}
        </Stack>

        <Divider sx={{ my: 2 }} />

        <Box>
          <TextField
            fullWidth
            size="small"
            placeholder="Create new label"
            value={newLabelName}
            onChange={(e) => setNewLabelName(e.target.value)}
          />
          <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
            <Button
              size="small"
              variant="contained"
              sx={{ 
                bgcolor: selectedColor,
                '&:hover': {
                  bgcolor: selectedColor,
                  opacity: 0.9,
                }
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              Color
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleCreateLabel}
              startIcon={<AddIcon />}
              disabled={!newLabelName.trim()}
            >
              Create
            </Button>
          </Box>
          {showColorPicker && (
            <Box sx={{ mt: 2 }}>
              <ChromePicker
                color={selectedColor}
                onChange={(color: any) => setSelectedColor(color.hex)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Popover>
  );
}; 