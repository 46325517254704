import React from "react";
import { Route } from "react-router-dom";
import type { CustomNavigation, CustomNavigationPageItem } from "router/types/customNavigation";

interface NavigationItemWithGrandBaby extends CustomNavigationPageItem {
  slugGrandBabyPattern?: string;
  slugGrandBabyComponent?: React.ReactNode;
}

export const mapToolpadToReactRouter = (
  pages: CustomNavigation,
  RouteComponent: React.ComponentType<any>
) => {
  return pages.map((page) => {
    if (page.kind === "header" || page.kind === "divider") {
      return null;
    }

    const typedPage = page as NavigationItemWithGrandBaby;

    if ("children" in typedPage && typedPage.children) {
      return typedPage.children.map((child) => {
        if (child.kind === "header" || child.kind === "divider") {
          return null;
        }

        const typedChild = child as NavigationItemWithGrandBaby;

        if (typedChild.component) {
          const basePath = `${typedPage.segment}/${typedChild.segment}`;
          return (
            <React.Fragment key={basePath}>
              <Route
                path={basePath}
                element={<RouteComponent>{typedChild.component}</RouteComponent>}
              />
              {typedChild.slugBabyPattern && typedChild.slugBabyComponent && (
                <React.Fragment>
                  <Route
                    path={`${basePath}/${typedChild.slugBabyPattern}`}
                    element={
                      <RouteComponent>{typedChild.slugBabyComponent}</RouteComponent>
                    }
                  />
                  {typedChild.slugGrandBabyPattern && typedChild.slugGrandBabyComponent && (
                    <Route
                      path={`${basePath}/${typedChild.slugBabyPattern}/${typedChild.slugGrandBabyPattern}`}
                      element={
                        <RouteComponent>{typedChild.slugGrandBabyComponent}</RouteComponent>
                      }
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }
        return null;
      });
    }

    if (typedPage.component) {
      return (
        <React.Fragment key={typedPage.segment}>
          <Route
            path={`/${typedPage.segment}`}
            element={<RouteComponent>{typedPage.component}</RouteComponent>}
          />
          {typedPage.slugBabyPattern && typedPage.slugBabyComponent && (
            <React.Fragment>
              <Route
                path={`/${typedPage.segment}/${typedPage.slugBabyPattern}`}
                element={
                  <RouteComponent>{typedPage.slugBabyComponent}</RouteComponent>
                }
              />
              {typedPage.slugGrandBabyPattern && typedPage.slugGrandBabyComponent && (
                <Route
                  path={`/${typedPage.segment}/${typedPage.slugBabyPattern}/${typedPage.slugGrandBabyPattern}`}
                  element={
                    <RouteComponent>{typedPage.slugGrandBabyComponent}</RouteComponent>
                  }
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }

    return null;
  });
};