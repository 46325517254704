import React, { useState, useEffect } from "react";
import { functionUrls, getHeaders } from "core";
import {
  UseEversignTemplateOutput,
  EversignDocumentBody,
  Signer,
  Field,
} from "core/functionUrls/types";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "hooks/auth/useAuth";
import { getClientProjects } from "core/projects";
import { loadAllClients } from "core/clients";
import { getProjectCSAs } from "core/csa";
import { PAGeneratorVerification } from "./components/PAGeneratorVerification";
// import { useLocalStorageState } from "@toolpad/core";
import { createEversignProjectAssignment } from "core/eversign";
import { getUserByEmail } from "core/users";
import { useManageUsers } from "hooks/admin/useManageUsers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DateTime } from "luxon";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import ViewFileDialog from 'components/ViewFileDialog';

const TEMPLATE_IDS = [
  {
    id: "0046fee9787e45a99876f14f133a11c1",
    name: "Project Assignment Form V1",
  },
];

type User = Stakeholder | Freelancer | UserInfoType;

const projectAssignmentFields: Field[] = [
  { identifier: "engineer_name", value: "" },
  { identifier: "effective_date", value: "" },
  { identifier: "conclusion_date", value: "" },
  { identifier: "project_name", value: "" },
  { identifier: "client_name", value: "" },
  { identifier: "billable_rate", value: "" },
  { identifier: "maximum_billable_hours", value: "" },
  { identifier: "maximum_dollar_budget", value: "" },
  { identifier: "scope_of_work", value: "" },
];

export interface DocumentState extends EversignDocumentBody {
  selectedClientId: string;
  selectedProjectId: string;
  activeCSAs: ConsultingServicesAgreement[];
  selectedCSA: string;
}

interface PreviousMonthAssignments {
  [engineerId: string]: ProjectAssignment[];
}

const ProjectAssignmentGenerator: React.FC = () => {
  const { userInfo } = useAuth();
  const createdBy = userInfo?.email || "";
  // const [stagedDocuments, setStagedDocuments] = useLocalStorageState<DocumentState[]>('stagedDocuments', []);
  const [stagedDocuments, setStagedDocuments] = useState<DocumentState[]>([]);
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const [freelancerUsers, setFreelancerUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  const [clients, setClients] = useState<Client[]>([]);
  const [projects, setProjects] = useState<{ [clientId: string]: Project[] }>(
    {}
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [previousMonthAssignments, setPreviousMonthAssignments] =
    useState<PreviousMonthAssignments>({});
  const [selectedAssignment, setSelectedAssignment] = useState<ProjectAssignment | null>(null);

  const { users, isLoading, isError } = useManageUsers();
  const { data: freelancers } = useAdminFreelancers();

  useEffect(() => {
    const fetchUsers = async () => {
      if (users) {
        setAdminUsers(users.filter((user) => user.user_type === "admin"));
        setFreelancerUsers(
          users.filter(
            (user) =>
              user.user_type === "freelancer" || user.user_type === "admin"
          )
        );
        console.log(
          "Admin users:",
          users.filter((user) => user.user_type === "admin")
        );
      }
    };
    fetchUsers();
  }, [users]);

  useEffect(() => {
    const fetchClients = async () => {
      const fetchedClients = await loadAllClients();
      setClients(fetchedClients);
    };
    fetchClients();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsByClient: { [clientId: string]: Project[] } = {};
      for (const client of clients) {
        const fetchedProjects = await getClientProjects(client.id);
        projectsByClient[client.id] = fetchedProjects;
      }
      setProjects(projectsByClient);
    };
    fetchProjects();
  }, [clients]);

  useEffect(() => {
    const fetchPreviousMonthAssignments = async () => {
      const lastMonth = DateTime.now().minus({ months: 1 });
      const startDate = lastMonth.startOf("month");
      const endDate = lastMonth.endOf("month");

      try {
        if (!freelancers) return;

        // Group assignments by engineer with proper typing
        const groupedAssignments = freelancers.reduce<PreviousMonthAssignments>(
          (acc, freelancer) => {
            if (freelancer.projectAssignments?.length) {
              acc[freelancer.email] = freelancer.projectAssignments.filter(
                (pa: ProjectAssignment) => {
                  const paDate = DateTime.fromISO(pa.startDate || "");
                  return paDate >= startDate && paDate <= endDate;
                }
              );
            }
            return acc;
          },
          {}
        );

        setPreviousMonthAssignments(groupedAssignments);
      } catch (error) {
        console.error("Error fetching previous month assignments:", error);
      }
    };

    fetchPreviousMonthAssignments();
  }, [freelancers]);

  const handleAddDocument = () => {
    setStagedDocuments([
      ...stagedDocuments,
      {
        ...{
          template_id: TEMPLATE_IDS[0].id,
          title: "Project Assignment Form",
          message: "Please review and sign the Project Assignment Form.",
          redirect: "https://portal.freetech.co/sign-agreements",
          redirect_decline: "https://portal.freetech.co/sign-agreements",
          signers: [
            {
              name: "",
              email: "",
              deliver_email: 0,
              role: "Adam Siwiec",
              order: 1,
              language: "en",
            },
            {
              name: "",
              email: "",
              deliver_email: 0,
              role: "Engineer",
              order: 2,
              language: "en",
            },
          ],
          fields: projectAssignmentFields,
          sandbox: true,
          embedded_signing_enabled: 1,
          recipients: [],
        },
        selectedClientId: "",
        selectedProjectId: "",
        activeCSAs: [],
        selectedCSA: "",
        sandbox: true,
      },
    ]);
  };

  const handleRemoveDocument = (index: number) => {
    setStagedDocuments(stagedDocuments.filter((_, i) => i !== index));
  };

  const handleChange = (index: number, field: string, value: string) => {
    console.log("handleChange before update:", {
      index,
      field,
      value,
      currentFields: stagedDocuments[index].fields,
    });

    setStagedDocuments(
      stagedDocuments.map((doc, i) => {
        if (i === index) {
          const updatedFields = doc.fields.map((f) =>
            f.identifier === field ? { ...f, value } : f
          );
          
          return {
            ...doc,
            fields: updatedFields,
          };
        }
        return doc;
      })
    );
  };

  const handleSignerChange = (
    docIndex: number,
    signerIndex: number,
    field: keyof Signer,
    value: string
  ) => {
    setStagedDocuments((prevDocs) =>
      prevDocs.map((doc, i) => {
        if (i === docIndex) {
          const newSigners = [...doc.signers];
          newSigners[signerIndex] = {
            ...newSigners[signerIndex],
            [field]: value,
          };

          // If this is the freelancer signer (index 1), also update the engineer_name field
          if (signerIndex === 1 && field === "email") {
            const freelancer = freelancers?.find((f) => f.email === value);
            if (freelancer) {
              const newFields = doc.fields.map((f) =>
                f.identifier === "engineer_name"
                  ? {
                      ...f,
                      value: `${freelancer.firstName} ${freelancer.lastName}`,
                    }
                  : f
              );
              return {
                ...doc,
                signers: newSigners,
                fields: newFields,
              };
            }
          }
          return { ...doc, signers: newSigners };
        }
        return doc;
      })
    );
  };

  const handleClientChange = async (clientId: string, docIndex: number) => {
    const selectedClient = clients.find((c) => c.id === clientId);
    if (selectedClient) {
      setStagedDocuments(
        stagedDocuments.map((doc, i) => {
          if (i === docIndex) {
            return {
              ...doc,
              selectedClientId: clientId,
              selectedProjectId: "",
              fields: doc.fields.map((f) =>
                f.identifier === "client_name"
                  ? { ...f, value: selectedClient.companyName }
                  : f.identifier === "project_name"
                    ? { ...f, value: "" }
                    : f
              ),
              activeCSAs: [],
              selectedCSA: "",
            };
          }
          return doc;
        })
      );
    }
  };

  const handleProjectChange = async (projectId: string, docIndex: number) => {
    const doc = stagedDocuments[docIndex];
    const selectedProject = projects[doc.selectedClientId]?.find(
      (p) => p.id === projectId
    );
    if (selectedProject) {
      const allCSAs = await getProjectCSAs(doc.selectedClientId, projectId);
      const activeCSAs = allCSAs.filter((csa) => csa.status === "active");
      setStagedDocuments(
        stagedDocuments.map((d, i) => {
          if (i === docIndex) {
            return {
              ...d,
              selectedProjectId: projectId,
              fields: d.fields.map((f) =>
                f.identifier === "project_name"
                  ? { ...f, value: selectedProject.name }
                  : f
              ),
              activeCSAs,
              selectedCSA: activeCSAs.length === 1 ? activeCSAs[0].id : "",
            };
          }
          return d;
        })
      );
    }
  };

  const handleCSAChange = (csaId: string, docIndex: number) => {
    setStagedDocuments(
      stagedDocuments.map((doc, i) => {
        if (i === docIndex) {
          return { ...doc, selectedCSA: csaId };
        }
        return doc;
      })
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
  };

  const handleConfirmGenerate = async () => {
    setConfirmDialogOpen(false);
    setLoading(true);

    try {
      const { url, method } = functionUrls.eversign.useEversignTemplate;
      
      const results = await Promise.all(
        stagedDocuments.map(async (doc) => {
          const generateEversignDocumentBody = JSON.stringify({
            created_by: createdBy,
            document_data: {
              ...doc,
              sandbox: doc.sandbox ? 1 : 0,
            },
            clientId: doc.selectedClientId,
            projectId: doc.selectedProjectId,
            csaId: doc.selectedCSA,
            freelancerId: doc.signers[1]?.email,
            reporterId: doc.signers[0]?.email,
            sandbox: doc.sandbox,
          });

          console.log(
            "generateEversignDocumentBody",
            generateEversignDocumentBody
          );

          const response = await fetch(url, {
            method,
            headers: getHeaders(),
            body: generateEversignDocumentBody,
          });

          const eversignResponse =
            (await response.json()) as UseEversignTemplateOutput;

          

          const billableRate =
            parseFloat(
              doc.fields.find((f) => f.identifier === "billable_rate")?.value ||
                "0"
            ) || 0;

          const budgetedHours =
            parseFloat(
              doc.fields.find((f) => f.identifier === "maximum_billable_hours")
                ?.value || "0"
            ) || 0;

          const budgetedDollarAmount =
            parseFloat((billableRate * budgetedHours).toFixed(2)) || 0;

          const pa: ProjectAssignment = {
            id: "",
            engineerId: doc.signers[1]?.email || "",
            clientId: doc.selectedClientId || "",
            projectId: doc.selectedProjectId || "",
            projectName:
              doc.fields.find((f) => f.identifier === "project_name")?.value ||
              "",
            csaId: doc.selectedCSA || "",
            csaName:
              doc.activeCSAs.find((csa) => csa.id === doc.selectedCSA)?.name ||
              "",
            billableRate,
            budgetedHours,
            budgetedDollarAmount,
            utilizedHours: 0,
            amountPaid: 0,
            paymentDate: "",
            paymentMethod: "",
            status: "signing",
            startDate:
              doc.fields.find((f) => f.identifier === "effective_date")
                ?.value || null,
            endDate:
              doc.fields.find((f) => f.identifier === "conclusion_date")
                ?.value || null,
            fileUrl: "",
            performanceReviews: [],
            eversignDocumentHash: eversignResponse?.document_hash || "",
            eversignFreelancerDocumentEmbedUrl:
              eversignResponse?.signers[1]?.embedded_signing_url || "",
            eversignAdminDocumentEmbedUrl:
              eversignResponse?.signers[0]?.embedded_signing_url || "",
            everSignDocumentStatus: "awaiting_admin_signature",
          };

          const engineer = await getUserByEmail(doc.signers[1]?.email || "");

          if (!engineer) {
            throw new Error("Engineer not found");
          }

          const newPA = await createEversignProjectAssignment(
            engineer.id,
            doc.selectedClientId,
            doc.selectedProjectId,
            pa,
            doc.selectedCSA
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          return;
        })
      );

      setSnackbar({
        open: true,
        message: `Successfully generated ${results.length} project assignment(s)`,
        severity: "success",
      });
      setStagedDocuments([]);
    } catch (error) {
      console.error("Error:", error);
      setSnackbar({
        open: true,
        message: "Error generating project assignments",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSandboxChange = (docIndex: number, checked: boolean) => {
    setStagedDocuments(
      stagedDocuments.map((doc, i) => {
        if (i === docIndex) {
          return { ...doc, sandbox: checked };
        }
        return doc;
      })
    );
  };

  const handleCopyPreviousAssignment = (assignment: ProjectAssignment) => {
    setStagedDocuments([
      ...stagedDocuments,
      {
        template_id: TEMPLATE_IDS[0].id,
        title: "Project Assignment Form",
        message: "Please review and sign the Project Assignment Form.",
        redirect: "https://portal.freetech.co/sign-agreements",
        redirect_decline: "https://portal.freetech.co/sign-agreements",
        signers: [
          {
            name: "",
            email: "",
            deliver_email: 0,
            role: "Adam Siwiec",
            order: 1,
            language: "en",
          },
          {
            name: "",
            email: "",
            deliver_email: 0,
            role: "Engineer",
            order: 2,
            language: "en",
          },
        ],
        fields: projectAssignmentFields.map(field => {
          switch (field.identifier) {
            case 'effective_date':
              return { ...field, value: assignment.startDate || '' };
            case 'conclusion_date':
              return { ...field, value: assignment.endDate || '' };
            case 'billable_rate':
              return { ...field, value: assignment.billableRate?.toString() || '' };
            case 'maximum_billable_hours':
              return { ...field, value: assignment.budgetedHours?.toString() || '' };
            case 'scope_of_work':
              return { ...field, value: assignment.scopeOfWork || '' };
            default:
              return field;
          }
        }),
        sandbox: true,
        embedded_signing_enabled: 1,
        recipients: [],
        selectedClientId: "",
        selectedProjectId: "",
        activeCSAs: [],
        selectedCSA: "",
      },
    ]);
  };

  const renderCSAHint = (doc: DocumentState) => {
    if (!doc.selectedClientId || !doc.selectedProjectId) {
      return null;
    }

    if (doc.activeCSAs.length === 0) {
      return (
        <Typography variant="body2" color="error">
          No CSAs found for this project, please contact Adam
        </Typography>
      );
    }

    if (doc.activeCSAs.length === 1) {
      const csa = doc.activeCSAs[0];
      return (
        <Typography variant="body2" color="success.main">
          Active CSA Detected: {csa.name}
        </Typography>
      );
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2" color="warning.main" sx={{ mr: 1 }}>
          Multiple CSAs detected, please pick one:
        </Typography>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <Select
            value={doc.selectedCSA}
            onChange={(e) =>
              handleCSAChange(
                e.target.value as string,
                stagedDocuments.indexOf(doc)
              )
            }
            displayEmpty
            variant="outlined"
          >
            <MenuItem value="">
              <em>Select a CSA</em>
            </MenuItem>
            {doc.activeCSAs.map((csa) => (
              <MenuItem key={csa.id} value={csa.id}>
                {csa.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const renderEditView = (doc: DocumentState, docIndex: number) => {
    const calculateMaxDollarBudget = () => {
      const rate = parseFloat(
        doc.fields.find((f) => f.identifier === "billable_rate")?.value || "0"
      );
      const hours = parseFloat(
        doc.fields.find((f) => f.identifier === "maximum_billable_hours")
          ?.value || "0"
      );
      return (rate * hours).toFixed(2);
    };

    const maxDollarBudget = calculateMaxDollarBudget();

    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Maximum Dollar Budget: ${maxDollarBudget}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={doc.sandbox}
                      onChange={(e) =>
                        handleSandboxChange(docIndex, e.target.checked)
                      }
                    />
                  }
                  label="Sandbox Mode"
                />
              </Box>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  const duplicatedDoc = JSON.parse(JSON.stringify(doc));
                  setStagedDocuments([...stagedDocuments, duplicatedDoc]);
                }}
                color="primary"
                title="Duplicate Assignment"
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Client</InputLabel>
              <Select
                value={doc.selectedClientId || ""}
                onChange={(e) =>
                  handleClientChange(e.target.value as string, docIndex)
                }
                label="Client"
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Project</InputLabel>
              <Select
                value={doc.selectedProjectId || ""}
                onChange={(e) =>
                  handleProjectChange(e.target.value as string, docIndex)
                }
                label="Project"
                disabled={!doc.selectedClientId}
              >
                {projects[doc.selectedClientId]?.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Effective Date"
              type="date"
              name="effective_date"
              value={
                doc.fields.find((f) => f.identifier === "effective_date")
                  ?.value || ""
              }
              onChange={(e) =>
                handleChange(docIndex, "effective_date", e.target.value)
              }
              required
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Conclusion Date"
              type="date"
              name="conclusion_date"
              value={
                doc.fields.find((f) => f.identifier === "conclusion_date")
                  ?.value || ""
              }
              onChange={(e) =>
                handleChange(docIndex, "conclusion_date", e.target.value)
              }
              required
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Billable Rate"
              name="billable_rate"
              type="number"
              value={
                doc.fields.find((f) => f.identifier === "billable_rate")
                  ?.value || ""
              }
              onChange={(e) =>
                handleChange(docIndex, "billable_rate", e.target.value)
              }
              required
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Maximum Billable Hours"
              name="maximum_billable_hours"
              type="number"
              value={
                doc.fields.find(
                  (f) => f.identifier === "maximum_billable_hours"
                )?.value || ""
              }
              onChange={(e) =>
                handleChange(docIndex, "maximum_billable_hours", e.target.value)
              }
              required
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Admin</InputLabel>
              <Select
                value={doc.signers[0]?.email || ""}
                onChange={(e) => {
                  const selectedUser = adminUsers.find(
                    (user) => user.email === e.target.value
                  );
                  if (selectedUser) {
                    handleSignerChange(
                      docIndex,
                      0,
                      "email",
                      selectedUser.email
                    );
                    handleSignerChange(
                      docIndex,
                      0,
                      "name",
                      `${selectedUser.firstName} ${selectedUser.lastName}`
                    );
                  }
                }}
                required
              >
                {adminUsers.map((user) => (
                  <MenuItem key={user.id} value={user.email}>
                    {`${user.firstName} ${user.lastName} (${user.email})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Freelancer</InputLabel>
              <Select
                value={doc.signers[1]?.email || ""}
                onChange={(e) => {
                  const selectedUser = freelancerUsers.find(
                    (user) => user.email === e.target.value
                  );
                  if (selectedUser) {
                    
                    const fullName = `${selectedUser.firstName} ${selectedUser.lastName}`;

                    // First update the engineer_name field
                    handleChange(docIndex, "engineer_name", fullName);

                    // Then update signer information
                    handleSignerChange(
                      docIndex,
                      1,
                      "email",
                      selectedUser.email
                    );
                    handleSignerChange(docIndex, 1, "name", fullName);

                    // Verify the update
                    console.log(
                      "Current document after update:",
                      stagedDocuments[docIndex]
                    );
                  }
                }}
                required
                disabled={!doc.signers[0]?.email}
              >
                <MenuItem value="">
                  <em>Select Freelancer</em>
                </MenuItem>
                {freelancerUsers
                  .filter((user) => user.email !== doc.signers[0]?.email)
                  .map((user) => (
                    <MenuItem key={user.id} value={user.email}>
                      {`${user.firstName} ${user.lastName} (${user.email})`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Scope of Work"
              name="scope_of_work"
              multiline
              rows={4}
              value={
                doc.fields.find((f) => f.identifier === "scope_of_work")
                  ?.value || ""
              }
              onChange={(e) =>
                handleChange(docIndex, "scope_of_work", e.target.value)
              }
              required
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Project Assignment Generator
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Previous Month's Assignments
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(previousMonthAssignments).flatMap(
              ([engineerId, assignments]) =>
                assignments.map((assignment) => {
                  const freelancer = freelancers?.find(
                    (f) => f.email === engineerId
                  );
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={assignment.id}>
                      <Card variant="outlined" sx={{ height: "100%" }}>
                        <CardContent sx={{ p: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              mb: 1,
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                            >
                              {freelancer
                                ? `${freelancer.firstName} ${freelancer.lastName}`
                                : engineerId}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <Button
                                size="small"
                                onClick={() => setSelectedAssignment(assignment)}
                              >
                                View
                              </Button>
                              <Button
                                size="small"
                                startIcon={<ContentCopyIcon />}
                                onClick={() =>
                                  handleCopyPreviousAssignment(assignment)
                                }
                                sx={{ minWidth: 0, p: 0.5 }}
                              >
                                Copy
                              </Button>
                            </Box>
                          </Box>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            noWrap
                          >
                            {assignment.projectName}
                          </Typography>
                          <Box sx={{ display: "flex", flexDirection: 'column', gap: 0.5, mt: 1 }}>
                            <Typography variant="caption" color="text.secondary">
                              Start: {assignment.startDate ? 
                                DateTime.fromISO(assignment.startDate).toFormat('dd LLL yyyy') : 
                                'Not set'}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              End: {assignment.endDate ? 
                                DateTime.fromISO(assignment.endDate).toFormat('dd LLL yyyy') : 
                                'Not set'}
                            </Typography>
                            <Box sx={{ display: "flex", gap: 1, mt: 0.5 }}>
                              <Typography variant="body2" color="text.secondary">
                                ${assignment.billableRate || 0}/hr
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                • {assignment.budgetedHours || 0}h
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
            )}
          </Grid>
        </Box>
        <Card sx={{ mb: 3, cursor: "pointer" }} onClick={handleAddDocument}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
            }}
          >
            <Typography variant="h6">
              <AddIcon sx={{ verticalAlign: "middle", mr: 1 }} />
              Generate New Project Assignment
            </Typography>
          </CardContent>
        </Card>
        <form onSubmit={handleSubmit}>
          {stagedDocuments.map((doc, docIndex) => (
            <Card key={docIndex} sx={{ mb: 3 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">
                    Project Assignment {docIndex + 1}
                  </Typography>
                  {renderCSAHint(doc)}
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                      onClick={() => handleRemoveDocument(docIndex)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                {renderEditView(doc, docIndex)}
              </CardContent>
            </Card>
          ))}
          {stagedDocuments.length > 0 && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? "Submitting..." : "Generate Assignments"}
            </Button>
          )}
        </form>
      </Box>
      <PAGeneratorVerification
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmGenerate}
        documents={stagedDocuments}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {selectedAssignment && (
        <ViewFileDialog
          open={!!selectedAssignment}
          onClose={() => setSelectedAssignment(null)}
          fileUrl={selectedAssignment.fileUrl || ''}
          fileName={`${selectedAssignment.projectName} - Project Assignment`}
        />
      )}
    </>
  );
};

export default ProjectAssignmentGenerator;
