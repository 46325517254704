import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useManageUsers } from 'hooks/admin';

interface ShareBoardDialogProps {
  open: boolean;
  onClose: () => void;
  board: Board;
  onUpdate: (boardId: string, updates: Partial<Board>) => Promise<void>;
}

const ShareBoardDialog: React.FC<ShareBoardDialogProps> = ({
  open,
  onClose,
  board,
  onUpdate,
}) => {
  const { users } = useManageUsers();
  const [selectedUsers, setSelectedUsers] = useState<UserInfoType[]>([]);
  const [accessType, setAccessType] = useState<'read' | 'write'>('read');

  useEffect(() => {
    if (open && users) {
      // When dialog opens, set selected users based on current access type
      const relevantUsers = accessType === 'read' 
        ? users.filter(user => board.readUserIds?.includes(user.id))
        : users.filter(user => board.writeUserIds?.includes(user.id));
      setSelectedUsers(relevantUsers);
    }
  }, [open, users, board, accessType]);

  const handleSave = async () => {
    try {
      const selectedUserIds = selectedUsers.map(u => u.id);
      
      // Prepare updates based on access type
      const updates: Partial<Board> = {
        readUserIds: accessType === 'read' 
          ? selectedUserIds 
          : (board.readUserIds || []),
        writeUserIds: accessType === 'write'
          ? selectedUserIds
          : (board.writeUserIds || []),
      };

      await onUpdate(board.id, updates);
      onClose();
    } catch (error) {
      console.error('Error updating board permissions:', error);
    }
  };

  const handleAccessTypeChange = (newAccessType: 'read' | 'write') => {
    setAccessType(newAccessType);
    // Update selected users based on new access type
    const relevantUsers = newAccessType === 'read'
      ? users?.filter(user => board.readUserIds?.includes(user.id)) || []
      : users?.filter(user => board.writeUserIds?.includes(user.id)) || [];
    setSelectedUsers(relevantUsers);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Share Board</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Access Type</FormLabel>
            <RadioGroup
              row
              value={accessType}
              onChange={(e) => handleAccessTypeChange(e.target.value as 'read' | 'write')}
            >
              <FormControlLabel value="read" control={<Radio />} label="Read" />
              <FormControlLabel value="write" control={<Radio />} label="Write" />
            </RadioGroup>
          </FormControl>

          <Autocomplete
            multiple
            options={users || []}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
            value={selectedUsers}
            onChange={(_, newValue) => setSelectedUsers(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Select Users for ${accessType} access`}
                placeholder="Search users..."
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  
                  label={`${option.firstName} ${option.lastName}`}
                  {...getTagProps({ index })}
                />
              ))
            }
          />

          <Typography variant="body2" color="text.secondary">
            Selected users will have {accessType} access to this board.
            {accessType === 'read' && board.writeUserIds?.length 
              ? ` (${board.writeUserIds.length} users have write access)`
              : accessType === 'write' && board.readUserIds?.length 
              ? ` (${board.readUserIds.length} users have read access)`
              : ''
            }
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareBoardDialog; 