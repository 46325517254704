import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  alpha,
} from "@mui/material";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { DatePicker } from "@mui/x-date-pickers-pro";
import AddIcon from "@mui/icons-material/Add";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import TimerIcon from "@mui/icons-material/Timer";
import EditIcon from "@mui/icons-material/EditCalendar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTimesheet } from "hooks/shared/timesheet/useTimesheet";
import { useAuth } from "hooks/auth/useAuth";
import { DateTime } from "luxon";
import {
  calculateDuration,
  formatDuration,
} from "hooks/shared/timesheet/utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocalStorageState } from "@toolpad/core";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import MarkdownEditor from "components/MarkdownEditor/MarkdownEditor";

interface TimeTrackerInputProps {
  activeProjectAssignments: ProjectAssignment[];
}

interface WarningDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

interface MinimumTimeDialogProps {
  open: boolean;
  onClose: () => void;
  duration: string;
}

const NonBillableWarningDialog: React.FC<WarningDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: "warning.main",
        }}
      >
        <WarningAmberIcon color="warning" />
        Non-Billable Time Entry
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You are about to track time without selecting a project assignment.
          Please note:
        </Typography>
        <Box sx={{ pl: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            • These hours will be tracked as non-billable learning/additional
            tasks
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            • They will not be considered billable under any project
          </Typography>
          <Typography variant="body2">
            • This cannot be changed later without administrative approval
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="warning"
          startIcon={<AddIcon />}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MinimumTimeDialog: React.FC<MinimumTimeDialogProps> = ({
  open,
  onClose,
  duration,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: "error.main",
        }}
      >
        <WarningAmberIcon color="error" />
        Invalid Time Duration
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          The time entry duration ({duration}) is less than one minute.
        </Typography>
        <Typography variant="body2">
          Please ensure that time entries are at least one minute long.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TimeTrackerInput: React.FC<TimeTrackerInputProps> = ({
  activeProjectAssignments,
}) => {
  const [view, setView] = useLocalStorageState("timeTrackingMethod", null);
  const [newEntry, setNewEntry] = useState<Partial<Timesheet>>({
    projectAssignmentId: "",
    description: "",
    longDescription: "",
    date: DateTime.now().toISODate(),
    startTime: DateTime.now().toISO(),
    endTime: DateTime.now().toISO(),
    billable: true,
    submitted: false,
  });
  const [isTimedEntry, setIsTimedEntry] = useState(view === "timer");
  const [expandedNotes, setExpandedNotes] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);
  const [manualDuration, setManualDuration] = useState<string>("00:00:00");
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [showMinTimeDialog, setShowMinTimeDialog] = useState(false);

  const { userInfo } = useAuth();
  const userId = userInfo?.id || "";
  const {
    getTimesheets,
    createTimesheet,
    timesheet,
    startTimer,
    stopTimer,
    timerValue,
    isTimerActive,
    isSaving,
    updateTimesheet,
  } = useTimesheet(userId);

  const { refetch: refetchTimesheets } = getTimesheets({ userId });

  const updateManualDuration = useCallback(() => {
    if (newEntry.startTime && newEntry.endTime) {
      const durationInSeconds = calculateDuration(
        newEntry.startTime,
        newEntry.endTime
      );
      setManualDuration(formatDuration(durationInSeconds));
    }
  }, [newEntry.startTime, newEntry.endTime]);

  useEffect(() => {
    if (!isTimedEntry) {
      updateManualDuration();
    }
  }, [isTimedEntry, updateManualDuration]);

  const handleInputChange = (field: keyof Timesheet, value: any) => {
    setNewEntry((prev) => {
      const updatedEntry = { ...prev, [field]: value };

      if (field === "date") {
        const newDate = DateTime.fromISO(value);
        const currentStartTime = DateTime.fromISO(prev.startTime || "");
        const currentEndTime = DateTime.fromISO(prev.endTime || "");

        const newStartTime = newDate
          .set({
            hour: currentStartTime.hour,
            minute: currentStartTime.minute,
            second: currentStartTime.second,
          })
          .toISO();

        const newEndTime = newDate
          .set({
            hour: currentEndTime.hour,
            minute: currentEndTime.minute,
            second: currentEndTime.second,
          })
          .toISO();

        if (newStartTime) updatedEntry.startTime = newStartTime;
        if (newEndTime) updatedEntry.endTime = newEndTime;
      }

      // Update timesheet if it exists and description/longDescription changes
      if (
        (field === "description" || field === "longDescription") &&
        timesheet?.id
      ) {
        updateTimesheet.mutateAsync({
          id: timesheet.id,
          description: field === "description" ? value : prev.description,
          longDescription:
            field === "longDescription" ? value : prev.longDescription,
        });
      }

      return updatedEntry;
    });

    if (field === "startTime" || field === "endTime" || field === "date") {
      updateManualDuration();
    }
  };

  const resetEntryToCurrentTime = useCallback(() => {
    const now = DateTime.now();
    setNewEntry((prev) => ({
      ...prev,
      description: "",
      longDescription: "",
      date: now.toISODate(),
      startTime: now.toISO(),
      endTime: now.toISO(),
      billable: true,
      submitted: false,
    }));
    setManualDuration("00:00:00");
  }, []);

  const isValidDuration = (startTime: string, endTime: string): boolean => {
    const durationInSeconds = calculateDuration(startTime, endTime);
    return durationInSeconds >= 60; // 60 seconds = 1 minute
  };

  const handleAddEntry = async () => {
    if (!newEntry.startTime || !newEntry.endTime || !newEntry.date) {
      setError("Please fill in all required fields");
      setShowError(true);
      return;
    }

    if (!isValidDuration(newEntry.startTime, newEntry.endTime)) {
      setShowMinTimeDialog(true);
      return;
    }

    try {
      const duration = calculateDuration(newEntry.startTime, newEntry.endTime);
      await createTimesheet.mutateAsync({
        ...newEntry,
        projectAssignmentId: newEntry.projectAssignmentId || undefined,
        userId: userInfo!.id,
        duration,
        billable: newEntry.projectAssignmentId ? newEntry.billable : false,
        submitted: false,
        description: newEntry.description || "",
        startTime: newEntry.startTime,
        endTime: newEntry.endTime,
        clientId: selectedClient || undefined,
        projectId: selectedProject || undefined,
      } as Omit<Timesheet, "id">);

      resetEntryToCurrentTime();
      refetchTimesheets();
      clearError();
    } catch (error) {
      console.error("Failed to add timesheet entry:", error);
      setError("Failed to add timesheet entry. Please try again.");
      setShowError(true);
    }
  };

  const handleTimerOrManualEntryChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null && newView !== view) {
      setIsTimedEntry(newView === "timer");
      if (isTimerActive) {
        handleStopTimer();
      }
      setView(newView);
    }
  };

  const handleStartTimer = async () => {
    if (!newEntry.projectAssignmentId) {
      setError("Please select a project assignment before starting the timer.");
      setShowError(true);
      return;
    }

    try {
      await startTimer(newEntry as Omit<Timesheet, "id">);
      clearError();
    } catch (error) {
      console.error("Error starting timer:", error);
      setError("Failed to start timer. Please try again.");
      setShowError(true);
    }
  };

  const handleStopTimer = async () => {
    try {
      const currentDuration = calculateDuration(
        timesheet?.startTime || "",
        DateTime.now().toISO()
      );

      if (currentDuration < 60) {
        setShowMinTimeDialog(true);
        return;
      }

      const result = await stopTimer();
      if (result) {
        refetchTimesheets();

        updateTimesheet.mutateAsync({
          id: timesheet?.id,
          description: newEntry.description,
        });

        resetEntryToCurrentTime();
      }
    } catch (error) {
      console.error(error);
      setError("Error stopping timer or adding time entry");
      setShowError(true);
    }
  };

  const formatTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
  };

  const inputHeight = 56; // Define a consistent height for all inputs

  const handleProjectAssignmentChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;

    if (value) {
      const selectedAssignment = activeProjectAssignments.find(
        (assignment) => assignment.id === value
      );

      if (selectedAssignment) {
        setSelectedClient(selectedAssignment.clientId);
        setSelectedProject(selectedAssignment.projectId);

        setNewEntry((prev) => ({
          ...prev,
          projectAssignmentId: value,
          clientId: selectedAssignment.clientId,
          projectId: selectedAssignment.projectId,
          billable: true,
        }));
      }
    } else {
      setNewEntry((prev) => ({
        ...prev,
        projectAssignmentId: "",
        billable: false,
        clientId: selectedClient || undefined,
        projectId: selectedProject || undefined,
      }));
    }

    clearError();
  };

  const clearError = () => {
    setError(null);
    setShowError(false);
  };

  useEffect(() => {}, [isTimerActive]);

  const theme = useTheme();

  // Create memoized lists of unique clients and projects
  const clients = useMemo(() => {
    const uniqueClients = new Map();
    activeProjectAssignments.forEach((assignment) => {
      if (!uniqueClients.has(assignment.clientId)) {
        uniqueClients.set(assignment.clientId, {
          id: assignment.clientId,
          name: assignment.clientName,
        });
      }
    });
    return Array.from(uniqueClients.values());
  }, [activeProjectAssignments]);

  const filteredProjects = useMemo(() => {
    const uniqueProjects = new Map();
    activeProjectAssignments
      .filter((assignment) => assignment.clientId === selectedClient)
      .forEach((assignment) => {
        if (!uniqueProjects.has(assignment.projectId)) {
          uniqueProjects.set(assignment.projectId, {
            id: assignment.projectId,
            name: assignment.projectName,
          });
        }
      });
    return Array.from(uniqueProjects.values());
  }, [activeProjectAssignments, selectedClient]);

  const filteredAssignments = useMemo(() => {
    if (!selectedClient && !selectedProject) {
      return activeProjectAssignments; // Show all assignments if nothing selected
    }
    if (selectedClient && !selectedProject) {
      return activeProjectAssignments.filter(
        (assignment) => assignment.clientId === selectedClient
      );
    }
    return activeProjectAssignments.filter(
      (assignment) =>
        assignment.clientId === selectedClient &&
        assignment.projectId === selectedProject
    );
  }, [activeProjectAssignments, selectedClient, selectedProject]);

  // Update handlers
  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    setSelectedProject("");
    setNewEntry((prev) => ({
      ...prev,
      projectAssignmentId: "",
      clientId: clientId || undefined,
      projectId: undefined,
      billable: false,
    }));
  };

  const handleProjectChange = (event: SelectChangeEvent<string>) => {
    const projectId = event.target.value;
    setSelectedProject(projectId);
    setNewEntry((prev) => ({
      ...prev,
      projectAssignmentId: "",
      projectId: projectId || undefined,
      billable: false,
    }));
  };

  const handleWarningConfirm = () => {
    setShowWarningDialog(false);
    setNewEntry((prev) => ({
      ...prev,
      billable: false,
    }));

    if (isTimedEntry) {
      isTimerActive ? handleStopTimer() : handleStartTimer();
    } else {
      handleAddEntry();
    }
  };

  const isActionButtonDisabled = useMemo(() => {
    if (isSaving) return true;
    if (!selectedClient || !selectedProject) return true;
    return false;
  }, [isSaving, selectedClient, selectedProject]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 1, sm: 2 },
          mb: 2,
          position: "sticky",
          top: { xs: 0, sm: 80 },
          zIndex: 1000,
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          {/* Selection Controls Row */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              width: "100%",
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Client</InputLabel>
              <Select
                value={selectedClient}
                onChange={handleClientChange}
                label="Client"
                disabled={isTimerActive}
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Project</InputLabel>
              <Select
                value={selectedProject}
                onChange={handleProjectChange}
                label="Project"
                disabled={isTimerActive || !selectedClient}
              >
                {filteredProjects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Assignment</InputLabel>
              <Select
                value={
                  timesheet?.projectAssignmentId ||
                  newEntry.projectAssignmentId ||
                  ""
                }
                onChange={handleProjectAssignmentChange}
                label="Assignment"
                disabled={isTimerActive}
              >
                {filteredAssignments.map((assignment) => (
                  <MenuItem key={assignment.id} value={assignment.id}>
                    {`${assignment.displayName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Time Entry Row */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              width: "100%",
            }}
          >
            {!isTimedEntry ? (
              <>
                {/* Manual Entry Mode */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 2,
                    flex: 1,
                  }}
                >
                  {/* Description Field */}
                  <TextField
                    label="Description"
                    value={newEntry.description || ""}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    multiline
                    rows={2}
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-root": {
                        height: inputHeight,
                        alignItems: "flex-start",
                        padding: "8px 14px",
                        "& textarea": {
                          height: "100% !important",
                          overflow: "auto !important",
                        },
                      },
                    }}
                  />

                  {/* Date and Time Controls */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexShrink: 0,
                      minWidth: { sm: "400px" },
                    }}
                  >
                    <DatePicker
                      label="Date"
                      value={
                        newEntry.date ? DateTime.fromISO(newEntry.date) : null
                      }
                      onChange={(newValue) => {
                        if (newValue) {
                          const date = newValue as DateTime;
                          handleInputChange("date", date.toISODate());
                        }
                      }}
                      sx={{
                        width: "150px",
                        "& .MuiInputBase-root": { height: inputHeight },
                      }}
                    />

                    <MultiInputTimeRangeField
                      value={[
                        newEntry.startTime
                          ? DateTime.fromISO(newEntry.startTime)
                          : null,
                        newEntry.endTime
                          ? DateTime.fromISO(newEntry.endTime)
                          : null,
                      ]}
                      onChange={(newValue) => {
                        const [start, end] = newValue;
                        if (start && end) {
                          handleInputChange("startTime", start.toISO());
                          handleInputChange("endTime", end.toISO());
                        }
                      }}
                      sx={{
                        width: "230px",
                        "& .MuiInputBase-root": { height: inputHeight },
                      }}
                      ampm={false}
                      format="HH:mm"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              // Timer Mode
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flex: 1,
                }}
              >
                <TextField
                  label="Description"
                  value={newEntry.description || ""}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                  sx={{
                    flex: 1,
                    "& .MuiInputBase-root": { height: inputHeight },
                  }}
                />
              </Box>
            )}

            {/* Action Controls */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                flexShrink: 0,
              }}
            >
              <Tooltip
                title={
                  !newEntry.projectAssignmentId
                    ? "Select an assignment to enable billable hours"
                    : ""
                }
                arrow
              >
                <span>
                  <IconButton
                    onClick={() =>
                      newEntry.projectAssignmentId &&
                      handleInputChange("billable", !newEntry.billable)
                    }
                    sx={{
                      width: inputHeight,
                      height: inputHeight,
                      border: "1px solid",
                      borderColor: "divider",
                      color:
                        newEntry.billable && newEntry.projectAssignmentId
                          ? theme.palette.success.main
                          : theme.palette.text.secondary,
                      opacity: newEntry.projectAssignmentId ? 1 : 0.5,
                    }}
                    disabled={!newEntry.projectAssignmentId}
                  >
                    {newEntry.billable && newEntry.projectAssignmentId ? (
                      <AttachMoneyIcon />
                    ) : (
                      <MoneyOffIcon />
                    )}
                  </IconButton>
                </span>
              </Tooltip>

              {/* Timer/Duration Display */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                  p: 1,
                  width: "110px",
                  height: inputHeight,
                }}
              >
                <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                  {isTimedEntry
                    ? isTimerActive && timesheet?.startTime
                      ? `Started: ${DateTime.fromISO(timesheet.startTime).toFormat("HH:mm:ss")}`
                      : "Timer"
                    : "Duration"}
                </Typography>
                <Typography variant="h6">
                  {isTimedEntry ? timerValue : manualDuration}
                </Typography>
              </Box>

              {/* Action Button with Warning */}
              <Box sx={{ position: "relative" }}>
                <Tooltip
                  title={
                    selectedClient &&
                    selectedProject &&
                    !newEntry.projectAssignmentId ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          p: 1,
                        }}
                      >
                        <WarningAmberIcon fontSize="small" />
                        <Typography variant="caption" textAlign="center">
                          Please select a project assignment <br></br> to track
                          billable hours.
                        </Typography>
                      </Box>
                    ) : !selectedClient ? (
                      "Please select a client"
                    ) : !selectedProject ? (
                      "Please select a project"
                    ) : (
                      ""
                    )
                  }
                  arrow
                  placement="bottom"
                >
                  <span>
                    {" "}
                    {/* Wrapper needed for disabled button tooltip */}
                    <Button
                      variant="contained"
                      startIcon={
                        isTimedEntry ? (
                          isTimerActive ? (
                            isSaving ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              <StopIcon />
                            )
                          ) : (
                            <PlayArrowIcon />
                          )
                        ) : (
                          <AddIcon sx={{ mr: 1 }} />
                        )
                      }
                      onClick={async () => {
                        if (
                          selectedClient &&
                          selectedProject &&
                          !newEntry.projectAssignmentId
                        ) {
                          setShowWarningDialog(true);
                          return;
                        }

                        if (isTimedEntry) {
                          isTimerActive
                            ? handleStopTimer()
                            : handleStartTimer();
                        } else {
                          handleAddEntry();
                        }
                      }}
                      color={
                        isTimedEntry && isTimerActive ? "secondary" : "primary"
                      }
                      disabled={isActionButtonDisabled}
                      sx={{
                        width: "110px",
                        height: inputHeight,
                        "& .MuiButton-startIcon": {
                          marginRight: "-4px",
                        },
                      }}
                    >
                      {isTimedEntry
                        ? isTimerActive
                          ? isSaving
                            ? "Saving..."
                            : "Stop"
                          : "Start"
                        : "Add"}
                    </Button>
                  </span>
                </Tooltip>
              </Box>

              {/* Mode Toggle */}
              <ToggleButtonGroup
                orientation="vertical"
                value={view}
                exclusive
                onChange={handleTimerOrManualEntryChange}
                sx={{
                  height: inputHeight,
                  "& .MuiToggleButton-root": {
                    padding: "4px",
                  },
                }}
              >
                <ToggleButton value="manual" aria-label="manual" size="small">
                  <EditIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="timer" aria-label="timer" size="small">
                  <TimerIcon fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Accordion
        expanded={expandedNotes}
        onChange={() => setExpandedNotes(!expandedNotes)}
        sx={{
          mb: 2,
          boxShadow: "none",
          "&:before": { display: "none" },
          "& .MuiAccordionSummary-root": {
            minHeight: { xs: 48, sm: 56 },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography>Long Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ 
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            overflow: 'hidden',
            opacity: isTimerActive ? 0.5 : 1,
            pointerEvents: isTimerActive ? 'none' : 'auto',
            '& .markdown-editor-toolbar': {
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
            },
            '& .markdown-editor-content': {
              minHeight: 200,
              padding: 2,
            },
          }}>
            <MarkdownEditor
              value={newEntry.longDescription || ""}
              onChange={(value) => !isTimerActive && handleInputChange("longDescription", value)}
              placeholder="Add additional details here..."
              minHeight={200}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <NonBillableWarningDialog
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onConfirm={handleWarningConfirm}
      />

      <MinimumTimeDialog
        open={showMinTimeDialog}
        onClose={() => setShowMinTimeDialog(false)}
        duration={isTimedEntry ? timerValue : manualDuration}
      />

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => setShowError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TimeTrackerInput;
