import React from "react";

import { useAuth } from "hooks/auth/useAuth";
import { Typography } from "@mui/material";

const StakeholderHome = (): JSX.Element => {
  const { userInfo } = useAuth();

  return (
    <>
      <Typography variant="h2">
        Welcome, {userInfo?.firstName} {userInfo?.lastName}. Please complete the
        onboarding steps below.
      </Typography>
    </>
  );
};

export default StakeholderHome;
