import React from "react";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  IconButton,
  AvatarGroup,
  Avatar,
  Tooltip,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import ArchiveIcon from "@mui/icons-material/Archive";
import ShareIcon from "@mui/icons-material/Share";
import CrownIcon from "@mui/icons-material/WorkspacePremium";
import SettingsIcon from '@mui/icons-material/Settings';

interface FreeloBoardHeaderProps {
  board: Board;
  // TODO: missing types since this is public user lsit
  users?: UserInfoType[];
  isAdmin: boolean;
  isArchiveView: boolean;
  isActivityView: boolean;
  editBoardTitle: string;
  isEditingBoardTitle: boolean;
  onEditBoardTitle: (title: string) => void;
  onBoardTitleSubmit: () => void;
  onSetIsEditingBoardTitle: (isEditing: boolean) => void;
  onViewChange: (view: "board" | "activity" | "archive") => void;
  onOpenShareDialog: () => void;
  onOpenSettings: () => void;
}

export const FreeloBoardHeader: React.FC<FreeloBoardHeaderProps> = ({
  board,
  users,
  isAdmin,
  isArchiveView,
  isActivityView,
  editBoardTitle,
  isEditingBoardTitle,
  onEditBoardTitle,
  onBoardTitleSubmit,
  onSetIsEditingBoardTitle,
  onViewChange,
  onOpenShareDialog,
  onOpenSettings,
}) => {
  const getBoardUsers = () => {
    if (!board) return [];

    const allUsers = new Set<string>();
    board.readUserIds?.forEach((id) => allUsers.add(id));
    board.writeUserIds?.forEach((id) => allUsers.add(id));
    if (board.createdBy) allUsers.add(board.createdBy);

    return Array.from(allUsers);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        bgcolor: "white",
        borderBottom: 1,
        borderColor: "divider",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          left: 0,
          right: 0,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: 64,
          flexWrap: { xs: "wrap", md: "nowrap" },
          gap: 2,
          maxWidth: "100%",
          pr: { xs: 2, md: 4, lg: 10 },
          pl: { xs: 2, md: 4, lg: 6 },
          boxSizing: "border-box",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minWidth: { xs: "100%", md: "auto" },
            flex: "0 1 auto",
            pl: { xs: 0, md: 2 },
          }}
        >
          {isEditingBoardTitle ? (
            <TextField
              autoFocus
              value={editBoardTitle}
              onChange={(e) => onEditBoardTitle(e.target.value)}
              onBlur={onBoardTitleSubmit}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onBoardTitleSubmit();
                }
              }}
              size="small"
              sx={{
                minWidth: 200,
                "& .MuiInputBase-input": {
                  fontSize: "h5.fontSize",
                  fontWeight: "h5.fontWeight",
                  p: "4px 8px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
              }}
            />
          ) : (
            <Typography
              variant="h5"
              onClick={() => {
                onSetIsEditingBoardTitle(true);
                onEditBoardTitle(board.name);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              {board.name}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: { xs: "100%", md: "auto" },
            justifyContent: { xs: "space-between", md: "flex-end" },
            flexWrap: { xs: "wrap", sm: "nowrap" },
            flex: "1 1 auto",
            minWidth: 0,
          }}
        >
          <ButtonGroup
            variant="outlined"
            size="small"
            sx={{
              flexGrow: { xs: 1, sm: 0 },
              "& .MuiButton-root": {
                flex: { xs: 1, sm: "initial" },
                whiteSpace: "nowrap",
              },
            }}
          >
            <Button
              startIcon={<DashboardIcon />}
              color={!isArchiveView && !isActivityView ? "primary" : "inherit"}
              variant={
                !isArchiveView && !isActivityView ? "contained" : "outlined"
              }
              onClick={() => onViewChange("board")}
            >
              <Box
                component="span"
                sx={{ display: { xs: "none", sm: "inline" } }}
              >
                Board
              </Box>
            </Button>
            <Button
              startIcon={<HistoryIcon />}
              color={isActivityView ? "primary" : "inherit"}
              variant={isActivityView ? "contained" : "outlined"}
              onClick={() => onViewChange("activity")}
            >
              <Box
                component="span"
                sx={{ display: { xs: "none", sm: "inline" } }}
              >
                Activity
              </Box>
            </Button>
            <Button
              startIcon={<ArchiveIcon />}
              color={isArchiveView ? "primary" : "inherit"}
              variant={isArchiveView ? "contained" : "outlined"}
              onClick={() => onViewChange("archive")}
            >
              <Box
                component="span"
                sx={{ display: { xs: "none", sm: "inline" } }}
              >
                Archive
              </Box>
            </Button>
          </ButtonGroup>

          <AvatarGroup
            max={5}
            sx={{
              "& .MuiAvatar-root": {
                width: { xs: 28, sm: 32 },
                height: { xs: 28, sm: 32 },
                fontSize: "0.875rem",
                border: "2px solid white",
              },
              flexShrink: 0,
            }}
          >
            {getBoardUsers()
              .sort((a, b) => {
                if (a === board.createdBy) return -1;
                if (b === board.createdBy) return 1;
                return 0;
              })
              .map((userId) => {
                const user = users?.find((u) => u.id === userId);
                if (!user) return null;

                const isOwner = userId === board.createdBy;

                return (
                  <Tooltip
                    key={user.id}
                    title={
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Typography variant="body2">
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        {isOwner && (
                          <Typography
                            variant="caption"
                            sx={{ color: "warning.main" }}
                          >
                            (Owner)
                          </Typography>
                        )}
                      </Box>
                    }
                  >
                    <Box sx={{ position: "relative" }}>
                      <Avatar
                        src={user.profilePicture || undefined}
                        alt={`${user.firstName} ${user.lastName}`}
                        sx={
                          isOwner
                            ? {
                                border: "2px solid",
                                borderColor: "warning.main",
                              }
                            : undefined
                        }
                      >
                        {user.firstName?.[0] || ""}
                      </Avatar>
                      {isOwner && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -2,
                            right: -2,
                            width: 16,
                            height: 16,
                            borderRadius: "50%",
                            bgcolor: "warning.main",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid white",
                          }}
                        >
                          <CrownIcon sx={{ fontSize: 12, color: "white" }} />
                        </Box>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
          </AvatarGroup>

          <Box sx={{ display: 'flex', gap: 1 }}>
            {isAdmin && (
              <>
                <Button
                  startIcon={<ShareIcon />}
                  onClick={onOpenShareDialog}
                  variant="outlined"
                  size="small"
                >
                  Share
                </Button>
                <IconButton
                  onClick={onOpenSettings}
                  size="small"
                  sx={{ color: 'inherit' }}
                >
                  <SettingsIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
