import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  styled,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { generateId } from 'utils/generateId';

interface KnowledgeTreeProps {
  structure: KnowledgeBaseStructure | undefined;
  isLoading: boolean;
  error: Error | null;
  onSelectFile: (file: KnowledgeBaseFile) => void;
  onSelectFolder: (path: string) => void;
  onCreateFolder: (parentPath: string) => void;
  onCreateDocument: (parentPath: string) => void;
  currentPath: string;
}

const StyledTreeContainer = styled(Box)(({ theme }) => ({
  width: '280px',
  height: '100%',
  padding: theme.spacing(2),
  borderRight: `1px solid ${theme.palette.divider}`,
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    maxHeight: '300px',
  },
}));

interface CustomTreeItemProps extends TreeItemProps {
  isFolder?: boolean;
  onAddClick?: (event: React.MouseEvent<HTMLElement>) => void;
  selected?: boolean;
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      '& .add-button': {
        opacity: 1,
      },
    },
  },
  [`& .${treeItemClasses.selected}`]: {
    backgroundColor: `${theme.palette.action.selected} !important`,
  },
}));

const CustomTreeItem = React.forwardRef<HTMLLIElement, CustomTreeItemProps>((props, ref) => {
  const { isFolder, onAddClick, label, selected, ...other } = props;

  return (
    <StyledTreeItem
      ref={ref}
      {...other}
      label={
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          width: '100%',
          minHeight: 32,
          py: 0.5,
          bgcolor: selected ? 'action.selected' : 'transparent',
          borderRadius: 1,
          '&:hover .add-button': {
            opacity: 1,
          },
        }}>
          {isFolder ? (
            <FolderIcon color="primary" fontSize="small" />
          ) : (
            <InsertDriveFileIcon color="info" fontSize="small" />
          )}
          <Typography sx={{ flex: 1, ml: 1 }}>{label}</Typography>
          {isFolder && (
            <IconButton
              size="small"
              className="add-button"
              onClick={onAddClick}
              sx={{ 
                opacity: 0,
                transition: 'opacity 0.2s',
                padding: '2px',
                mr: 1,
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                },
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      }
    />
  );
});

const KnowledgeTree: React.FC<KnowledgeTreeProps> = ({
  structure,
  isLoading,
  error,
  onSelectFile,
  onSelectFolder,
  onCreateDocument,
  onCreateFolder,
  currentPath,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedPath, setSelectedPath] = useState<string>('');
  const [expandedItems, setExpandedItems] = useState<string[]>(['root']);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);

  const handleAddClick = (event: React.MouseEvent<HTMLElement>, path: string) => {
    event.stopPropagation();
    setSelectedPath(path);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewDoc = () => {
    onCreateDocument(selectedPath);
    handleClose();
  };

  const handleNewFolder = () => {
    onCreateFolder(selectedPath);
    handleClose();
  };

  const renderTreeItem = (itemId: string) => {
    if (itemId === 'root') {
      return {
        isFolder: true,
        onAddClick: (e: React.MouseEvent<HTMLElement>) => handleAddClick(e, 'root'),
        selected: currentPath === 'root' && !selectedFileId,
      };
    }

    const folder = structure?.rootFolder.folders.find(f => f.path === itemId);
    if (folder) {
      return {
        isFolder: true,
        onAddClick: (e: React.MouseEvent<HTMLElement>) => handleAddClick(e, folder.path),
        selected: currentPath === folder.path && !selectedFileId,
      };
    }

    return {
      isFolder: false,
      selected: itemId === selectedFileId,
    };
  };

  const handleItemClick = (event: React.MouseEvent, itemId: string) => {
    if (itemId === 'root') {
      onSelectFolder('root');
      window.history.pushState(null, '', window.location.pathname);
      setSelectedFileId(null);
      return;
    }

    const folder = structure?.rootFolder.folders.find(f => f.path === itemId);
    if (folder) {
      onSelectFolder(folder.path);
      window.history.pushState(null, '', `${window.location.pathname}#folder-${folder.path}`);
      setSelectedFileId(null);
      return;
    }

    for (const folder of structure?.rootFolder.folders || []) {
      const file = folder.files.find(f => f.id === itemId);
      if (file) {
        onSelectFile(file);
        window.history.pushState(null, '', `${window.location.pathname}#${file.id}`);
        setSelectedFileId(file.id);
        return;
      }
    }
  };

  const findFileParentPath = (fileId: string): string | null => {
    for (const folder of structure?.rootFolder.folders || []) {
      if (folder.files.some(f => f.id === fileId)) {
        return folder.path;
      }
    }
    return null;
  };

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (!hash) {
      onSelectFolder('root');
      setExpandedItems(['root']);
      setSelectedFileId(null);
      return;
    }

    if (hash.startsWith('folder-')) {
      const folderPath = hash.replace('folder-', '');
      const folder = structure?.rootFolder.folders.find(
        f => f.path === folderPath
      );
      if (folder) {
        onSelectFolder(folder.path);
        setExpandedItems(['root', folder.path]);
        setSelectedFileId(null);
      }
    } else {
      for (const folder of structure?.rootFolder.folders || []) {
        const file = folder.files.find(f => f.id === hash);
        if (file) {
          onSelectFile(file);
          setExpandedItems(['root', folder.path]);
          setSelectedFileId(file.id);
          break;
        }
      }
    }
  }, [structure, onSelectFile, onSelectFolder]);

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (!hash) return;

    if (!hash.startsWith('folder-')) {
      for (const folder of structure?.rootFolder.folders || []) {
        const file = folder.files.find(f => f.id === hash);
        if (file) {
          setSelectedFileId(file.id);
          break;
        }
      }
    }
  }, []);

  const handleItemExpand = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">{error.message}</Typography>;
  if (!structure) return null;

  return (
    <StyledTreeContainer>
      <RichTreeView
        defaultExpandedItems={['root']}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleItemExpand}
        slots={{
          expandIcon: AddBoxIcon,
          collapseIcon: IndeterminateCheckBoxIcon,
          item: CustomTreeItem,
        }}
        items={[{
          id: 'root',
          label: structure.rootFolder.name,
          children: structure.rootFolder.folders.map(folder => ({
            id: folder.path,
            label: folder.name,
            children: folder.files.map(file => ({
              id: file.id,
              label: file.name,
            })),
          })),
        }]}
        slotProps={{
          item: (ownerState) => {
            const customProps = renderTreeItem(ownerState.itemId);
            return {
              ...ownerState,
              ...customProps,
            } as Partial<TreeItemProps>;
          },
        }}
        onItemClick={handleItemClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleNewDoc}>
          <ListItemIcon>
            <NoteAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="New doc" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleNewFolder}>
          <ListItemIcon>
            <CreateNewFolderIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="New collection" />
        </MenuItem>
      </Menu>
    </StyledTreeContainer>
  );
};

export default KnowledgeTree; 