import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Divider,
  Stack,
  IconButton,
  Chip,
  ClickAwayListener,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import FreeloTicketDialog from '../FreeloTicketDialog/FreeloTicketDialog';
import { useUserList } from 'hooks/shared/user/useUserList';
import EditIcon from '@mui/icons-material/Edit';
import LabelIcon from '@mui/icons-material/Label';
import PersonIcon from '@mui/icons-material/Person';
import TimerIcon from '@mui/icons-material/Timer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { LabelManager } from 'components/LabelManager/LabelManager';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import { DateTimeRangeValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers-pro";
import { DateTime } from 'luxon';
import CommentIcon from '@mui/icons-material/Comment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AvatarGroup from '@mui/material/AvatarGroup';
import ConfirmActionDialog from 'components/ConfirmActionDialog';
import { useNavigate } from 'react-router-dom';

interface FreeloTicketProps {
  card: Card;
  boardId: string;
  listId: string;
  onUpdate: (cardId: string, updates: Partial<Card>) => Promise<void>;
  onArchive: () => void;
  onCopy: () => void;
}

// Add this type for better member management
type MemberType = 'assignee' | 'additional';

// Add this type to match the user data structure from useUserList
type UserListType = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
};

// Add this type for the picker actions
type PickerAction = 'accept' | 'cancel' | 'clear' | 'partial';

const FreeloTicket: React.FC<FreeloTicketProps> = ({ card: initialCard, boardId, listId, onUpdate, onArchive, onCopy }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { data: users } = useUserList();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [memberAnchorEl, setMemberAnchorEl] = useState<HTMLElement | null>(null);
  const [memberSearch, setMemberSearch] = useState('');
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [labelAnchorEl, setLabelAnchorEl] = useState<HTMLElement | null>(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(initialCard.title);
  const [datePickerAnchorEl, setDatePickerAnchorEl] = useState<HTMLElement | null>(null);
  const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);

  // Add local state for optimistic updates
  const [card, setCard] = useState<Card>(initialCard);

  // Update local state when prop changes
  useEffect(() => {
    setCard(initialCard);
  }, [initialCard]);

  // Helper function for optimistic updates
  const optimisticUpdate = async (updates: Partial<Card>) => {
    setCard(current => ({
      ...current,
      ...updates,
      updatedAt: new Date().toISOString()
    }));

    try {
      await onUpdate(card.id, updates);
    } catch (error) {
      console.error("Error updating card:", error);
      setCard(initialCard);
    }
  };

  const assignee = users?.find(user => user.id === card.assignee);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    const cardElement = cardRef.current;
    if (!cardElement) return;
    
    const rect = cardElement.getBoundingClientRect();
    setContextMenu({
      mouseX: rect.right + 2, // Position menu 2px to the right of the card
      mouseY: rect.top, // Align with top of card
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleMenuAction = (action: string, event?: React.MouseEvent<HTMLElement>) => {
    handleClose();
    switch (action) {
      case 'open':
        setIsDialogOpen(true);
        break;
      case 'labels':
        if (event) {
          event.stopPropagation();
          setLabelAnchorEl(cardRef.current);
        }
        break;
      case 'members':
        if (event) {
          handleMembersOpen(event);
        }
        break;
      case 'dates':
        if (event) {
          event.stopPropagation();
          setDatePickerAnchorEl(cardRef.current);
        }
        break;
      case 'copy':
        onCopy();
        break;
      case 'archive':
        setShowArchiveConfirm(true);
        break;
      default:
        break;
    }
  };

  const handleMembersOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMemberAnchorEl(cardRef.current);
  };

  const handleMembersClose = () => {
    setMemberAnchorEl(null);
    setMemberSearch('');
  };

  const handleRemoveMember = async (userId: string, type: MemberType) => {
    try {
      if (type === 'assignee') {
        await optimisticUpdate({
          assignee: '',
        });
      } else {
        await optimisticUpdate({
          additionalAssignees: (card.additionalAssignees || []).filter(id => id !== userId)
        });
      }
    } catch (error) {
      console.error('Error removing member:', error);
    }
  };

  const handleAddMember = async (userId: string) => {
    try {
      if (!card.assignee) {
        await optimisticUpdate({
          assignee: userId
        });
      } else if (!card.additionalAssignees?.includes(userId) && userId !== card.assignee) {
        await optimisticUpdate({
          additionalAssignees: [...(card.additionalAssignees || []), userId]
        });
      }
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  const handleTitleEdit = async () => {
    if (editedTitle.trim() === '') return;
    if (editedTitle === card.title) {
      setIsEditingTitle(false);
      return;
    }
    
    try {
      await optimisticUpdate({ 
        title: editedTitle.trim() 
      });
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating title:', error);
      setEditedTitle(card.title);
    }
  };

  const handleDateChange = async (
    value: [DateTime | null, DateTime | null],
    context: any
  ) => {
    try {
      const [startDate, endDate] = value;
      
      const currentStartDate = card.startDate ? DateTime.fromISO(card.startDate) : null;
      const currentDueDate = card.dueDate ? DateTime.fromISO(card.dueDate) : null;
      
      const hasChanged = 
        startDate?.toISO() !== currentStartDate?.toISO() || 
        endDate?.toISO() !== currentDueDate?.toISO();

      if (hasChanged) {
        await optimisticUpdate({
          startDate: startDate?.toISO() || null,
          dueDate: endDate?.toISO() || null,
        });
      }

      if (context?.action === 'accept' || context?.action === 'clear') {
        setDatePickerAnchorEl(null);
      }
    } catch (error) {
      console.error("Error updating dates:", error);
    }
  };

  const handleLabelUpdate = async (labels: CardLabel[]) => {
    try {
      await onUpdate(card.id, {
        labels,
      });
    } catch (error) {
      console.error('Error updating labels:', error);
    }
  };

  const renderMemberItem = (user: UserListType, type: MemberType, userId: string) => (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        p: 1,
        borderRadius: 1,
        transition: 'all 0.2s ease',
        '&:hover': {
          bgcolor: 'action.hover',
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Avatar 
          src={user.profilePicture || undefined} 
          sx={{ width: 28, height: 28 }}
        >
          {user.firstName?.[0]}
        </Avatar>
        <Box>
          <Typography>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {type === 'assignee' ? 'Assignee' : 'Additional'}
          </Typography>
        </Box>
      </Box>
      <IconButton 
        size="small" 
        onClick={() => handleRemoveMember(userId, type)}
        sx={{
          opacity: 0,
          transition: 'opacity 0.2s',
          '.MuiBox-root:hover &': {
            opacity: 1,
          }
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const getSharedUsers = () => {
    const sharedUsers: UserListType[] = [];
    
    // Add assignee if exists
    if (card.assignee && users) {
      const assigneeUser = users.find(u => u.id === card.assignee);
      if (assigneeUser) sharedUsers.push(assigneeUser);
    }
    
    // Add additional assignees
    if (card.additionalAssignees && users) {
      card.additionalAssignees.forEach(id => {
        const user = users.find(u => u.id === id);
        if (user) sharedUsers.push(user);
      });
    }
    
    return sharedUsers;
  };

  const handleCardClick = () => {
    if (!isEditingTitle) {
      navigate(`/freelo/${boardId}#${initialCard.humanReadableId}`, { replace: true });
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    navigate(`/freelo/${boardId}`, { replace: true });
  };

  return (
    <>
      <Paper
        ref={cardRef}
        sx={{
          p: 2,
          mb: 1,
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          backgroundColor: 'background.paper',
          '&:hover, &[data-context-open="true"]': {
            transform: 'translateY(-2px)',
            boxShadow: 3,
            backgroundColor: 'background.paper',
            '& .edit-button': {
              opacity: 1,
            },
          },
          position: 'relative',
          minHeight: '80px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 1,
          ...(contextMenu !== null && {
            transform: 'translateY(-2px)',
            boxShadow: 3,
          }),
        }}
        onClick={handleCardClick}
        onContextMenu={handleContextMenu}
        data-context-open={contextMenu !== null ? "true" : undefined}
      >
        <Typography
          variant="caption"
          sx={{
            color: 'text.secondary',
            mb: 1,
            fontSize: '0.75rem',
            fontFamily: 'monospace',
            letterSpacing: '0.5px'
          }}
        >
          #{card.humanReadableId || 'undefined'}
        </Typography>

        <Tooltip title="Edit title">
          <IconButton
            size="small"
            className="edit-button"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditingTitle(true);
            }}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              opacity: 0,
              transition: 'opacity 0.2s ease',
              padding: '4px',
              zIndex: 2,
              ':hover': {
                color: 'primary.main',
                bgcolor: 'transparent',
              },
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        {card.labels && card.labels.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1.5 }}>
            {card.labels.map(label => (
              <Chip
                key={label.id}
                label={label.name}
                size="small"
                sx={{
                  bgcolor: label.color,
                  color: 'white',
                  height: 20,
                  fontSize: '0.75rem',
                }}
              />
            ))}
          </Box>
        )}

        <Box sx={{ position: 'relative', mb: 'auto', minHeight: '40px' }}>
          {isEditingTitle ? (
            <ClickAwayListener onClickAway={handleTitleEdit}>
              <TextField
                fullWidth
                variant="standard"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    handleTitleEdit();
                  } else if (e.key === 'Escape') {
                    setEditedTitle(card.title);
                    setIsEditingTitle(false);
                  }
                }}
                autoFocus
                multiline
                onClick={(e) => e.stopPropagation()}
                sx={{
                  width: '100%',
                  '& .MuiInput-root': {
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                    '&:before, &:after': {
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    },
                    '&:hover:not(.Mui-disabled):before': {
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    },
                  },
                  '& .MuiInput-input': {
                    padding: '0px',
                    lineHeight: '1.5',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  },
                }}
              />
            </ClickAwayListener>
          ) : (
            <Typography 
              variant="subtitle2" 
              sx={{ 
                pr: 4,
                wordWrap: 'break-word',
                overflowWrap: 'anywhere',
                whiteSpace: 'pre-line',
                cursor: 'pointer',
                width: '100%',
                display: 'block',
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: 1.5,
                '&:hover': {
                  color: 'primary.main',
                }
              }}
            >
              {card.title}
            </Typography>
          )}
        </Box>

        <Box 
          sx={{ 
            mt: 2,
            pt: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1.5,
          }}>
            {(card.comments && card.comments.length > 0) && (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 0.5,
                color: 'text.secondary',
              }}>
                <CommentIcon sx={{ fontSize: '1rem' }} />
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {card.comments?.length}
                </Typography>
              </Box>
            )}
            
            {(card.startDate || card.dueDate) && (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 0.5,
                color: 'text.secondary',
              }}>
                <AccessTimeIcon sx={{ fontSize: '1rem' }} />
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {card.startDate && DateTime.fromISO(card.startDate).toFormat('MMM d')}
                  {card.startDate && card.dueDate && ' - '}
                  {card.dueDate && DateTime.fromISO(card.dueDate).toFormat('MMM d')}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
            <AvatarGroup
              max={3}
              sx={{
                '& .MuiAvatar-root': {
                  width: 24,
                  height: 24,
                  fontSize: '0.75rem',
                  border: '2px solid white',
                },
              }}
            >
              {getSharedUsers().map((user) => (
                <Tooltip 
                  key={user.id} 
                  title={`${user.firstName} ${user.lastName}`}
                >
                  <Avatar
                    src={user.profilePicture || undefined}
                    alt={`${user.firstName} ${user.lastName}`}
                  >
                    {user.firstName[0]}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          </Box>
        </Box>
      </Paper>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => handleMenuAction('open')}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Open card</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleMenuAction('labels', e)}>
          <ListItemIcon>
            <LabelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit labels</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleMenuAction('members', e)}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change members</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleMenuAction('dates', e)}>
          <ListItemIcon>
            <TimerIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {card.dueDate ? (
              <>
                {card.startDate && `${DateTime.fromISO(card.startDate).toFormat('MMM d')} - `}
                Due {DateTime.fromISO(card.dueDate).toFormat('MMM d, yyyy')}
              </>
            ) : (
              'Edit dates'
            )}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleMenuAction('copy')}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy card</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleMenuAction('archive')} sx={{ color: 'error.main' }}>
          <ListItemIcon sx={{ color: 'error.main' }}>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
      </Menu>

      <Popover
        open={Boolean(memberAnchorEl)}
        anchorEl={memberAnchorEl}
        onClose={handleMembersClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: 300,
            maxHeight: '410px',
            display: 'flex',
            flexDirection: 'column',
            ml: 1,
          }
        }}
      >
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">Change members</Typography>
            <IconButton size="small" onClick={handleMembersClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            size="small"
            placeholder="Search members"
            value={memberSearch}
            onChange={(e) => setMemberSearch(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'action.hover',
              }
            }}
          />
        </Box>

        <Box sx={{ 
          flex: 1, 
          overflowY: 'auto',
          px: 2,
          py: 1,
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'action.hover',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.400',
            borderRadius: '3px',
            '&:hover': {
              backgroundColor: 'grey.500',
            },
          },
        }}>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
            Card members
          </Typography>
          <Stack spacing={0.5} sx={{ mb: 2 }}>
            {card.assignee && users?.find(u => u.id === card.assignee) && 
              renderMemberItem(
                users.find(u => u.id === card.assignee)!,
                'assignee',
                card.assignee
              )
            }
            {(card.additionalAssignees || []).map(userId => {
              const user = users?.find(u => u.id === userId);
              if (!user) return null;
              return renderMemberItem(user, 'additional', userId);
            })}
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
            Board members
          </Typography>
          <Stack spacing={0.5}>
            {(users || [])
              .filter(user => 
                (user.firstName + ' ' + user.lastName)
                  .toLowerCase()
                  .includes(memberSearch.toLowerCase()) &&
                !card.additionalAssignees?.includes(user.id) &&
                user.id !== card.assignee
              )
              .map(user => (
                <Box
                  key={user.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: 'action.hover',
                      transform: 'translateX(4px)',
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddMember(user.id);
                  }}
                >
                  <Avatar 
                    src={user.profilePicture || undefined} 
                    sx={{ width: 28, height: 28 }}
                  >
                    {user.firstName[0]}
                  </Avatar>
                  <Typography>
                    {user.firstName} {user.lastName}
                  </Typography>
                </Box>
              ))}
          </Stack>
        </Box>
      </Popover>

      <LabelManager
        open={Boolean(labelAnchorEl)}
        anchorEl={labelAnchorEl}
        onClose={() => setLabelAnchorEl(null)}
        boardId={boardId}
        card={card}
      />

      <Popover
        open={Boolean(datePickerAnchorEl)}
        anchorEl={datePickerAnchorEl}
        onClose={() => setDatePickerAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: 'auto',
            p: 2,
            ml: 1,
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1">Set Date Range</Typography>
          <IconButton size="small" onClick={() => setDatePickerAnchorEl(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DateTimeRangePicker
            localeText={{ start: 'Start', end: 'End' }}
            value={[
              card.startDate ? DateTime.fromISO(card.startDate) : null,
              card.dueDate ? DateTime.fromISO(card.dueDate) : null
            ]}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                fullWidth: true,
                size: 'small',
              },
              actionBar: {
                actions: ['clear', 'cancel', 'accept'],
              },
              layout: {
                sx: {
                  [`.MuiDateRangeCalendar-root`]: {
                    width: 'auto',
                    maxHeight: 'none',
                  }
                }
              },
              field: {
                clearable: true,
              }
            }}
            closeOnSelect={false}
            format="MMM dd, yyyy hh:mm a"
            ampm
            sx={{ 
              '.MuiPickersLayout-root': {
                minWidth: 'auto',
              }
            }}
          />
        </LocalizationProvider>
        
        {(card.startDate || card.dueDate) && (
          <Button
            sx={{ mt: 2 }}
            color="error"
            fullWidth
            onClick={() => handleDateChange([null, null], {} as any)}
          >
            Remove Dates
          </Button>
        )}
      </Popover>

      <FreeloTicketDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        card={card}
        onUpdate={onUpdate}
        onArchive={onArchive}
        boardId={boardId}
      />

      <ConfirmActionDialog
        open={showArchiveConfirm}
        title="Archive Card"
        message={`Are you sure you want to archive "${card.title}"? You can find it later in the archived items.`}
        confirmText="Archive"
        confirmButtonColor="error"
        onConfirm={() => {
          onArchive();
          setShowArchiveConfirm(false);
        }}
        onCancel={() => setShowArchiveConfirm(false)}
      />
    </>
  );
};

export default FreeloTicket; 