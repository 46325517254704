import * as d3 from 'd3';

interface HillChartConfig {
  target: SVGSVGElement;
  width?: number;
  height?: number;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  initialValue?: number;
  editable?: boolean;
}

interface Point {
  x: number;
  y: number;
}

const defaults: Required<HillChartConfig> = {
  width: 700,
  height: 270,
  margin: {
    top: 15,
    right: 60,
    bottom: 35,
    left: 60
  },
  target: null as unknown as SVGSVGElement,
  initialValue: 0,
  editable: false
};

export class HillChartClass {
  private svg!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private xScale!: d3.ScaleLinear<number, number>;
  private yScale!: d3.ScaleLinear<number, number>;
  private xAxis!: d3.Axis<d3.NumberValue>;
  private yAxis!: d3.Axis<d3.NumberValue>;
  private line!: d3.Line<Point>;
  private readonly width: number;
  private readonly height: number;
  private readonly margin: typeof defaults.margin;
  private readonly target: SVGSVGElement;
  private progressCallback?: (value: number) => void;
  private dot?: d3.Selection<SVGCircleElement, Point, null, undefined>;
  private readonly initialValue: number;
  private editable: boolean;

  constructor(config: HillChartConfig) {
    const fullConfig = { ...defaults, ...config };
    this.width = fullConfig.width;
    this.height = fullConfig.height;
    this.margin = fullConfig.margin;
    this.target = fullConfig.target;
    this.initialValue = fullConfig.initialValue || 0;
    this.editable = fullConfig.editable || false;
    this.init();
  }

  public onProgressChange(callback: (value: number) => void) {
    this.progressCallback = callback;
  }

  public updateProgress(value: number) {
    if (!this.dot) return;

    const fn = (x: number) => 50 * Math.sin((Math.PI / 50) * x - (1 / 2) * Math.PI) + 50;
    const y = fn(value);

    this.dot
      .datum({ x: value, y })
      .attr('cx', this.xScale(value))
      .attr('cy', this.yScale(y));
  }

  public setEditable(editable: boolean) {
    this.editable = editable;
    if (this.dot) {
      this.dot.attr('cursor', editable ? 'pointer' : 'default')
        .style('opacity', editable ? 1 : 0.7);
    }
  }

  private init() {
    const w = this.width - this.margin.left - this.margin.right;
    const h = this.height - this.margin.top - this.margin.bottom;

    this.svg = d3.select(this.target)
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

    this.xScale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, w]);

    this.yScale = d3.scaleLinear()
      .domain([0, 100])
      .range([h, 0]);

    this.xAxis = d3.axisBottom(this.xScale).ticks(0);
    this.yAxis = d3.axisLeft(this.yScale).ticks(0);

    // Add axes
    this.svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${h})`)
      .call(this.xAxis);

    this.svg
      .append("g")
      .attr("class", "y axis")
      .call(this.yAxis);

    // Create hill curve
    const fn = (x: number) => 50 * Math.sin((Math.PI / 50) * x - (1 / 2) * Math.PI) + 50;
    const lineData = d3.range(0, 100, 0.1).map((i: number): Point => ({
      x: i,
      y: fn(i)
    }));

    this.line = d3.line<Point>()
      .x(d => this.xScale(d.x))
      .y(d => this.yScale(d.y));

    // Add hill path
    this.svg
      .append("path")
      .attr("class", "line")
      .datum(lineData)
      .attr("d", this.line)
      .attr("fill", "none")
      .attr("stroke", "#000000")
      .attr("stroke-width", 1.5);

    // Add middle line
    this.svg
      .append("line")
      .attr("class", "middle")
      .attr("x1", this.xScale(50))
      .attr("y1", this.yScale(0))
      .attr("x2", this.xScale(50))
      .attr("y2", this.yScale(100))
      .attr("stroke", "lightgrey")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "5,5");

    // Add labels
    this.svg
      .append("text")
      .attr("class", "text")
      .text("Figuring things out")
      .attr("x", this.xScale(25))
      .attr("y", h + 25)
      .attr("text-anchor", "middle")
      .attr("fill", "#bbb")
      .style("text-transform", "uppercase")
      .style("font-weight", "bold");

    this.svg
      .append("text")
      .attr("class", "text")
      .text("Making it happen")
      .attr("x", this.xScale(75))
      .attr("y", h + 25)
      .attr("text-anchor", "middle")
      .attr("fill", "#bbb")
      .style("text-transform", "uppercase")
      .style("font-weight", "bold");

    // Add draggable dot
    const initialY = fn(this.initialValue);

    const dragBehavior = d3.drag<SVGCircleElement, Point>()
      .on('drag', (event) => {
        if (!this.editable) return;

        const currentX = this.dot!.datum().x;
        const newX = Math.max(0, Math.min(100, 
          this.xScale.invert(this.xScale(currentX) + event.dx)
        ));
        const y = fn(newX);
        
        this.dot!
          .datum({ x: newX, y })
          .attr('cx', this.xScale(newX))
          .attr('cy', this.yScale(y));

        if (this.progressCallback) {
          this.progressCallback(newX);
        }
      });

    this.dot = this.svg
      .append('circle')
      .datum({ x: this.initialValue, y: initialY })
      .attr('r', 8)
      .attr('cx', d => this.xScale(d.x))
      .attr('cy', d => this.yScale(d.y))
      .attr('fill', '#2196f3')
      .attr('stroke', 'white')
      .attr('stroke-width', 2)
      .attr('cursor', this.editable ? 'pointer' : 'default')
      .style('opacity', this.editable ? 1 : 0.7)
      .call(dragBehavior);
  }

  public destroy() {
    this.progressCallback = undefined;
    // Any other cleanup needed
  }
} 