import React, { useState } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Paper,
  Stack,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import FreelancerCardGroup from "./components/FreelancerCardGroup";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WarningIcon from '@mui/icons-material/Warning';

const AdminProjectAssignments = () => {
  const [startDate, setStartDate] = useState<DateTime>(() => {
    return DateTime.local().minus({ months: 2 }).startOf('month');
  });
  const [endDate, setEndDate] = useState<DateTime>(() => {
    return DateTime.local();
  });

  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedFreelancers, setSelectedFreelancers] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<ProjectAssignmentStatus[]>([]);

  const [projects, setProjects] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [freelancerNames, setFreelancerNames] = useState<string[]>([]);

  // Add filter states
  const [appliedStartDate, setAppliedStartDate] = useState<DateTime>(startDate);
  const [appliedEndDate, setAppliedEndDate] = useState<DateTime>(endDate);
  const [appliedProjects, setAppliedProjects] = useState<string[]>([]);
  const [appliedClients, setAppliedClients] = useState<string[]>([]);
  const [appliedFreelancers, setAppliedFreelancers] = useState<string[]>([]);
  const [appliedStatus, setAppliedStatus] = useState<ProjectAssignmentStatus[]>([]);

  // Query freelancers
  const { 
    data: freelancers, 
    isLoading: isLoadingFreelancers,
    updateProjectAssignment 
  } = useAdminFreelancers();


  const statusOptions: { value: ProjectAssignmentStatus; label: string }[] = [
    { value: 'interviewing', label: 'Interviewing' },
    { value: 'ready', label: 'Ready' },
    { value: 'signing', label: 'Signing' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'complete', label: 'Complete' },
    { value: 'cancelled', label: 'Cancelled' },
  ];

  // Add new state for EverSign status dialog
  const [everSignStatusDialog, setEverSignStatusDialog] = useState<{
    open: boolean;
    assignmentId: string;
    clientId: string;
    projectId: string;
    currentStatus: EverSignDocumentStatus | undefined;
  }>({
    open: false,
    assignmentId: '',
    clientId: '',
    projectId: '',
    currentStatus: undefined,
  });
  const [newEverSignStatus, setNewEverSignStatus] = useState<EverSignDocumentStatus | ''>('');

  const everSignStatusOptions: { value: EverSignDocumentStatus; label: string }[] = [
    { value: 'awaiting_admin_signature', label: 'Awaiting Admin Signature' },
    { value: 'awaiting_engineer_signature', label: 'Awaiting Engineer Signature' },
    { value: 'signed', label: 'Signed' },
    { value: 'declined', label: 'Declined' },
    { value: 'cancelled', label: 'Cancelled' },
  ];

  const handleStartDateChange = (date: DateTime | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: DateTime | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  const handleApplyFilters = () => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
    setAppliedProjects(selectedProjects);
    setAppliedClients(selectedClients);
    setAppliedFreelancers(selectedFreelancers);
    setAppliedStatus(selectedStatus);
    console.log(freelancers);
  };

  const handleUpdateAssignment = (
    clientId: string,
    projectId: string,
    assignmentId: string,
    updates: Partial<ProjectAssignment>
  ) => {
    updateProjectAssignment.mutate({
      clientId,
      projectId,
      assignmentId,
      updates
    });
  };

  const handleOpenEverSignDialog = (
    clientId: string,
    projectId: string,
    assignmentId: string,
    currentStatus: EverSignDocumentStatus | undefined
  ) => {
    setEverSignStatusDialog({
      open: true,
      assignmentId,
      clientId,
      projectId,
      currentStatus,
    });
    setNewEverSignStatus(currentStatus || '');
  };

  const handleCloseEverSignStatusDialog = () => {
    setEverSignStatusDialog({
      open: false,
      assignmentId: '',
      clientId: '',
      projectId: '',
      currentStatus: undefined,
    });
    setNewEverSignStatus('');
  };

  const handleUpdateEverSignStatus = (
    clientId: string,
    projectId: string,
    assignmentId: string,
    currentStatus: EverSignDocumentStatus | undefined
  ) => {
    handleUpdateAssignment(
      clientId,
      projectId,
      assignmentId,
      { everSignDocumentStatus: currentStatus }
    );
  };

  const handleUpdateEverSignStatusClick = () => {
    if (newEverSignStatus) {
      handleUpdateEverSignStatus(
        everSignStatusDialog.clientId,
        everSignStatusDialog.projectId,
        everSignStatusDialog.assignmentId,
        newEverSignStatus
      );
      handleCloseEverSignStatusDialog();
    }
  };

  if (isLoadingFreelancers) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  console.log('isLoadingFreelancers', isLoadingFreelancers);
  console.log('freelancers', freelancers);

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Admin Project Assignments
      </Typography>
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Stack 
          direction={{ xs: 'column', md: 'row' }} 
          spacing={2} 
          sx={{ mb: 2 }}
          flexWrap="wrap"
          alignItems="center"
        >
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            sx={{ width: { xs: '100%', md: 200 } }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            sx={{ width: { xs: '100%', md: 200 } }}
          />
          <Autocomplete
            multiple
            options={projects}
            renderInput={(params) => (
              <TextField {...params} label="Projects" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            value={selectedProjects}
            onChange={(_, newValue) => setSelectedProjects(newValue)}
            sx={{ width: { xs: '100%', md: 250 } }}
          />
          <Autocomplete
            multiple
            options={clients}
            renderInput={(params) => (
              <TextField {...params} label="Clients" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            value={selectedClients}
            onChange={(_, newValue) => setSelectedClients(newValue)}
            sx={{ width: { xs: '100%', md: 250 } }}
          />
          <Autocomplete
            multiple
            options={freelancerNames}
            renderInput={(params) => (
              <TextField {...params} label="Freelancers" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            value={selectedFreelancers}
            onChange={(_, newValue) => setSelectedFreelancers(newValue)}
            sx={{ width: { xs: '100%', md: 250 } }}
          />
          <Autocomplete
            multiple
            options={statusOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Status" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip 
                  label={option.label} 
                  {...getTagProps({ index })}
                />
              ))
            }
            value={statusOptions.filter(option => selectedStatus.includes(option.value))}
            onChange={(_, newValue) => setSelectedStatus(newValue.map(v => v.value))}
            sx={{ width: { xs: '100%', md: 250 } }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
            startIcon={<FilterAltIcon />}
            sx={{ height: 56 }}
          >
            Apply Filters
          </Button>
        </Stack>
      </Paper>
      <FreelancerCardGroup
        startDate={appliedStartDate.toJSDate()}
        endDate={appliedEndDate.toJSDate()}
        selectedProjects={appliedProjects}
        selectedClients={appliedClients}
        selectedFreelancers={appliedFreelancers}
        selectedStatus={appliedStatus}
        setProjects={setProjects}
        setClients={setClients}
        setFreelancerNames={setFreelancerNames}
        freelancers={freelancers || []}
        onUpdateAssignment={handleUpdateAssignment}
        onUpdateEverSignStatus={handleUpdateEverSignStatus}
        onOpenEverSignDialog={handleOpenEverSignDialog}
      />

      {/* EverSign Status Update Dialog */}
      <Dialog 
        open={everSignStatusDialog.open} 
        onClose={handleCloseEverSignStatusDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: 'warning.light', display: 'flex', alignItems: 'center', gap: 1 }}>
          <WarningIcon color="warning" />
          Manual EverSign Status Update
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
            Warning: EverSign document status is typically updated automatically by the EverSign system.
            Manual updates should only be performed to fix system inconsistencies or bugs.
            Incorrect updates may cause system behavior issues.
          </Alert>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>New EverSign Status</InputLabel>
            <Select
              value={newEverSignStatus}
              label="New EverSign Status"
              onChange={(e) => setNewEverSignStatus(e.target.value as EverSignDocumentStatus)}
            >
              {everSignStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography color="text.secondary" sx={{ mt: 2 }}>
            Current Status: {everSignStatusDialog.currentStatus || 'Not Set'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEverSignStatusDialog}>Cancel</Button>
          <Button 
            onClick={handleUpdateEverSignStatusClick}
            variant="contained" 
            color="warning"
            disabled={!newEverSignStatus}
          >
            Update Status
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminProjectAssignments;
