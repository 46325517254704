import { INotifications } from "hooks/notifications/useNotifications";
import { Payment as PaymentIcon, Assignment as AssignmentIcon, Description as DescriptionIcon, Comment as CommentIcon } from '@mui/icons-material';

export const getIcon = (type: string) => {
    switch (type) {
      case 'invoice_sent':
        return PaymentIcon;
      case 'project_assigned':
        return AssignmentIcon;
      case 'document_uploaded':
        return DescriptionIcon;
      case 'mention':
        return CommentIcon;
      case 'assignment':
        return AssignmentIcon;
      default:
        return null;
    }
  };

  export const getNotificationMessage = (notification: INotifications): string => {
    const { type, data } = notification
    switch (type) {
      case 'invoice_sent':
        return `New invoice has been sent for review`
      case 'project_assigned':
        return `You have been assigned to a new project`
      case 'invoice_status_updated':
        return `Invoice status has been changed`
      case 'document_uploaded':
        return `A new document has been uploaded`
      case 'mention':
        return `You have been mentioned in a comment` 
      case 'assignment':
        return `You have been assigned to a ticket`
      default:
        return 'New notification'
    }
  };