import {
  collectionGroup,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { useQuery } from "@tanstack/react-query";
import { functionUrls } from "core/functionUrls/functionUrls";
import { getHeaders } from "core";

export const useAdminSignAgreements = () => {
  return useQuery({
    queryKey: ["adminProjectAssignments"],
    queryFn: getAllProjAssToSign,
    staleTime: 1000 * 60 * 5, // 5 minutes of stale time
    refetchOnWindowFocus: false, // Disable refetching when window is focused
    refetchOnMount: false, // Prevents refetch on mount if data is fresh
  });
};

export const getAllProjAssToSign = async (): Promise<ProjectAssignment[]> => {
  const projectAssignmentsQuery = query(
    collectionGroup(db, "projectAssignments")
  );
  const querySnapshot = await getDocs(projectAssignmentsQuery);
  const projectAssignments = querySnapshot.docs
    .map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        }) as ProjectAssignment
    )
    .filter(
      (assignment) =>
        assignment.everSignDocumentStatus === "awaiting_admin_signature" ||
        assignment.everSignDocumentStatus === "awaiting_engineer_signature"
    );
  return projectAssignments;
};

export const cancelProjectAssignmentInSigningStatus = async (
  clientId: string,
  assignmentId: string,
  documentHash: string
) => {
  const projectAssignmentRef = doc(
    db,
    "clients",
    clientId,
    "projectAssignments",
    assignmentId
  );
  const projectAssignmentSnap = await getDoc(projectAssignmentRef);
  const projectAssignmentData = projectAssignmentSnap.data();

  if (!projectAssignmentData) {
    throw new Error("Project assignment not found");
  }

  const cancelDocumentInput: typeof functionUrls.eversign.cancelDocument.input =
    {
      document_hash: documentHash,
    };

  const cancelDocumentResponse = await fetch(
    functionUrls.eversign.cancelDocument.url,
    {
      headers: getHeaders(),
      method: functionUrls.eversign.cancelDocument.method,
      body: JSON.stringify(cancelDocumentInput),
    }
  );

  const cancelDocumentData = await cancelDocumentResponse.json();

  if (!cancelDocumentData.success) {
    throw new Error("Failed to cancel document");
  }

  await updateDoc(projectAssignmentRef, {
    everSignDocumentStatus: "cancelled",
    status: "cancelled",
  });

  return true;
};
