import { db } from "config/firebase";
import { collectionGroup, getDocs, query, where } from "@firebase/firestore";
import { getClientProjects } from "../clients";
import { loadSingleStakeholder } from "./loadSingleStakeholder";

export const fetchStakeholderProjects = async (stakeholderId: string): Promise<Project[]> => {

    const stakeholder = await loadSingleStakeholder(stakeholderId);

    if (!stakeholder) {
        throw new Error('Stakeholder not found');
    }

    const clientIds = stakeholder.stakeholderClientIds || [];

    

    const projects = collectionGroup(db, 'projects');
    if (clientIds.length > 0) { 
        const projectsQuery = query(projects, where('client_id', 'in', clientIds));
        const projectsSnap = await getDocs(projectsQuery);
        const projectsData = projectsSnap.docs.map((doc) => doc.data() as Project);
        return projectsData;
    }
    return [];
};