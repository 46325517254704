import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Select,
  DialogActions,
  Autocomplete,
  Popover,
  CircularProgress,
  Link,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MarkdownEditor from "components/MarkdownEditor/MarkdownEditor";
import SubjectIcon from "@mui/icons-material/Subject";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LabelIcon from "@mui/icons-material/Label";
import PersonIcon from "@mui/icons-material/Person";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TimerIcon from "@mui/icons-material/Timer";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Chip from "@mui/material/Chip";
import { useUserList } from "hooks/shared/user/useUserList";
import { DateTime } from "luxon";
import { useAuth } from "hooks/auth/useAuth";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import type { DateTimeRangePickerProps } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import {
  DateTimeRangeValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import { SingleHillChart } from "./components/SingleHillChart/SingleHillChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useTheme } from "@mui/material/styles";
import { LabelManager } from "components/LabelManager/LabelManager";
import { useBoardActivity } from "hooks/freelo/useBoardActivity";
import { ActivitySection } from "./components/ActivitySection/ActivitySection";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useFreelo } from "hooks/freelo/useFreelo";
import { formatFileSize } from "utils/formatters";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GitHubIcon from "@mui/icons-material/GitHub";
import { logBoardActivity } from "hooks/freelo/useBoardActivity";
import { generateActivityMessage } from "hooks/freelo/generateActivityMessage";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface FreeloTicketDialogProps {
  open?: boolean;
  onClose?: () => void;
  card?: Card;
  onUpdate?: (cardId: string, updates: Partial<Card>) => Promise<void>;
  onArchive?: () => void;
  boardId?: string;
  standalone?: boolean;
}

interface DateRange<T> {
  start: T | null;
  end: T | null;
}

interface ChecklistItem {
  id: string;
  text: string;
  completed: boolean;
  createdAt: string;
  completedAt?: string;
  assigneeIds?: string[];
}

const AttachmentList: React.FC<{
  attachments?: CardAttachment[];
  onDelete: (attachmentId: string) => void;
  onDeleteClick: (attachment: CardAttachment) => void;
}> = ({ attachments, onDelete, onDeleteClick }) => {
  if (!attachments?.length) return null;

  return (
    <Box>
      {attachments.map((attachment) => (
        <Box
          key={attachment.id}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 0.5,
            "&:hover": {
              "& .delete-icon": {
                opacity: 1,
              },
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <InsertDriveFileIcon sx={{ fontSize: 20 }} />
            <Box>
              <Link
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                  color: "text.primary",
                  fontSize: "0.875rem",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                component="a"
              >
                {attachment.fileName}
              </Link>
              <Typography
                variant="caption"
                display="block"
                color="text.secondary"
              >
                {formatFileSize(attachment.fileSize)}
              </Typography>
            </Box>
          </Box>
          <IconButton
            size="small"
            onClick={() => onDeleteClick(attachment)}
            className="delete-icon"
            sx={{
              color: "error.main",
              opacity: 0,
              padding: 0.5,
              transition: "opacity 0.2s",
            }}
          >
            <DeleteIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

const FreeloTicketDialog: React.FC<FreeloTicketDialogProps> = ({
  open = false,
  onClose,
  card: initialCard,
  onUpdate,
  onArchive,
  boardId,
  standalone = false,
}) => {
  const navigate = useNavigate();
  const { cardId } = useParams<{ cardId: string; boardId: string }>();
  const { boards } = useFreelo();
  const board = boards?.find((b) => b.id === boardId);
  const isBoardLoading = !board;

  const [card, setCard] = useState<Card | undefined>(initialCard);
  const [isDescriptionEditing, setIsDescriptionEditing] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [editContent, setEditContent] = useState(
    initialCard?.description || ""
  );
  const [editTitle, setEditTitle] = useState(initialCard?.title || "");
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);
  const [showMembersDialog, setShowMembersDialog] = useState(false);
  const [labelAnchorEl, setLabelAnchorEl] = useState<null | HTMLElement>(null);
  const { data: users } = useUserList();
  const { userInfo } = useAuth();
  const [newComment, setNewComment] = useState("");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [showHillChart, setShowHillChart] = useState(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showAttachments, setShowAttachments] = useState(true);
  const [showGithubDialog, setShowGithubDialog] = useState(false);
  const [githubBranch, setGithubBranch] = useState("");
  const [attachmentToDelete, setAttachmentToDelete] =
    useState<CardAttachment | null>(null);
  const [showChecklists, setShowChecklists] = useState(true);
  const [editingChecklistId, setEditingChecklistId] = useState<string | null>(
    null
  );
  const [newChecklistItem, setNewChecklistItem] = useState("");
  const [showAssignMemberDialog, setShowAssignMemberDialog] = useState(false);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [memberMenuAnchorEl, setMemberMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [editingItemTextId, setEditingItemTextId] = useState<string | null>(
    null
  );
  const [editingItemText, setEditingItemText] = useState("");

  const { activities } = useBoardActivity(boardId || "");

  const { updateCard, addComment, uploadAttachment, deleteAttachment } =
    useFreelo();

  const theme = useTheme();

  useEffect(() => {
    if (standalone && cardId && board) {
      const foundCard = board.columns?.reduce<Card | undefined>(
        (found: Card | undefined, column: Column) => {
          if (found) return found;
          return column.cards.find((c) => c.id === cardId);
        },
        undefined
      );

      if (foundCard) {
        setCard(foundCard);
        setEditContent(foundCard.description || "");
        setEditTitle(foundCard.title || "");
      }
    } else {
      setCard(initialCard);
      setEditContent(initialCard?.description || "");
      setEditTitle(initialCard?.title || "");
    }
  }, [standalone, cardId, board, initialCard]);

  const handleClose = () => {
    if (standalone) {
      navigate(`/freelo/${boardId}`, { replace: true });
    } else if (onClose) {
      onClose();
    }
  };

  const handleUpdate = async (updates: Partial<Card>) => {
    if (!card) return;

    try {
      if (standalone) {
        await updateCard.mutateAsync({
          boardId: boardId!,
          cardId: card.id,
          updates,
        });
      } else if (onUpdate) {
        await onUpdate(card.id, updates);
      }
    } catch (error) {
      console.error("Error updating card:", error);
    }
  };

  const handleArchive = async () => {
    if (!card) return;

    try {
      if (standalone) {
        await updateCard.mutateAsync({
          boardId: boardId!,
          cardId: card.id,
          updates: { archived: true },
        });
        navigate(`/freelo/${boardId}`, { replace: true });
      } else if (onArchive) {
        await onArchive();
      }
    } catch (error) {
      console.error("Error archiving card:", error);
    }
  };

  if (standalone && !card && isBoardLoading) {
    return (
      <Dialog
        open={true}
        maxWidth="lg" // Match main dialog
        fullWidth
        PaperProps={{
          // Match main dialog
          sx: {
            minHeight: "85vh",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh", // Adjust for better centering
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (!card) return null;

  const optimisticUpdate = async (updates: Partial<Card>) => {
    if (!card) return;

    setCard((current) => {
      if (!current) return current;
      return {
        ...current,
        ...updates,
        updatedAt: new Date().toISOString(),
      };
    });

    try {
      await handleUpdate(updates);
    } catch (error) {
      console.error("Error updating card:", error);
      setCard(initialCard);
    }
  };

  const handleTitleSave = async () => {
    if (!editTitle.trim() || editTitle === card.title) {
      setEditTitle(card.title);
      setIsTitleEditing(false);
      return;
    }

    setIsTitleEditing(false);
    await optimisticUpdate({
      title: editTitle.trim(),
    });
  };

  const handleDescriptionSave = async () => {
    setIsDescriptionEditing(false);
    await optimisticUpdate({
      description: editContent,
    });
  };

  const handleDescriptionCancel = () => {
    if (initialCard) {
      setEditContent(initialCard.description || "");
    }
    setIsDescriptionEditing(false);
  };

  const getUserById = (userId: string) => {
    return users?.find((user) => user.id === userId);
  };

  const handleMemberUpdate = async (updates: Partial<Card>) => {
    if (!card) return;

    setCard((current) => {
      if (!current) return current;
      return {
        ...current,
        ...updates,
        updatedAt: new Date().toISOString(),
      } as Card;
    });

    try {
      await handleUpdate(updates);
    } catch (error) {
      console.error("Error updating members:", error);
      setCard(initialCard);
    }
  };

  const renderMemberChip = (userId: string, role: string) => {
    const user = getUserById(userId);
    if (!user) return null;

    return (
      <Chip
        avatar={
          <Avatar
            src={user.profilePicture || ""}
            alt={`${user.firstName} ${user.lastName}`}
          >
            {user.firstName[0]}
          </Avatar>
        }
        label={`${user.firstName} ${user.lastName} (${role})`}
        variant="outlined"
        sx={{ m: 0.5 }}
      />
    );
  };

  const renderUserMenuItem = (user: any) => (
    <MenuItem key={user.id} value={user.id}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Avatar
          sx={{ width: 24, height: 24 }}
          src={user.profilePicture || ""}
          alt={`${user.firstName} ${user.lastName}`}
        >
          {user.firstName[0]}
        </Avatar>
        {`${user.firstName} ${user.lastName}`}
      </Box>
    </MenuItem>
  );

  const renderSelectedAssignees = (selected: string[]) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((value) => {
        const user = getUserById(value);
        return user ? (
          <Chip
            key={value}
            avatar={
              <Avatar
                src={user.profilePicture || ""}
                alt={`${user.firstName} ${user.lastName}`}
              >
                {user.firstName[0]}
              </Avatar>
            }
            label={`${user.firstName} ${user.lastName}`}
            size="small"
          />
        ) : null;
      })}
    </Box>
  );

  const handleDateUpdate = async (
    value: [DateTime | null, DateTime | null]
  ) => {
    if (!card) return;

    const [startDate, endDate] = value;
    const updates = {
      startDate: startDate?.toISO() || null,
      dueDate: endDate?.toISO() || null,
    };

    setCard((current) => {
      if (!current) return current;
      return {
        ...current,
        ...updates,
        updatedAt: new Date().toISOString(),
      } as Card;
    });

    try {
      await handleUpdate(updates);
    } catch (error) {
      console.error("Error updating dates:", error);
      setCard(initialCard);
    }
  };

  const handleProgressUpdate = async (progress: number) => {
    await optimisticUpdate({
      progress,
    });
  };

  const handleHillProgressChange = async (newValue: number) => {
    await optimisticUpdate({
      hillProgress: newValue,
    });
  };

  const handleAddHillChart = async () => {
    if (card.hillProgress === undefined) {
      await optimisticUpdate({
        hillProgress: 0,
      });
    }
  };

  const handleUpdateLabels = async (labels: CardLabel[]) => {
    await optimisticUpdate({
      labels,
    });
  };

  const handleLabelClick = (event: React.MouseEvent<HTMLElement>) => {
    setLabelAnchorEl(event.currentTarget);
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file || !card || !boardId) return;

    setIsUploading(true);
    try {
      await uploadAttachment.mutateAsync({
        boardId,
        cardId: card.id,
        file,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading(false);
    }

    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDeleteAttachment = async (attachmentId: string) => {
    if (!card || !boardId) return;

    try {
      await deleteAttachment.mutateAsync({
        boardId,
        cardId: card.id,
        attachmentId,
      });
      setAttachmentToDelete(null);
    } catch (error) {
      console.error("Error deleting attachment:", error);
    }
  };

  const handleGithubBranchLink = () => {
    if (card.githubBranch) {
      // If already linked, show dialog with current branch
      setGithubBranch(card.githubBranch);
    } else {
      // If not linked, suggest a branch name based on card ID
      setGithubBranch(`feature/${card.humanReadableId.toLowerCase()}`);
    }
    setShowGithubDialog(true);
  };

  const handleGithubBranchSave = async () => {
    if (!card || !boardId) return;

    try {
      const oldBranch = card.githubBranch;
      const newBranch = githubBranch.trim();

      await updateCard.mutateAsync({
        boardId,
        cardId: card.id,
        updates: {
          githubBranch: newBranch,
        },
      });

      // Log appropriate activity based on action
      await logBoardActivity(
        boardId,
        newBranch ? "card-github-link" : "card-github-unlink",
        userInfo?.id || "system",
        generateActivityMessage({
          type: newBranch ? "card-github-link" : "card-github-unlink",
          cardHumanReadableId: card.humanReadableId,
          oldValue: oldBranch || "",
          newValue: newBranch,
        })
      );

      setShowGithubDialog(false);
    } catch (error) {
      console.error("Error linking GitHub branch:", error);
    }
  };

  const handleAddChecklistItem = async (checklistId: string) => {
    if (!newChecklistItem.trim()) return;

    const newItem: ChecklistItem = {
      id: crypto.randomUUID(),
      text: newChecklistItem.trim(),
      completed: false,
      createdAt: new Date().toISOString(),
    };

    const updatedChecklists = (card.checklists || []).map((checklist) => {
      if (checklist.id === checklistId) {
        return {
          ...checklist,
          items: [...checklist.items, newItem],
        };
      }
      return checklist;
    });

    await optimisticUpdate({
      checklists: updatedChecklists,
    });

    setNewChecklistItem("");
    setEditingChecklistId(null);
  };

  const handleToggleChecklistItem = async (
    checklistId: string,
    itemId: string
  ) => {
    if (!card?.checklists) return;

    const updatedChecklists = card.checklists.map((checklist) => {
      if (checklist.id === checklistId) {
        return {
          ...checklist,
          items: checklist.items.map((item) => {
            if (item.id === itemId) {
              const completed = !item.completed;
              return {
                ...item,
                completed,
                completedAt: completed ? new Date().toISOString() : undefined,
              };
            }
            return item;
          }),
        };
      }
      return checklist;
    });

    await optimisticUpdate({
      checklists: updatedChecklists,
    });
  };

  const handleAssignChecklistItem = async (
    checklistId: string,
    itemId: string,
    assigneeIds: string[]
  ) => {
    if (!card?.checklists) return;

    const updatedChecklists = card.checklists.map((checklist) => {
      if (checklist.id === checklistId) {
        return {
          ...checklist,
          items: checklist.items.map((item) => {
            if (item.id === itemId) {
              return {
                ...item,
                assigneeIds: assigneeIds.length > 0 ? assigneeIds : undefined,
              };
            }
            return item;
          }),
        };
      }
      return checklist;
    });

    await optimisticUpdate({
      checklists: updatedChecklists,
    });
  };

  const getCurrentItem = () => {
    if (!editingChecklistId || !editingItemId) return null;
    return card.checklists
      ?.find((c) => c.id === editingChecklistId)
      ?.items.find((i) => i.id === editingItemId);
  };

  const handleUpdateChecklistItem = async (
    checklistId: string,
    itemId: string,
    newText: string
  ) => {
    if (!card?.checklists) return;

    const updatedChecklists = card.checklists.map((checklist) => {
      if (checklist.id === checklistId) {
        return {
          ...checklist,
          items: checklist.items.map((item) => {
            if (item.id === itemId) {
              return {
                ...item,
                text: newText.trim(),
              };
            }
            return item;
          }),
        };
      }
      return checklist;
    });

    await optimisticUpdate({
      checklists: updatedChecklists,
    });

    setEditingItemTextId(null);
    setEditingItemText("");
  };

  return (
    <>
      <Dialog
        open={standalone || open}
        onClose={handleClose}
        maxWidth="lg" // Restore to lg
        fullWidth
        PaperProps={{
          // Restore PaperProps
          sx: {
            minHeight: "85vh",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
            }}
          >
            {isTitleEditing ? (
              <TextField
                fullWidth
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                onBlur={handleTitleSave}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleTitleSave();
                  }
                }}
                sx={{ mr: 2 }}
                autoFocus
              />
            ) : (
              <Typography
                variant="h5"
                onClick={() => setIsTitleEditing(true)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "action.hover",
                    borderRadius: 1,
                    px: 1,
                  },
                  px: 1,
                }}
              >
                {card.title}
              </Typography>
            )}
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box sx={{ display: "flex", gap: 3 }}>
            {/* Main Content */}
            <Box sx={{ flex: 1 }}>
              <Box sx={{ mb: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    gap: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <SubjectIcon />
                    <Typography variant="h6">Description</Typography>
                  </Box>
                </Box>
                <Box
                  onClick={() =>
                    !isDescriptionEditing && setIsDescriptionEditing(true)
                  }
                  sx={{
                    cursor: !isDescriptionEditing ? "pointer" : "default",
                    "&:hover": !isDescriptionEditing
                      ? {
                          bgcolor: "action.hover",
                          borderRadius: 1,
                        }
                      : {},
                  }}
                >
                  {isDescriptionEditing ? (
                    <>
                      <MarkdownEditor
                        value={editContent}
                        onChange={(value) => setEditContent(value)}
                        onSave={handleDescriptionSave}
                        minHeight={300}
                        placeholder="Add a more detailed description..."
                      />
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 1,
                        }}
                      >
                        <Button onClick={handleDescriptionCancel}>
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleDescriptionSave}
                          disabled={editContent === card.description}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        p: 2,
                        "&:hover": {
                          bgcolor: "action.hover",
                          borderRadius: 1,
                        },
                      }}
                    >
                      <MarkdownEditor
                        value={
                          card.description ||
                          "Add a more detailed description..."
                        }
                        onChange={() => {}}
                        readOnly
                        minHeight={300}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Progress Section */}
              {card.hillProgress !== undefined && showHillChart && (
                <Box sx={{ mb: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      gap: 1,
                    }}
                  >
                    <TimelineIcon />
                    <Typography variant="h6">Progress</Typography>
                  </Box>
                  <SingleHillChart
                    value={card.hillProgress}
                    onChange={handleHillProgressChange}
                    onSave={async (value) => {
                      await handleHillProgressChange(value);
                    }}
                    disabled={!userInfo?.id}
                  />
                </Box>
              )}

              {Boolean(card.checklists?.length) && showChecklists && (
                <Box sx={{ mb: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      gap: 1,
                    }}
                  >
                    <CheckCircleIcon sx={{ color: "success.main" }} />
                    <Typography variant="h6">Checklist</Typography>
                  </Box>

                  {card.checklists?.map((checklist) => (
                    <Box
                      key={checklist.id}
                      sx={{
                        mb: 3,
                        backgroundColor: "background.paper",
                        borderRadius: 1,
                        p: 2,
                        boxShadow: 1,
                      }}
                    >
                      {checklist.items.map((item) => (
                        <Box
                          key={item.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                            "&:hover .assign-member": {
                              opacity: 1,
                            },
                          }}
                        >
                          <Checkbox
                            checked={item.completed}
                            onChange={() =>
                              handleToggleChecklistItem(checklist.id, item.id)
                            }
                            size="small"
                          />
                          {editingItemTextId === item.id ? (
                            <TextField
                              autoFocus
                              size="small"
                              fullWidth
                              value={editingItemText}
                              onChange={(e) =>
                                setEditingItemText(e.target.value)
                              }
                              onBlur={() => {
                                if (editingItemText.trim()) {
                                  handleUpdateChecklistItem(
                                    checklist.id,
                                    item.id,
                                    editingItemText
                                  );
                                } else {
                                  setEditingItemTextId(null);
                                  setEditingItemText("");
                                }
                              }}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  editingItemText.trim()
                                ) {
                                  handleUpdateChecklistItem(
                                    checklist.id,
                                    item.id,
                                    editingItemText
                                  );
                                }
                              }}
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              onClick={() => {
                                setEditingItemTextId(item.id);
                                setEditingItemText(item.text);
                              }}
                              sx={{
                                flex: 1,
                                cursor: "pointer",
                                textDecoration: item.completed
                                  ? "line-through"
                                  : "none",
                                color: item.completed
                                  ? "text.secondary"
                                  : "text.primary",
                                "&:hover": {
                                  bgcolor: "action.hover",
                                  borderRadius: 1,
                                  px: 1,
                                },
                                px: 1,
                              }}
                            >
                              {item.text}
                            </Typography>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                            }}
                          >
                            {item.assigneeIds?.length ? (
                              <AvatarGroup
                                max={3}
                                sx={{
                                  "& .MuiAvatar-root": {
                                    width: 24,
                                    height: 24,
                                    fontSize: "0.75rem",
                                  },
                                }}
                              >
                                {item.assigneeIds.map((userId) => {
                                  const user = getUserById(userId);
                                  return user ? (
                                    <Avatar
                                      key={userId}
                                      src={user.profilePicture || ""}
                                      alt={`${user.firstName} ${user.lastName}`}
                                      sx={{ width: 24, height: 24 }}
                                    >
                                      {user.firstName[0]}
                                    </Avatar>
                                  ) : null;
                                })}
                              </AvatarGroup>
                            ) : null}
                            <IconButton
                              size="small"
                              className="assign-member"
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingItemId(item.id);
                                setEditingChecklistId(checklist.id);
                                setMemberMenuAnchorEl(e.currentTarget);
                              }}
                              sx={{
                                opacity: 0,
                                transition: "opacity 0.2s",
                                p: 0.5,
                              }}
                            >
                              <PersonAddIcon sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}

                      {editingChecklistId === checklist.id ? (
                        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                          <TextField
                            size="small"
                            fullWidth
                            value={newChecklistItem}
                            onChange={(e) =>
                              setNewChecklistItem(e.target.value)
                            }
                            placeholder="Add an item"
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleAddChecklistItem(checklist.id);
                              }
                            }}
                          />
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleAddChecklistItem(checklist.id)}
                          >
                            Add
                          </Button>
                        </Box>
                      ) : (
                        <Button
                          size="small"
                          sx={{ mt: 1 }}
                          onClick={() => setEditingChecklistId(checklist.id)}
                        >
                          Add item
                        </Button>
                      )}
                    </Box>
                  ))}
                </Box>
              )}

              <ActivitySection
                card={card}
                activities={activities || []}
                onAddComment={async (comment, mentionedUserIds) => {
                  if (!userInfo) return;

                  await addComment.mutateAsync({
                    boardId: boardId!,
                    cardId: card.id,
                    content: comment.trim(),
                    mentionedUserIds,
                  });
                }}
              />
            </Box>

            {/* Sidebar */}
            <Box sx={{ width: 280 }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 2, color: "text.secondary" }}
              >
                Add to card
              </Typography>

              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ mb: 1 }}
                >
                  Members
                </Typography>

                <Box sx={{ display: "flex", flexWrap: "wrap", mb: 1 }}>
                  {card.assignee && renderMemberChip(card.assignee, "Assignee")}
                  {card.reporter && renderMemberChip(card.reporter, "Reporter")}
                  {card.additionalAssignees?.map((userId) =>
                    renderMemberChip(userId, "Additional Assignee")
                  )}
                </Box>

                <Button
                  startIcon={<PersonAddIcon />}
                  fullWidth
                  sx={{ justifyContent: "flex-start", mb: 1 }}
                  onClick={() => setShowMembersDialog(true)}
                >
                  Manage Members
                </Button>
              </Box>

              <Button
                startIcon={<LabelIcon />}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  mb: 1,
                  color: card.labels?.length ? "primary.main" : "inherit",
                }}
                onClick={handleLabelClick}
              >
                {card.labels?.length
                  ? `Labels (${card.labels.length})`
                  : "Labels"}
              </Button>

              <Divider sx={{ my: 2 }} />

              <Typography
                variant="subtitle2"
                sx={{ mb: 2, color: "text.secondary" }}
              >
                Actions
              </Typography>

              <Button
                startIcon={<TimerIcon />}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  mb: 1,
                  color: card.dueDate ? "primary.main" : "inherit",
                }}
                onClick={() => setOpenDatePicker(true)}
              >
                {card.dueDate ? (
                  <>
                    {card.startDate &&
                      `${DateTime.fromISO(card.startDate).toFormat("MMM d")} - `}
                    Due {DateTime.fromISO(card.dueDate).toFormat("MMM d, yyyy")}
                  </>
                ) : (
                  "Set Date Range"
                )}
              </Button>

              <Button
                startIcon={<CheckCircleIcon sx={{ color: "success.main" }} />}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  mb: 1,
                  color: card.checklists?.length ? "primary.main" : "inherit",
                }}
                onClick={() => {
                  if (!card.checklists?.length) {
                    const newChecklist = {
                      id: crypto.randomUUID(),
                      title: "Checklist",
                      items: [],
                    };
                    optimisticUpdate({
                      checklists: [...(card.checklists || []), newChecklist],
                    });
                  } else {
                    setShowChecklists(!showChecklists);
                  }
                }}
              >
                {Boolean(card.checklists?.length)
                  ? showChecklists
                    ? "Hide Checklist"
                    : "Show Checklist"
                  : "Add Checklist"}
              </Button>

              <Button
                startIcon={<TimelineIcon />}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  mb: 1,
                  color:
                    card.hillProgress !== undefined
                      ? "primary.main"
                      : "inherit",
                }}
                onClick={() => {
                  if (card.hillProgress === undefined) {
                    handleAddHillChart();
                  } else {
                    setShowHillChart(!showHillChart);
                  }
                }}
              >
                {card.hillProgress !== undefined
                  ? showHillChart
                    ? "Hide Hill Chart"
                    : "Show Hill Chart"
                  : "Add Hill Chart"}
              </Button>

              <Button fullWidth sx={{ justifyContent: "flex-start", mb: 1 }}>
                Move (non impl)
              </Button>

              <Button fullWidth sx={{ justifyContent: "flex-start", mb: 1 }}>
                Copy (non impl, right click card)
              </Button>

              <Button
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  mb: 1,
                  color: "error.main",
                }}
                onClick={() => setShowArchiveConfirm(true)}
              >
                Archive
              </Button>

              <Divider sx={{ my: 2 }} />

              <Typography
                variant="subtitle2"
                sx={{ mb: 2, color: "text.secondary" }}
              >
                Additional
              </Typography>

              <Button
                startIcon={<GitHubIcon />}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  mb: 1,
                  color: card.githubBranch ? "primary.main" : "inherit",
                }}
                onClick={handleGithubBranchLink}
              >
                {card.githubBranch
                  ? `Branch: ${card.githubBranch}`
                  : "Link GitHub Branch"}
              </Button>

              <Button
                startIcon={<AttachFileIcon />}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  mb: 1,
                  color: card.attachments?.length ? "primary.main" : "inherit",
                }}
                onClick={() => setShowAttachments(!showAttachments)}
              >
                ATTACHMENTS ({card.attachments?.length || 0}){" "}
                {showAttachments ? "▼" : "▶"}
              </Button>

              {showAttachments && (
                <Box sx={{ pl: 4, mb: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" color="text.secondary">
                        {card.attachments?.length || 0} attachments
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => fileInputRef.current?.click()}
                      disabled={isUploading}
                      sx={{
                        minWidth: "unset",
                        px: 2,
                        backgroundColor: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          opacity: 0.8,
                        },
                      }}
                    >
                      ADD
                    </Button>
                  </Box>

                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />

                  <AttachmentList
                    attachments={card.attachments}
                    onDelete={handleDeleteAttachment}
                    onDeleteClick={setAttachmentToDelete}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={handleArchive}>
            <ListItemIcon>
              <ArchiveIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Archive</ListItemText>
          </MenuItem>
        </Menu>
      </Dialog>

      {/* Members Management Dialog */}
      <Dialog
        open={showMembersDialog}
        onClose={() => setShowMembersDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Manage Card Members</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, py: 2 }}>
            {/* Assignee Selection */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Assignee
              </Typography>
              <Select
                fullWidth
                value={card.assignee || ""}
                onChange={(e) =>
                  handleMemberUpdate({ assignee: e.target.value })
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {users?.map(renderUserMenuItem)}
              </Select>
            </Box>

            {/* Reporter Selection */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Reporter
              </Typography>
              <Select
                fullWidth
                value={card.reporter || ""}
                onChange={(e) =>
                  handleMemberUpdate({ reporter: e.target.value })
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {users?.map(renderUserMenuItem)}
              </Select>
            </Box>

            {/* Additional Assignees */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Additional Assignees
              </Typography>
              <Autocomplete
                multiple
                value={(card.additionalAssignees || [])
                  .map((id) => users?.find((u) => u.id === id))
                  .filter(
                    (user): user is NonNullable<typeof user> => user != null
                  )}
                onChange={(_, newValue) => {
                  handleMemberUpdate({
                    additionalAssignees: newValue.map((user) => user.id),
                  });
                }}
                options={users || []}
                getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, user) => (
                  <Box component="li" {...props}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        src={user.profilePicture || ""}
                        alt={`${user.firstName} ${user.lastName}`}
                      >
                        {user.firstName[0]}
                      </Avatar>
                      {`${user.firstName} ${user.lastName}`}
                    </Box>
                  </Box>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((user, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={user.id}
                      avatar={
                        <Avatar
                          src={user.profilePicture || ""}
                          alt={`${user.firstName} ${user.lastName}`}
                        >
                          {user.firstName[0]}
                        </Avatar>
                      }
                      label={`${user.firstName} ${user.lastName}`}
                      size="small"
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      card.additionalAssignees?.length
                        ? "Add more assignees"
                        : "Search for assignees"
                    }
                  />
                )}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowMembersDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Archive Confirmation Dialog */}
      <Dialog
        open={showArchiveConfirm}
        onClose={() => setShowArchiveConfirm(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Archive Card</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to archive "{card.title}"? You can find it
            later in the archived items.
          </Typography>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: 1 }}>
          <Button onClick={() => setShowArchiveConfirm(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handleArchive}>
            Archive
          </Button>
        </Box>
      </Dialog>

      {/* Date Picker Dialog */}
      <Dialog
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: "auto",
          },
        }}
      >
        <DialogTitle>Set Date Range</DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimeRangePicker
              localeText={{ start: "Start", end: "End" }}
              value={[
                card.startDate ? DateTime.fromISO(card.startDate) : null,
                card.dueDate ? DateTime.fromISO(card.dueDate) : null,
              ]}
              onChange={handleDateUpdate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
                actionBar: {
                  actions: ["clear", "cancel", "accept"],
                },
                layout: {
                  sx: {
                    [`.MuiDateRangeCalendar-root`]: {
                      width: "auto",
                      maxHeight: "none",
                    },
                  },
                },
              }}
              closeOnSelect={false}
              format="MMM dd, yyyy hh:mm a"
              ampm
              sx={{
                ".MuiPickersLayout-root": {
                  minWidth: "auto",
                },
              }}
            />
          </LocalizationProvider>
          {(card.startDate || card.dueDate) && (
            <Button
              sx={{ mt: 2 }}
              color="error"
              onClick={() => handleDateUpdate([null, null])}
            >
              Remove Dates
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDatePicker(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Replace Dialog with Popover */}
      <LabelManager
        open={Boolean(labelAnchorEl)}
        anchorEl={labelAnchorEl}
        onClose={() => setLabelAnchorEl(null)}
        boardId={boardId || ""}
        card={card}
      />

      {/* GitHub Branch Dialog */}
      <Dialog
        open={showGithubDialog}
        onClose={() => setShowGithubDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {card?.githubBranch ? "Update GitHub Branch" : "Link GitHub Branch"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              fullWidth
              label="Branch Name"
              value={githubBranch}
              onChange={(e) => setGithubBranch(e.target.value)}
              placeholder="feature/branch-name"
              helperText="Enter the name of the GitHub branch to link"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {card?.githubBranch && (
            <Button
              color="error"
              onClick={async () => {
                await handleGithubBranchSave();
                setGithubBranch("");
              }}
            >
              Remove Link
            </Button>
          )}
          <Button onClick={() => setShowGithubDialog(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleGithubBranchSave}
            disabled={!githubBranch.trim()}
          >
            {card?.githubBranch ? "Update" : "Link"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Attachment Dialog */}
      <Dialog
        open={Boolean(attachmentToDelete)}
        onClose={() => setAttachmentToDelete(null)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Delete Attachment</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete "{attachmentToDelete?.fileName}"?
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttachmentToDelete(null)}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (attachmentToDelete) {
                handleDeleteAttachment(attachmentToDelete.id);
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Assign Member Dialog */}
      <Menu
        anchorEl={memberMenuAnchorEl}
        open={Boolean(memberMenuAnchorEl)}
        onClose={() => {
          setMemberMenuAnchorEl(null);
          setEditingItemId(null);
          setEditingChecklistId(null);
        }}
        sx={{
          "& .MuiPaper-root": {
            width: 300,
            maxHeight: 400,
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle2" sx={{ px: 1, mb: 1 }}>
            Assign members
          </Typography>
          {users?.map((user) => {
            const isAssigned = getCurrentItem()?.assigneeIds?.includes(user.id);
            return (
              <MenuItem
                key={user.id}
                onClick={() => {
                  if (editingChecklistId && editingItemId) {
                    const currentAssignees =
                      getCurrentItem()?.assigneeIds || [];
                    const newAssignees = isAssigned
                      ? currentAssignees.filter((id) => id !== user.id)
                      : [...currentAssignees, user.id];

                    handleAssignChecklistItem(
                      editingChecklistId,
                      editingItemId,
                      newAssignees
                    );
                  }
                  // Don't close the menu to allow multiple selections
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  py: 0.5,
                }}
              >
                <Checkbox checked={isAssigned} size="small" sx={{ p: 0.5 }} />
                <Avatar
                  src={user.profilePicture || ""}
                  alt={`${user.firstName} ${user.lastName}`}
                  sx={{ width: 24, height: 24 }}
                >
                  {user.firstName[0]}
                </Avatar>
                <Typography variant="body2">
                  {user.firstName} {user.lastName}
                </Typography>
              </MenuItem>
            );
          })}
        </Box>
      </Menu>
    </>
  );
};

export default FreeloTicketDialog;
