import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Container,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useFreelo } from 'hooks/freelo/useFreelo';
import { useAuth } from 'hooks/auth/useAuth';
import { useUserList } from 'hooks/shared/user/useUserList';

const Freelo: React.FC = () => {
  const navigate = useNavigate();
  const [newBoardName, setNewBoardName] = useState('');
  const [openNewBoardDialog, setOpenNewBoardDialog] = useState(false);
  const { userInfo } = useAuth();
  const { data: users } = useUserList();
  
  const { 
    boards,
    isLoading,
    error,
    createBoard,
  } = useFreelo();

  const getAccessibleBoards = () => {
    if (!boards) return [];
    
    
    return boards.filter(board => {
      if (userInfo?.user_type === 'admin') return true;
      
      const hasReadAccess = board.readUserIds?.includes(userInfo?.id || '');
      const hasWriteAccess = board.writeUserIds?.includes(userInfo?.id || '');
      const isCreator = board.createdBy === userInfo?.id;

      return hasReadAccess || hasWriteAccess || isCreator;
    });
  };

  const getUserName = (userId: string) => {
    const user = users?.find(u => u.id === userId);
    return user ? `${user.firstName} ${user.lastName}` : 'Unknown User';
  };

  const handleCreateBoard = async () => {
    if (!newBoardName.trim()) return;
    
    try {
      const boardId = await createBoard.mutateAsync({
        name: newBoardName.trim(),
      });
      setNewBoardName('');
      setOpenNewBoardDialog(false);
      navigate(`/freelo/${boardId}`);
    } catch (error) {
      console.error('Error creating board:', error);
    }
  };

  const handleBoardClick = (boardId: string) => {
    
    navigate(`/freelo/${boardId}`);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">Error loading boards</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box 
        sx={{ 
          p: { xs: 2, sm: 3 },
          borderBottom: '1px solid',
          borderColor: 'divider',
          mb: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 2, sm: 0 }
          }}
        >
          <Typography 
            variant="h4" 
            component="h1"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.125rem' },
              fontWeight: 500
            }}
          >
            Freelo Boards
          </Typography>
        </Box>
      </Box>

      <Box sx={{ px: { xs: 2, sm: 3 } }}>
        <Grid container spacing={3}>
          {/* Create New Board Card */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card 
              sx={{ 
                height: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'action.hover',
                cursor: 'pointer',
              }}
              onClick={() => setOpenNewBoardDialog(true)}
            >
              <CardActionArea sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <AddIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                  <Typography variant="h6" color="text.secondary">
                    Create New Board
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {/* Board Cards - Updated to use getAccessibleBoards */}
          {getAccessibleBoards().map((board) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={board.id}>
              <Card 
                sx={{ 
                  height: '200px',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.2s ease-in-out',
                  },
                }}
                onClick={() => handleBoardClick(board.id)}
              >
                <CardActionArea sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {board.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {board.columns?.length} columns
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block',
                          color: board.createdBy === userInfo?.id ? 'success.main' : 'info.main'
                        }}
                      >
                        {board.createdBy === userInfo?.id ? 'Owner' : 'Shared with you'}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block',
                          color: 'text.secondary',
                          mt: 0.5
                        }}
                      >
                        Created by {getUserName(board.createdBy)}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* New Board Dialog */}
      <Dialog 
        open={openNewBoardDialog} 
        onClose={() => setOpenNewBoardDialog(false)}
      >
        <DialogTitle>Create New Board</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Board Name"
            fullWidth
            value={newBoardName}
            onChange={(e) => setNewBoardName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewBoardDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateBoard} variant="contained">Create</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Freelo; 