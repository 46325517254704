import React, { useMemo } from "react";
import { Box, Paper, useTheme, alpha, Typography, Grid, Button } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { PieChart } from "@mui/x-charts/PieChart";
import { logoNoBackground } from "../../../../assets";

interface TimesheetExplorerGridProps {
  timesheets: Timesheet[];
  loading: boolean;
  startDate: DateTime;
  endDate: DateTime;
  selectedClients: string[];
  selectedEngineers: string[];
  selectedProjects: string[];
}

const CustomToolbar = ({ startDate, endDate, selectedClients }: { 
  startDate: DateTime; 
  endDate: DateTime;
  selectedClients: string[];
}) => {
  const handlePrintReport = () => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    // Get the summary section with chart
    const summarySection = document.querySelector('.summary-section');
    
    // Get the hourly breakdown data from the grid
    const rows = document.querySelectorAll('.MuiDataGrid-row');
    let timesheetRows = '';
    rows.forEach(row => {
      const cells = row.querySelectorAll('.MuiDataGrid-cell');
      timesheetRows += `
        <tr>
          <td>${cells[0]?.textContent || ''}</td>
          <td>${cells[1]?.textContent || ''}</td>
          <td>${cells[2]?.textContent || ''}</td>
          <td>${cells[3]?.textContent || ''}</td>
          <td>${cells[4]?.textContent || ''}</td>
          <td>${cells[5]?.textContent || ''}</td>
          <td>${cells[6]?.textContent || ''}</td>
        </tr>
      `;
    });

    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Timesheet Report</title>
          <style>
            body { 
              font-family: Arial, sans-serif; 
              padding: 20px;
              line-height: 1.6;
              color: #000;
            }
            .title-page {
              min-height: 100vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              text-align: center;
              padding: 40px;
            }
            .header { 
              margin-bottom: 40px;
              text-align: center;
            }
            .logo { 
              height: 80px; 
              margin: 40px 0;
            }
            .report-title {
              font-size: 28px;
              font-weight: bold;
              margin: 20px 0;
              color: #000;
            }
            .report-subtitle {
              font-size: 18px;
              color: #000;
              margin: 20px 0 40px 0;
            }
            .filters { 
              margin: 20px auto;
              max-width: 600px;
              text-align: center;
              font-size: 16px;
              color: #000;
            }
            .filter-item {
              margin: 10px 0;
            }
            .content {
              margin: 20px auto;
              max-width: 800px;
              width: 100%;
            }
            .summary-box {
              padding: 30px;
              background: #f8f9fa;
              border-radius: 8px;
              margin: 20px 0;
            }
            .chart-container {
              margin: 30px 0;
            }
            .timesheet-details {
              font-size: 11px;
            }
            .timesheet-details .header {
              font-size: 14px;
            }
            .timesheet-details table { 
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
              font-size: 11px;
            }
            .timesheet-details th, .timesheet-details td {
              border: 1px solid #ddd;
              padding: 6px;
              text-align: left;
            }
            .timesheet-details th {
              background-color: #f5f5f5;
              font-weight: 600;
            }
            .page-break {
              page-break-before: always;
            }
            @media print {
              @page { 
                margin: 20px; 
                size: landscape;
              }
              .title-page {
                page-break-after: always;
              }
              .content { 
                page-break-inside: avoid; 
              }
              table { 
                page-break-inside: auto; 
              }
              tr { 
                page-break-inside: avoid; 
                page-break-after: auto; 
              }
            }
          </style>
        </head>
        <body>
          <div class="title-page">
            <img src="${logoNoBackground}" alt="FreeTech Logo" class="logo" />
            <h1 class="report-title">Timesheet Summary Report</h1>
            <div class="report-subtitle">
              ${startDate.toFormat("MMMM dd, yyyy")} - ${endDate.toFormat("MMMM dd, yyyy")}
            </div>
            ${selectedClients.length > 0 ? `
              <div class="filters">
                <div class="filter-item">
                  <strong>Clients:</strong> ${selectedClients.join(", ")}
                </div>
              </div>
            ` : ''}
            <div class="content">
              ${summarySection?.innerHTML || ''}
            </div>
          </div>

          <div class="page-break"></div>
          <div class="timesheet-details">
            <div class="header">
              <h2>Detailed Timesheet Breakdown</h2>
              <p>${startDate.toFormat("MMMM dd")} - ${endDate.toFormat("MMMM dd, yyyy")}</p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Client</th>
                  <th>Project</th>
                  <th>Date</th>
                  <th>Duration</th>
                  <th>Description</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                ${timesheetRows}
              </tbody>
            </table>
          </div>
        </body>
      </html>
    `;

    printWindow.document.write(printContent);
    printWindow.document.close();

    // Wait for images to load before printing
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    };
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {/* <GridToolbarFilterButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      {/* <GridToolbarExport
        printOptions={{
          disableToolbarButton: false,
          hideFooter: true,
          hideToolbar: true,
        }}
        csvOptions={{
          disableToolbarButton: false,
          fileName: `timesheet-export-${DateTime.now().toFormat("yyyy-MM-dd")}`,
          utf8WithBom: true,
        }}
      /> */}
      <Button
        color="primary"
        startIcon={<PrintIcon />}
        onClick={handlePrintReport}
      >
        Print Report
      </Button>
    </GridToolbarContainer>
  );
};

const TimesheetExplorerGrid: React.FC<TimesheetExplorerGridProps> = ({
  timesheets,
  loading,
  startDate,
  endDate,
  selectedClients,
  selectedEngineers,
  selectedProjects,
}) => {
  const theme = useTheme();

  const engineerSummary = useMemo(() => {
    return timesheets.reduce((acc, timesheet) => {
      const userName = timesheet.userName || 'Unknown';
      if (!acc[userName]) {
        acc[userName] = 0;
      }
      acc[userName] += timesheet.duration || 0;
      return acc;
    }, {} as Record<string, number>);
  }, [timesheets]);

  const pieChartData = useMemo(() => {
    return Object.entries(engineerSummary).map(([name, duration]) => ({
      id: name,
      value: duration / 3600, // Convert to hours
      label: name,
    }));
  }, [engineerSummary]);

  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const totalHours = useMemo(() => {
    return Object.values(engineerSummary).reduce((acc, duration) => acc + duration, 0);
  }, [engineerSummary]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "userName",
        headerName: "User",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "clientName",
        headerName: "Client",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "projectName",
        headerName: "Project",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "duration",
        headerName: "Duration",
        flex: 1,
        minWidth: 100,
        valueFormatter: (value: number) => {
          const totalSeconds = value;
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        },
      },
      {
        field: "description",
        headerName: "Description",
        flex: 2,
        minWidth: 200,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 120,
      },
    ],
    []
  );

  return (
    <Box>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }} className="summary-section">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Engineer Time Summary
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Total Hours: {formatDuration(totalHours)}
            </Typography>
            {Object.entries(engineerSummary).map(([name, duration]) => (
              <Typography key={name}>
                {name}: {formatDuration(duration)}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <PieChart
              series={[
                {
                  data: pieChartData,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30 },
                },
              ]}
              height={300}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3}>
        <DataGridPro
          rows={timesheets}
          columns={columns}
          loading={loading}
          autoHeight
          pagination
          density="comfortable"
          slots={{
            toolbar: (props) => (
              <CustomToolbar
                {...props}
                startDate={startDate}
                endDate={endDate}
                selectedClients={selectedClients}
              />
            ),
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [],
              },
            },
          }}
          sx={{
            border: "none",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default TimesheetExplorerGrid;
