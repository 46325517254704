import * as React from "react";
import { AppRoutes } from "./router/AppRoutes";
import { AuthProvider } from "./contexts/AuthContext";
import { SnackbarProvider } from "contexts/snackBarContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import RootErrorBoundary from "./views/Shared/ErrorBoundaries/RootErrorBoundary";

const queryClient = new QueryClient();

export default function App() {
  return (
    <RootErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <SnackbarProvider>
              <AppRoutes />
            </SnackbarProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </LocalizationProvider>
    </RootErrorBoundary>
  );
}
