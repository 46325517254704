import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';

interface DeleteListConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (targetListId: string | null) => void;
  listToDelete: Column | null;
  availableLists: Column[];
  hasCards: boolean;
}

export const DeleteListConfirmDialog: React.FC<DeleteListConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  listToDelete,
  availableLists,
  hasCards,
}) => {
  const [targetListId, setTargetListId] = useState<string>('');

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete List</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Are you sure you want to delete "{listToDelete?.name}"?
        </Typography>
        
        {hasCards && (
          <Box sx={{ mt: 2 }}>
            <Typography color="warning.main" gutterBottom>
              This list contains cards. Please select where you want to move them:
            </Typography>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <Select
                value={targetListId}
                onChange={(e) => setTargetListId(e.target.value as string)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select a list
                </MenuItem>
                {availableLists
                  .filter(list => list.id !== listToDelete?.id)
                  .map(list => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          onClick={() => onConfirm(hasCards ? targetListId : null)}
          color="error"
          disabled={hasCards && !targetListId}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 