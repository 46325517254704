import React, { useEffect } from "react";
import { CustomNavigation } from "router/layouts/DashboardLayoutProvider";
import { ClientInvoices, StakeholderProjects, StakeholderHome } from "views/Stakeholder";
import {
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
  Home as HomeIcon,
} from "@mui/icons-material";

export const stakeholderPages: CustomNavigation = [
  { kind: "header", title: "General" },
  {
    segment: "stakeholder",
    title: "Home",
    icon: <HomeIcon />,
    component: <StakeholderHome />,
  },
  {
    segment: "stakeholder/invoices",
    title: "Invoices",
    icon: <ReceiptIcon />,
    component: <ClientInvoices />,
  },
  {
    segment: "stakeholder/projects",
    title: "Projects",
    icon: <AssignmentIcon />,
    component: <StakeholderProjects />,
  },
];
