import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

export const getUserName = async (userId: string): Promise<string> => {
  try {
    const userDoc = await getDoc(doc(db, "users", userId));
    const userData = userDoc.data() as UserInfoType;
    return userData.firstName + " " + userData.lastName || "Unknown User";
  } catch (error) {
    console.error("Error fetching user name:", error);
    return "Unknown User";
  }
}; 