import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

import { useAuth } from "hooks/auth/useAuth";
import { getClientInvoices } from 'core/clients/getClientInvoices';
import ViewFileDialog from 'components/ViewFileDialog';
import PayInvoiceDialog from './components/PayInvoiceDialog';
import { getDownloadableURL } from 'core/firebaseHelpers/getDownloadableURL';

const ClientInvoices: React.FC = () => {
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const [loading, setLoading] = useState(true);
  const { userInfo } = useAuth();
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isPayDialogOpen, setIsPayDialogOpen] = useState(false);

  const clientId = "UEJrrMCXdZoLkCUM0hDe";

  useEffect(() => {
    const fetchInvoices = async () => {
      if (clientId) {
        try {
          const fetchedInvoices = await getClientInvoices(clientId);
           
          setInvoices(fetchedInvoices);
        } catch (error) {
          console.error('Error fetching invoices:', error);
          // Handle error (e.g., show error message to user)
        } finally {
          setLoading(false);
        }
      }
    };

    fetchInvoices();
  }, []);

  const handleViewInvoice = async (invoice: InvoiceData) => {
    try {
      const fullStoragePath = `clients/${invoice.clientId}/invoices/${invoice.id}.pdf`;
      const url = await getDownloadableURL(fullStoragePath);
      invoice.pdfUrl = url;
      setSelectedInvoice(invoice);
      setIsViewDialogOpen(true);
    } catch (error) {
      console.error('Error fetching invoice PDF:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handlePayInvoice = (invoice: InvoiceData) => {
    setSelectedInvoice(invoice);
    setIsPayDialogOpen(true);
  };

  const handleCloseViewDialog = () => {
    setIsViewDialogOpen(false);
    setSelectedInvoice(null);
  };

  const handleClosePayDialog = () => {
    setIsPayDialogOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <>
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Your Invoices
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Number</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>{invoice.invoiceNumber}</TableCell>
                    <TableCell>{invoice.invoiceDate}</TableCell>
                    <TableCell>${invoice.items.reduce((sum, item) => sum + item.quantity * item.rate, 0).toFixed(2)}</TableCell>
                    <TableCell>{invoice.status}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleViewInvoice(invoice)}>View Invoice</Button>
                      {invoice.status === 'unpaid' && (
                        <Button onClick={() => handlePayInvoice(invoice)}>Pay Invoice</Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {selectedInvoice && (
          <>
            <ViewFileDialog
              open={isViewDialogOpen}
              onClose={handleCloseViewDialog}
              fileUrl={selectedInvoice.pdfUrl || ''}
              fileName={`Invoice_${selectedInvoice.invoiceNumber}.pdf`}
            />
            <PayInvoiceDialog
              open={isPayDialogOpen}
              onClose={handleClosePayDialog}
              invoice={selectedInvoice}
              // TODO: Change this to userInfo.mainFreeTechPaymentAccountId
              accountId={'sandbox_account_wsja3ql2aqobwsegmehy'}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default ClientInvoices;