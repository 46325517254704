import React, { useState } from 'react';
import {
  Box,
  Typography,
  Popover,
  IconButton,
  Button,
  Divider,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';

interface ListColorManagerProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onColorSelect: (color: string | null) => void;
  currentColor: string | null;
}

export const ListColorManager: React.FC<ListColorManagerProps> = ({
  open,
  anchorEl,
  onClose,
  onColorSelect,
  currentColor,
}) => {
  const [selectedColor, setSelectedColor] = useState(currentColor || '#4CAF50');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const defaultColors = [
    { id: 'green', color: '#4CAF50' },
    { id: 'yellow', color: '#FFC107' },
    { id: 'orange', color: '#FF9800' },
    { id: 'red', color: '#f44336' },
    { id: 'purple', color: '#9C27B0' },
    { id: 'blue', color: '#2196F3' },
    { id: 'pink', color: '#E91E63' },
    { id: 'teal', color: '#009688' },
  ];

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1">List Color</Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(4, 1fr)', 
          gap: 0.5,
          width: 'fit-content',
          mb: 1,
        }}>
          {defaultColors.map((color) => (
            <Box
              key={color.id}
              sx={{
                width: 28,
                height: 28,
                bgcolor: color.color,
                borderRadius: 0.5,
                cursor: 'pointer',
                border: currentColor === color.color ? '2px solid' : 'none',
                borderColor: 'primary.main',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => onColorSelect(color.color)}
            />
          ))}
          <Box
            sx={{
              width: 28,
              height: 28,
              bgcolor: 'grey.100',
              borderRadius: 0.5,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              borderColor: 'divider',
              fontSize: '0.875rem',
              '&:hover': {
                bgcolor: 'grey.200',
              },
            }}
            onClick={() => onColorSelect(null)}
          >
            ✕
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Button
          size="small"
          variant="contained"
          fullWidth
          sx={{ 
            bgcolor: 'black',
            color: 'white',
            '&:hover': {
              bgcolor: 'black',
              opacity: 0.9,
            }
          }}
          onClick={() => setShowColorPicker(!showColorPicker)}
        >
          CUSTOM COLOR
        </Button>
        
        {showColorPicker && (
          <Box sx={{ mt: 2 }}>
            <ChromePicker
              color={selectedColor}
              onChange={(color: any) => setSelectedColor(color.hex)}
              onChangeComplete={(color: any) => onColorSelect(color.hex)}
            />
          </Box>
        )}
      </Box>
    </Popover>
  );
}; 